<template>
    <div class="select-wrapper">
        <span class="icon">
            <slot name="icon" />
        </span>
        <select v-model="model" :class="[{ '--placeholder-selected': isPlaceholderSelected, '--required': isRequired }]"
            :disabled="disabled" :required="isRequired">
            <option disabled value="">{{ placeholder }}</option>
            <option v-for="value in transformedOptions" :key="value[optionsKey]" :value="value[optionsKey]">{{
                value[optionsValue]
            }}</option>
        </select>
    </div>
</template>


<script setup>
import { defineModel, defineProps, computed, ref, watchEffect } from 'vue'
const model = defineModel();
const isPlaceholderSelected = computed(() => model.value === '');

const { isRequired, options, optionsKey, optionsValue, placeholder, disabled } = defineProps({
    isRequired: {
        type: Boolean,
        default: false,
    },
    options: {
        type: Array,
        default: () => [],
    },
    optionsKey: {
        type: String,
        default: 'code',
    },
    optionsValue: {
        type: String,
        default: 'label',
    },
    placeholder: {
        type: String,
        default: 'Select an option',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});

// If no optionsKey & optionsValue defined, then assume that options is an array of strings
const transformedOptions = ref([]);
watchEffect(() => {
    if (options.length && typeof options[0] === 'string') {
        transformedOptions.value = options.map((value) => ({ code: value, label: value }));
    } else {
        transformedOptions.value = options;
    }
});
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
.--placeholder-selected {
    color: var(--grey-darker);
}

.select-wrapper {
    position: relative;
    display: flex;
    width: 100%;

    &:deep(img) {
        width: 1.5vw;
        height: 1.5vw;
        pointer-events: none;
    }
}

.icon {
    position: absolute;
    left: 0.2vw;
    top: 0.15vw;
    filter: grayscale(100%);

    &::after {
        left: 1.5vw;
    }

    &::after,
    &.--right::after {
        content: '|';
        top: -0.05vw;
        position: absolute;
        color: var(--grey-darker);
        font-size: 1.2vw;
    }
}

select {
    height: 1.8vw;
    /* padding: ↑ → ↓ ← */
    padding-left: 2.4vw;

    width: 100%;
    font-size: 1.1vw;
    /* I don't know why but text is slightly off center to the bottom */
    /* ↓ this is the fix */

    color: var(--black-light);
    border: 0.01vw solid var(--grey-darker);
    border-radius: var(--small-radius);
    box-shadow: var(--small-shadow);

    &:disabled {
        background-color: var(--grey-light);
        color: var(--grey-darker);
    }

    &:focus {
        outline: 0.01vw solid var(--grey-darker);
        border: 0.01vw solid var(--grey-darker);
    }
}

select.--required:invalid {
    box-shadow: var(--red-glow-shadow);
}
</style>