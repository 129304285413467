<template>
    <div class="manage-container">

        <div class="search-bar fully">
            <div class="search-bar-container">
                <img src="@/assets/home/search.png" />
                <input v-model="userInput" ref="input" placeholder="Search for species..." type="text" />
            </div>

        </div>

        <div class="tools">
            <div class="pagination-tool">
                <div @click="previousPage" class="btn reverse"> <img src="@/assets/map/arrow-point-to-right.png"> </div>
                <p>{{ pagination_total_results > 0 ? pagination_actual_page : 0 }} of {{ totalPages }}</p>
                <div @click="nextPage" class="btn "> <img src="@/assets/map/arrow-point-to-right.png"> </div>
            </div>
            <p id="total-results">{{pagination_total_results}} found</p>
        </div>

        <button @click="addingSpecies = true" id="add">Reference new species</button>
        <AddSpecies @close="addingSpecies = false" v-if="addingSpecies"/>

        <template v-if="speciesToEdit == null">
            <div class="content-scroller">
                <loadingComp ref="loading"></loadingComp>
                <div v-for="(species, key) in species" :key="key" class="row">
                    <section>
                        <label>Full name</label>
                        <p v-html="species.fullNameHtml"></p>
                    </section>
                    <div class="btns">
                        <!-- <button class="edit">Edit</button>
                        <button  class="delete">Delete</button> -->
                    </div>
                </div>

                <div v-for="(species, key) in not_referenced_species" :key="key" class="row new">
                    <span id="not-referenced-tag"></span>
                    <section>
                        <label>full name</label>
                        <p v-html="species.fullNameHtml"></p>
                    </section>
                    <div class="btns">
                        <button :class="{referencing : species.referencing}" @click="handleReference(species)" id="reference">
                            {{ species.referencing ? "" : "Reference" }}
                            <img src="@/assets/map/valid.png" v-if="species.referencing == 'success'">
                            <img src="@/assets/map/loading.gif" v-if="species.referencing == 'waiting'">
                            <img src="@/assets/map/error.png" v-if="species.referencing == 'fail'">
                        </button>
                    </div>
                </div>
            </div>

        </template>
    </div>

</template>

<script>
import loadingComp from '@/components/loadingComp.vue'
import AddSpecies from '@/components/map/topTools/administration/AddSpecies.vue'
export default
    {
        mounted() {
            this.searchSpecies("")
        },
        components:
        {
            loadingComp,
            AddSpecies
        },
        watch:
        {
            userInput(input) {
                this.pagination_actual_page = 1
                this.searchSpecies(input)
            }
        },
        computed:
        {
            totalPages() {
                return Math.ceil(this.pagination_total_results / this.pagination_per_page)
            }
        },
        methods:
        {
            nextPage() {
                if (this.pagination_actual_page + 1 <= this.totalPages) {
                    this.pagination_actual_page = this.pagination_actual_page + 1
                    this.searchSpecies()
                }
            },
            previousPage() {
                if (this.pagination_actual_page - 1 > 0) {
                    this.pagination_actual_page = this.pagination_actual_page - 1
                    this.searchSpecies()
                }
            },
            async handleReference(species)
            {
                species.referencing = 'waiting'

                this.state = 1            
                var response = await fetch(process.env.VUE_APP_BACKEND_V2_URL + "/addTaxonToDatabase/" + species.scientificName, {
                    method : "POST"
                })
                var json = await response.json();
                console.log(json);
                if(json.error)
                {
                    species.referencing = 'fail'
                }
                if(json.success)
                {
                    species.referencing = 'success'
                }
                
               
            },
            constructGetTaxonDataFromFullNameRequest(name, lines, page) {
                return "/getTaxonDataFromFullName?name=" + name + "&lines=" + lines + "&page=" + page;
            },
            constructGetTaxRefAutocomplete(name, lines, page) {
                return "https://taxref.mnhn.fr/api/taxa/autocomplete?term=" + name + "&size=" + lines + "&page=" + page;
            },
            async searchSpecies() {
                this.$refs.loading.open("Loading")
                this.species = []
                this.not_referenced_species = []

                var result = await fetch(process.env.VUE_APP_BACKEND_V2_URL + this.constructGetTaxonDataFromFullNameRequest(this.userInput, this.pagination_per_page, this.pagination_actual_page), {
                    method: "GET"
                })
                var json = await result.json()                

                if (!json.error) {
                    this.species = json.taxons
                    this.pagination_total_results = json.count
                    if (this.userInput && json.count == 0) {
                        const result_taxref = await fetch(this.constructGetTaxRefAutocomplete(this.userInput, this.pagination_per_page - this.species.length, 1))
                        const json_taxref = await result_taxref.json();
                        if(json_taxref.page.size > 0 ) {
                            let taxa = json_taxref._embedded.taxa;
                            this.not_referenced_species = taxa
                            this.pagination_total_results = taxa.length
                        }
                    }
                }
                

                this.$refs.loading.close()
            }
        },
        data() {
            return {
                addingSpecies : false,
                speciesToEdit: null,
                userInput: "",
                pagination_total_results: 31,
                pagination_actual_page: 1,
                pagination_taxref_actual_page: 1,
                pagination_per_page: 15,
                not_referenced_species: [],
                species: [],
                unreferenced_species: [],
            }
        },
    }
</script>

<style scoped>
.row.new {
    position: relative;
    filter: opacity(0.7);
    transition: 0.1s ease;
}

.row.new:hover{
    position: relative;
    filter: opacity(1);
}

#not-referenced-tag {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 2vw;
    background-color: red;
    filter: opacity(0.7);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

.manage-container .search-bar-container
{
    width: 100%;
}

.manage-container button#add {
    height: 4vw;
    margin: 1vw 0;
    background-color: white;
    color: black;
    width: 100%;
    box-shadow: rgba(0, 0, 0, 0.329) 0px 0.1vw 0.3vw;
    font-family: "Century Gothic"
}
#reference
{
    height: 2.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
}
#reference.referencing
{
    cursor: auto;
    background: none;
    pointer-events: none;
}
#reference img
{
  width: 1.5vw;
}

.manage-container button#add:hover {
    background-color: rgb(240, 240, 240);
}
</style>