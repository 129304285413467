<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>
            <div class="form-field__form-field-content">
                <textarea v-model="model.field_label" required id="field-element"
                    placeholder="This notice is intended to provide information to the user...">
                </textarea>
            </div>
        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps } from 'vue';
import skeletonField from './skeletonField.vue'

const model = defineModel();

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function
});
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

.form-field__form-field-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    textarea {
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        border: 0.01vw solid var(--grey-dark);
        background-color: var(--white);
        /* border: none; */
        border-radius: 0.1vw;
        padding: 0.2vw;

        width: 100%;
        font-size: 1.1vw;
        height: 5.3vw;
        resize: vertical;
        min-height: 5.3vw;

        &:focus {
            outline: none;
            border: 0.01vw solid var(--grey-darker);
        }
    }
}
</style>