<template>
    <div class="alerts-container">
        <alertComp :getAlertColor="getAlertColor" @click="handleAlertClick(alert)"
            v-for="(alert, key) in $store.state.alerts" :key="key" :data="alert"></alertComp>
    </div>
    <div v-if="detailedAlert !== null" class="alert-data-container">
        <img @click="detailedAlert = null" id="comeback" src="@/assets/map/comeback.png" />
        <span class="alert-border" :style="{ backgroundColor: getAlertColor(detailedAlert.priority) }"></span>
        <div class="data">
            <template v-for="(data, key) in detailedAlert" :key="key">
                <section v-if="filterKey(key)">
                    <h6>{{ capitalizeFirstLetter(key) }}</h6>
                    <p>{{ data }}</p>
                </section>
            </template>

        </div>
    </div>
</template>

<script>
import alertComp from "./subComp/alertComp.vue";

export default {
    components: {
        alertComp,
    },
    emits: ['alertClicked'],
    data() {
        return {
            detailedAlert: null,
        };
    },
    methods: {
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        handleAlertClick(alert) {
            this.detailedAlert = alert
            this.$emit("alertClicked", alert)
        },
        filterKey(key) {
            if (key == "lat" || key == "lng") return false
            return true
        },
        getAlertColor(priority) {
            if (priority == "high") return "#ff3523";
            if (priority == "normal") return "#ffc123";
            else return "black";
        },
        setData(data) {
            this.detailedAlert = data
        }
    },
};
</script>

<style scoped>
.alerts-container {
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.alert-data-container {
    height: 100%;
    width: 100%;
    padding: 3vw;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgb(255, 255, 255);
}

img#comeback {
    cursor: pointer;
    filter: invert(1);
    position: absolute;
    left: 4vw;
    z-index: 2;
    top: 3.5vw;
    transform: translateY(-50%);
    width: 1.5vw;
    transition: 0.1s ease;
}

img#comeback:hover {
    transform: translateY(-50%) scale(1.1);
}

.alert-border {
    overflow: visible;
    width: 90%;
    border-radius: 20vw;
    height: 3.5vw;
    position: absolute;
    right: 50%;
    transform: translateX(50%) translateY(-50%);
    top: 3.5vw;
    z-index: 0;
}

.data {
    margin-top: 4vw;
    display: flex;
    padding: 2vw;
    border-left: 0.1vw solid rgba(194, 194, 195, 0.455);
    border-right: 0.1vw solid rgba(194, 194, 195, 0.455);
    flex-direction: column;
    gap: 1vw;
}

.data section {
    display: flex;
    gap: 1vw;
}

.data section h6 {
    width: 25%;
    display: flex;
    align-items: flex-end;
    font-size: 1vw;
    color: var(--black);
    font-family: 'Century Gothic';
    border-bottom: 0.1vw solid rgba(194, 194, 195, 0.455);
    padding: 1vw;
    padding-left: 0.2vw;
}

.data section p {
    border-radius: 0.2vw;
    width: 75%;
    padding: 0.5vw 0;
    background-color: rgba(213, 213, 213, 0.233);
    padding: 1vw;
    color: var(--green);
    font-size: 0.8vw;
}

@media screen and (max-width: 100vh) {

    .alert-border {
        height: 6vw;
        top: 7vw;
    }

    img#comeback {
        width: 4vw;
        top: 7vw;
        left: 6vw;
    }
    .data {
    margin-top: 9vw;}
    .data section h6 {
        width: 30%;
        font-size: 2.5vw;
    }

    .data section p {
        width: 70%;
        font-size: 2.3vw;
    }
}
</style>
