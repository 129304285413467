<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>

            <div class="form-field__form-field-content">
                <div class="form-field-content__content-header">

                    <!-- Field key (input for database) -->
                    <fieldKeySelect v-model="model.field_key" :keys="$store.state.formKeys" />

                </div>
                <div class="form-field-content__content-header">

                    <!-- Collapse Button -->
                    <button class="content-header__collapse-button" :class="{ collapsed: isElementCollapsed }"
                        @click=" collapseElement()">
                        <img src="@/assets/image/forms/form-header-collapse-icon.webp">
                    </button>

                    <!-- Checkbox Label -->
                    <input type="text" v-model="model.field_label" id="field-element" required
                        placeholder="This/These checkbox(s) asks the user...">
                </div>
                <div class="form-field-content__content">

                    <!-- Checkboxes -->
                    <SlickList axis="y" v-model:list="model.checkbox_options" class="content__section-items"
                        :class="{ collapsed: isElementCollapsed }" appendTo=".top-tools" useDragHandle>
                        <SlickItem v-for="(option, index) in model.checkbox_options" class="section-items__item"
                            :key="option" :index="index">

                            <div class="item__drag-handle">
                                <input type="checkbox">
                            </div>

                            <!-- Option Label -->
                            <input type="text" v-model="option.label" placeholder="Option label..." id="field-element"
                                @change="dontAllowExistingOptions()" required>

                            <!-- Drag Handle -->
                            <DragHandle>
                                <!-- Prevent default button press behavior -->
                                <div class="item__drag-handle">
                                    <img src="@/assets/image/forms/form-drag-alternative-icon.svg" alt="Drag">
                                </div>
                            </DragHandle>

                            <!-- Delete Button -->
                            <button class="item__drag-handle --click"
                                @click="removeOptionFromCheckboxArray(model, option)">
                                <img src="@/assets/image/forms/form-field-trash-bin-icon.svg"
                                    style="width: 1.5vw; height: 1.5vw;">
                            </button>

                        </SlickItem>
                    </SlickList>

                </div>
                <div class="form-field-content__content-footer">
                    <form class="content-footer__new-option" :class="{ collapsed: isElementCollapsed }"
                        @submit.prevent="addOptionToCheckboxArray(model, model.hidden_newOption)">
                        <input type="text" v-model="model.hidden_newOption" placeholder="New choice...">
                        <button type="submit">
                            <img src="@/assets/image/forms/select-field-add-button.svg"
                                style="width: 1.5vw; height: 1.5vw;">
                        </button>
                    </form>
                </div>
            </div>

        </template>
        <template v-slot:options>
            <!-- <div class="parameter-group__option">
                <button class="--option">
                    <label>Default state:</label>
                    <select v-model="model.checkbox_default">
                        <option value="true">Checked</option>
                        <option value="false">Unchecked</option>
                    </select>
                </button>
            </div> -->
            <div class="parameter-group__option">
                <button class="--option">
                    <label>🔑 Required :</label>
                    <input type="checkbox" v-model="model.field_required">
                </button>
            </div>
        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps, ref } from 'vue';
import skeletonField from './skeletonField.vue'
import fieldKeySelect from "@/components/map/topTools/forms/customElements/fieldKeySelect.vue";
import {
    SlickList, SlickItem, DragHandle
} from 'vue-slicksort';

const model = defineModel();
const isElementCollapsed = ref(false);

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function
});

/**
 * Method responsible for adding an option to a select field
 *
 * @param {Object} element - form field element
 * @param {string} option - option to add
 * @version 1.0.3
 */
function addOptionToCheckboxArray(element, option) {
    if (option === undefined || option === '' || element.checkbox_options.map(option => option.label).includes(option)) {
        element.hidden_newOption = '';
        return
    } else if (option.trim() === '') {
        element.hidden_newOption = '';
        return
    }
    element.checkbox_options.push(
        {
            label: option,
            // hidden_id: Date.now()
        }
    );
    element.hidden_newOption = '';
}
function removeOptionFromCheckboxArray(element, option) {
    element.checkbox_options.splice(element.checkbox_options.indexOf(option), 1);
}
function collapseElement() {
    isElementCollapsed.value = !isElementCollapsed.value;
}
function dontAllowExistingOptions() {
    // If the users rename an existing option to another existing option, the input will be cleared and the option will not be renamed
    for (let i = 0; i < this.model.checkbox_options.length; i++) {
        for (let j = 0; j < this.model.checkbox_options.length; j++) {
            if (i !== j && this.model.checkbox_options[i].label === this.model.checkbox_options[j].label) {
                this.model.checkbox_options[j].label = ''
            }
        }
    }
}
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

.form-field__form-field-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
}

input {
    height: 1.8vw;
    width: 50%;
    font-size: 1.2vw;
    padding: 0.3vw;

    &:focus {
        outline: 0.01vw solid var(--grey-darker);
    }
}

img {
    pointer-events: none;
}

.form-field-content__content-header {
    display: flex;
    align-items: center;

    margin-bottom: 0.2vw;

    input {
        width: 100%;
        background-color: var(--white);
        border: 0.01vw solid var(--grey-darker);
        border-radius: 0 var(--small-radius) var(--small-radius) 0;
        box-shadow: var(--small-shadow);
    }
}

.content-header__collapse-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background-color: var(--grey-dark);
    border: 0.01vw solid var(--grey-darker);
    border-radius: var(--small-radius) 0 0 var(--small-radius);

    height: 1.8vw;

    img {
        height: 1.8vw;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        pointer-events: none;

        transition: transform 0.5s ease-in-out;
        transform: rotate(-90deg);
    }

    &.collapsed img {
        transform: rotate(90deg);
    }
}

.content__section-items {
    display: flex;
    flex-direction: column;

    margin-left: 1vw;
    gap: 0.2vw;

    &.collapsed {
        max-height: 0;
        padding: 0;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}

.section-items__item {
    display: flex;
    align-items: center;

    height: 1.8vw;

    background-color: var(--grey);
    /* border-radius: 4px; */

    img {
        height: 1.8vw;
        width: 1.8vw;
    }
}

.content-footer__new-option {
    display: flex;
    align-items: center;

    margin-top: 0.5vw;

    input {
        width: 100%;
        background-color: var(--grey-light);
        border: 0.01vw solid var(--grey-dark);
        border-radius: var(--small-radius) 0 0 var(--small-radius);
    }

    button {
        height: 1.8vw;
        width: 1.8vw;

        border-radius: 0 var(--small-radius) var(--small-radius) 0;
        background-color: var(--grey-dark);
        border: 0.01vw solid var(--grey-darker);
    }
}

.item__drag-handle {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3vw;

    &.--click {
        /* align to top right */
        margin-left: auto;
        cursor: pointer;
    }
}

.parameter-group__option {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5vw;

    .--option {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--grey-light);
        border-radius: var(--small-radius);
        border: 0.01vw solid var(--grey-darker);

        padding: 0.2vw;
        font-size: 0.9vw;

        label {
            margin-right: 0.2vw;
            /* filter: grayscale(100%); */
        }

        input {
            display: inline-flex;
            flex-direction: row;

            width: 2vw;
            height: 1.1vw;
            font-size: 0.9vw;
            padding: 0.2vw;

            &:focus {
                border: none;
                /* outline: 0.01vw solid var(--grey-darker); */
                /* border-radius: var(--small-radius); */
            }
        }

        input[type="checkbox"] {

            width: 1vw;
            height: 1vw;

            border-radius: var(--small-radius);
            border: none;
            outline: none;
        }
    }
}

/* .form-field__form-field-content {
    width: 100%;
    gap: 0.5vw;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    input {
        width: 1.8vw;
        height: 1.8vw;
    }

    textarea {
        border: 0.01vw solid var(--grey);
        border-radius: 0.1vw;
        padding: 0.2vw;

        width: 100%;
        font-size: 1.2vw;
        height: 1.8vw;
        overflow: auto;
        resize: vertical;
        min-height: 1.8vw;
        max-height: 5vw;
    }
} */

/* .parameter-group__option {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5vw;

    .--option {
        display: inline-flex;
        background-color: var(--grey-light);
        border-radius: var(--small-radius);
        border: 0.01vw solid var(--grey-darker);

        padding: 0.2vw;
        font-size: 0.9vw;

        label {
            margin-right: 0.2vw;
        }

        select {
            background-color: var(--grey-light);
            border-radius: var(--small-radius);
            border: 0.01vw solid var(--grey-darker);
            padding: 0.2vw;
            font-size: 0.9vw;

            option {
                background-color: var(--grey-light);
                border-radius: var(--small-radius);
                border: 0.01vw solid var(--grey-darker);
                padding: 0.2vw;
                font-size: 0.9vw;
            }
        }

    }
} */
</style>