<template>
  <loadingComp ref="loading"></loadingComp>
  <div v-if="showData" class="container">
    <div class="admin-left">
      <h5 @click="handleAddRole">Roles</h5>
      <div class="scroller">
        <span @click="handleRoleClick(role, key)" :class="{ selected: key == selectedRoleKey }"
          v-for="(role, key) in $store.state.roles" :key="key">{{ role.name }}</span>
      </div>
    </div>
    <div class="admin-right">
      <div :style="{paddingBottom: change ? '4vw' : 0 }" class="scroller">
        <h5>Manage role : {{ $store.state.roles[selectedRoleKey].name }}</h5>
        <div class="row">
          <h6>Role name :</h6>
          <input v-model="selectedRole.name" type="text">
        </div>
        <div v-for="permission in $store.state.permissions" :key="permission.Permission_code" class="row">
          <h6>{{ permission.Permission_code }}</h6>
          <p>{{ permission.En_name }}</p>
          <div class="checkbox-wrapper-2">
            <input @change="handleChangeCheckPermission($event, permission.Permission_code)"
              :checked="selectedRole.permissions.includes(permission.Permission_code)" type="checkbox" class="sc-gJwTLC ikxBAC">
          </div>
        </div>
      </div>
      <button @click="handleSaveClick" :class="{ show: change }" id="save">Save changes</button>
    </div>
  </div>
</template>

<script>
import loadingComp from '@/components/loadingComp.vue'

export default {
  components : {
    loadingComp
  },
  data() {
    return {
      selectedRole:null,
      selectedRoleKey: 0
    }
  },

  computed: {
    change() {
      return !this.deepEqual(this.selectedRole, this.$store.state.roles[this.selectedRoleKey])
    },
    showData(){
      return this.$store.state.permissions !== null && this.$store.state.permissions != 'waiting' && 
      this.$store.state.roles !== null && this.$store.state.roles != 'waiting' && this.selectedRole != null
    }
  },

  async mounted() {
    this.$refs.loading.open("Pulling roles")
    await this.$store.dispatch("getAllRoles")
    await this.$store.dispatch("getAllPermissions")
    this.selectFirstRole()
    this.$refs.loading.close()
  },
  methods: {
    selectFirstRole(){
      this.$store.state.roles[0].permissions.sort()
      this.selectedRoleKey = 0
      this.selectedRole = JSON.parse(JSON.stringify(this.$store.state.roles[0]))
    },
    deepEqual(object1, object2) {
      if(object1 == null || object2 == null ) return false
      const keys1 = Object.keys(object1);
      const keys2 = Object.keys(object2);

      if (keys1.length !== keys2.length) {
        return false;
      }

      for (const key of keys1) {
        const val1 = object1[key];
        const val2 = object2[key];
        const areObjects = this.isObject(val1) && this.isObject(val2);
        if (
          areObjects && !this.deepEqual(val1, val2) ||
          !areObjects && val1 !== val2
        ) {
          return false;
        }
      }

      return true;
    },
    isObject(object) {
      return object != null && typeof object === 'object';
    },
    handleRoleClick(role, key) {
      role.permissions.sort()
      this.selectedRoleKey = key
      this.selectedRole = JSON.parse(JSON.stringify(role));
    },
    handleSaveClick() {
      
      const arrayTwo = this.selectedRole['permissions']; // Changed array
      const arrayOne = this.$store.state.roles[this.selectedRoleKey]['permissions'] // not changed

      const removed = arrayOne.filter(item => !arrayTwo.includes(item));
      const added = arrayTwo.filter(item => !arrayOne.includes(item));

      const body = {
        removed: removed,
        added: added,
        name : this.selectedRole.name
      };
      body


      fetch(process.env.VUE_APP_BACKEND_V2_URL + "/updateRole/" + this.selectedRole.id, {
        method : "POST",
        body : JSON.stringify(body)
      }).then(res=>{
        res.json().then(json=>{
          if (!json.error) {
            this.$store.commit('updateRole', { key: this.selectedRoleKey, data: this.selectedRole })
            this.selectedRole = JSON.parse(JSON.stringify(this.selectedRole))
          }
        })
      });
      
    },
    handleChangeCheckPermission(e, permissionId) {
      if(this.selectedRole == null) return
      if (e.target.checked) this.selectedRole.permissions.push(permissionId)
      else {
        const index = this.selectedRole.permissions.indexOf(permissionId)
        this.selectedRole.permissions.splice(index, 1);
        
      }
      this.selectedRole.permissions.sort()
    },

    handleAddRole(){
      fetch(process.env.VUE_APP_BACKEND_V2_URL + "/newRole", {
        method :"POST",
      }).then(res=>{
        res.json().then(json=>{
          this.$store.commit('addRole', json)
          this.selectFirstRole()
        })
      })
      
      
    }
  }
}
</script>

<style scoped>

.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.admin-left {
  height: 100%;
  width: 28%;
  padding: 1vw;
}


.admin-right {
  width: 70%;
  position: relative;
  padding: 0 1vw;
  height: 100%;
  border-left: 0.1vw solid rgba(128, 128, 128, 0.153);
}


h5 {
  color: var(gray);
  font-size: 1.2vw;
  padding: 0.5vw 1vw;
  font-family: 'Century Gothic';
  position: relative;
}
.admin-left h5{
  cursor: pointer;
}

.admin-left span {
  display: block;
  margin: 1vw 0;
  border-radius: 1vw;
  color: var(gray);
  font-size: 0.9vw;
  font-family: 'Century Gothic';
  width: 100%;
  height: auto;
  cursor: pointer;
  padding: 1vw;
  background-color: rgba(128, 128, 128, 0.045);
}

.admin-left span:hover {
  background-color: rgba(128, 128, 128, 0.082);
}

.admin-left span.selected {
  background-color: rgba(128, 128, 128, 0.321);
}


.admin-left h5::after,
.admin-left h5::before {
  pointer-events: all;
  content: "";
  height: 0.1vw;
  width: 0.8vw;
  background-color: rgba(0, 0, 0, 0.744);
  position: absolute;
  right: 0.6vw;
  top: 50%;
  transform: translateY(50%);
}

.admin-left h5::before {
  transform: translateY(50%) rotate(90deg);
}

.scroller {
  height: 100%;
  overflow: auto;

}

.row {
  position: relative;
  padding: 1vw;

  margin-top: 0vw;
  margin-right: 1.5vw;
  display: flex;
  flex-direction: column;
  gap: 0.6vw;
  border-top: 0.1vw solid rgba(128, 128, 128, 0.112);
}

.row h6 {
  font-size: 1vw;
  font-family: 'Century Gothic';
}

.row p {
  padding-right: 3vw;
  font-size: 0.9vw;
  font-family: 'Century Gothic';
}

.row input[type="text"] {
  width: 100%;
  border-radius: 0.2vw;
  font-size: 0.9vw;
  border: solid 0.1vw rgba(128, 128, 128, 0.364);
  padding: 1vw;
}

.row input[type="text"]:focus {
  outline: none;
  border-color: var(--black);
}

/* checkbox from getcssscan.com */
.checkbox-wrapper-2 {
  position: absolute;
  right: 0;
  top: 50%;

}

.checkbox-wrapper-2 .ikxBAC {
  appearance: none;
  cursor: inherit;
  background-color: #dfe1e4;
  border-radius: 72px;
  border-style: none;
  flex-shrink: 0;
  height: 1.5vw;
  margin: 0;
  width: 2.5vw;
}

.checkbox-wrapper-2 .ikxBAC::before {
  bottom: -6px;
  cursor: pointer;
  content: "";
  left: -6px;
  position: absolute;
  right: -6px;
  top: -6px;
}

.checkbox-wrapper-2 .ikxBAC,
.checkbox-wrapper-2 .ikxBAC::after {

  transition: all 100ms ease-out;
}

.checkbox-wrapper-2 .ikxBAC::after {
  cursor: pointer;
  background-color: #fff;
  border-radius: 50%;
  content: "";
  height: 1.1vw;
  left: 0.3vw;
  position: absolute;
  top: 0.2vw;
  width: 1.1vw;
}

.checkbox-wrapper-2 input[type=checkbox] {
  cursor: default;
}

.checkbox-wrapper-2 .ikxBAC:hover {
  background-color: #c9cbcd;
  transition-duration: 0s;
}

.checkbox-wrapper-2 .ikxBAC:checked {
  background-color: var(--orange);
}

.checkbox-wrapper-2 .ikxBAC:checked::after {
  background-color: #fff;
  left: 1.1vw;
}

.checkbox-wrapper-2 :focus:not(.focus-visible) {
  outline: 0;
}

button#save {
  position: absolute;
  font-size: 0.9vw;
  right: 2vw;
  cursor: pointer;
  padding: 1vw 5vw;
  border-radius: 1vw;
  background-color: rgb(11, 209, 11);
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
  color: white;
  border: none;
  transition: 0.1s ease;
  pointer-events: none;
  bottom: 1vw;
  opacity: 0;
}

button#save.show {
  pointer-events: all;
  opacity: 1;
}

button#save:hover {
  scale: 1.04;
}
</style>