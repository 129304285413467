<template>
    <div v-if="!isTextArea" class="input-wrapper">
        <span class="icon" :class="{ '--right': rightAlignIcon }">
            <slot name="icon" />
        </span>
        <div class="custom-button" v-if="attachCustomButton && rightAlignIcon">
            <slot name="customButtonIcon" />
        </div>
        <input type="text" v-model="model" :placeholder="placeholder" :disabled="disabled"
            :class="{ '--right': rightAlignIcon, '--required': isRequired, '--version-indicator': isVersionIndicator }"
            :required="isRequired" />
        <div class="custom-button" v-if="attachCustomButton && !rightAlignIcon">
            <slot name="customButtonIcon" />
        </div>
    </div>
    <div v-else class="input-wrapper">
        <span class="icon" :class="{ '--right': rightAlignIcon, '--textarea': isTextArea }">
            <slot name="icon" />
        </span>
        <textarea v-model="model" :placeholder="placeholder" :disabled="disabled"
            :class="{ '--right': rightAlignIcon, '--required': isRequired }" :required="isRequired" />
    </div>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps } from 'vue'
const model = defineModel();

defineProps({
    rightAlignIcon: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    isTextArea: {
        type: Boolean,
        default: false,
    },
    isVersionIndicator: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    attachCustomButton: {
        type: Boolean,
        default: false,
    },
})
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
.input-wrapper {
    position: relative;
    display: flex;
    width: 100%;

    &:deep(img) {
        width: 1.5vw;
        height: 1.5vw;
        pointer-events: none;
    }
}

.custom-button :deep(button) {
    display: flex;
    align-items: center;
    justify-content: center;

    /* background-color: var(--white); */
    border: 0.01vw solid var(--grey-darker);
    border-radius: var(--small-radius);
    box-shadow: var(--small-shadow);

    cursor: pointer;

    width: 1.8vw;
    height: 1.8vw;
}

.icon {
    position: absolute;
    left: 0.2vw;
    top: 0.15vw;
    filter: grayscale(100%);

    &::after {
        left: 1.5vw;
    }
}

.icon {
    &:not(.--textarea) {

        &::after,
        &.--right::after {
            content: '|';
            top: -0.05vw;
            position: absolute;
            color: var(--grey-darker);
            font-size: 1.2vw;
        }
    }

    &.--textarea::after {
        content: '|';
        top: -0.05vw;
        position: absolute;
        color: var(--grey-darker);
        font-size: 1.2vw;
    }
}

.icon.--right {
    left: auto;
    right: 0.2vw;

    &::after {
        left: auto;
        right: 1.2vw;
    }
}

input,
textarea {
    width: 100%;
    font-size: 1.1vw;
    color: var(--black-light);
    border: 0.01vw solid var(--grey-darker);
    border-radius: var(--small-radius);
    box-shadow: var(--small-shadow);

    &:disabled {
        background-color: var(--grey-light);
        color: var(--grey-darker);
    }

    &:focus {
        outline: 0.01vw solid var(--grey-darker);
        border: 0.01vw solid var(--grey-darker);
    }
}

input {
    height: 1.8vw;
    padding-left: 2.4vw;

    &.--right {
        padding: 0.3vw 2.4vw 0.3vw 0.3vw;
    }
}

input.--required:invalid,
textarea.--required:invalid {
    box-shadow: var(--red-glow-shadow);
}

textarea {
    height: 3.5vw;
    min-height: 3.5vw;
    resize: none;
    max-height: 10vw;
    padding: 0.2vw 0.3vw 0.3vw 2.4vw;
}
</style>