<template>
    <navbarComp :navStyleData="navStyleData" ref="navbar"></navbarComp>
    <main :style="{ marginTop: navStyleData.height + 'vw', height: 'calc(100vh - ' + navStyleData.height + 'vw)' }"
        ref="main">
        <div class="left">
            <h3>{{ activeAirport.name }} Biodiversity management</h3>
        </div>
        <div class="right">
            <form @submit.prevent="handleFormSubmit">
                <h1>LOGIN</h1>
                <div class="container">
                    <img src="@/assets/navbar/account.png">
                    <input @input="handleInput" v-model="email" type="email" autocomplete="on" name="email"
                        placeholder="Email...">
                </div>
                <div class="container">
                    <img src="@/assets/image/log/lock.png">
                    <input @input="handleInput" v-model="password" type="password" name="password" autocomplete="on"
                        placeholder="password...">
                </div>
                <div class="container">
                    <select>
                        <option v-for="(role, key) in $store.state.roles" :key="key">{{ role.name }}</option>
                    </select>
                </div>
                <div class="container disabled">
                    <img src="@/assets/image/log/insurance.png">
                    <input type="current-password" disabled placeholder="OTP Disabled">
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    </main>
</template>

<script>
import navbarComp from '@/components/navbarComp.vue'
import { signInWithEmailAndPassword } from "firebase/auth";
import { auth } from "@/app/firebase";
signInWithEmailAndPassword, auth
export default {
    data() {
        return {
            navStyleData: { height: 0 },
            email: '',
            password: ''
        }
    },
    components: {
        navbarComp,
    },
    async mounted() {
        // verify if connected
        await this.$store.dispatch('waitLogged')
        if (this.$store.state.logged) {
            const activeAirportParam = this.$route.params.airport;
            this.$router.push('/privateMap/' + activeAirportParam)
            return
        }
    },
    methods: {
        handleFormSubmit(e) {
            let error = false
            const inputs = e.target.querySelectorAll("input")
            inputs.forEach(element => {
                if (element.value == '' && !element.disabled) {
                    element.parentNode.classList.add("error")
                    error = true
                }
                if (element.name == 'email' && !this.validateEmail(element.value)) {
                    element.parentNode.classList.add("error")
                    error = true
                }
            });
            if (error) return

            signInWithEmailAndPassword(auth, this.email, this.password)
                .then(() => {
                    this.$router.push('/privateMap/' + this.$store.state.activeAirportKey)
                })
                .catch((error) => {
                    error
                    inputs.forEach(e => { if (!e.disabled) e.parentNode.classList.add("error") })
                });
        },
        validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
        },
        handleInput(e) {
            e.target.parentNode.classList.remove("error")
        },
    },
    computed: {
        activeAirport() {
            return this.$store.state.activeAirport
        },
    },
}
</script>

<style scoped>
h1 {
    font-size: 3vw;
}

main {
    width: 100%;
    display: flex;
    min-height: 33vw;
}

.left {
    width: 40%;
    background-color: var(--green);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.left h3 {
    font-size: 2vw;
    padding: 4vw;
    font-family: 'Century Gothic';
    font-weight: bolder;
    text-align: center;
}

.right {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 60%;
    height: 100%;
}

form {
    height: 90%;
    max-height: 40vw;
    padding: 3vw;
    width: 70%;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 0.3vw 0.8vw;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
}

form .container {
    width: 70%;
    display: flex;
    align-items: center;
    padding: 0 1vw;
    justify-content: center;
    flex-direction: row;
    background-color: rgba(231, 231, 231, 0.437);
}

form .container img {
    width: 2vw;
    padding-right: 0.5vw;
    opacity: 0.5;
    border-right: 0.1vw solid black;
}

form .container.error {
    outline: 0.1vw solid red;
}

form .container.disabled {
    background-color: rgb(187, 187, 187);
}

form .container input,
form .container select {
    font-family: 'Century Gothic';
    padding: 1vw;
    font-size: 1vw;
    background: none;
    border: none;
    width: 100%;
}


form .container input:focus,
form .container select:focus {
    outline: none;
}

form button {
    width: 70%;
    background-color: var(--green);
    font-size: 0.8vw;
    border: none;
    color: white;
    cursor: pointer;
    padding: 1vw;
}


@media screen and (max-width: 100vh) {
    main {
        flex-direction: column;
        height: fit-content !important;
    }

    .left {
        height: 20vw;
        width: 100%;
    }

    .left h3 {
        font-size: 3vw;
    }

    .right {
        height: 80vw;

        width: 100%;
    }

    h1 {
        font-size: 4vw;
    }

    form {
        padding: 5vw 0;
        width: 90%;
        max-height: none;
    }

    form .container input,
    form .container select,
    form button {
        font-size: 3vw;
        padding: 2vw;
    }

    form .container img {
        width: 4vw;
    }

}
</style>