import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
import species from "./species";
import observations from "./observations";
observations
// import observations from "./observations";

const firebaseConfig = {
    apiKey: "AIzaSyABUwTZ94Jilv6FZoIeP1p5LB0LS0cwXAU",
    authDomain: "olga-6a1a7.firebaseapp.com",
    projectId: "olga-6a1a7",
    storageBucket: "olga-6a1a7.appspot.com",
    messagingSenderId: "522766382109",
    appId: "1:522766382109:web:4459b529d372337a8702f4",
    measurementId: "G-T1E1QNSVXR",
};



// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const auth = getAuth(app);
export const db = getFirestore(app);

export const getObservations = async () => {

    let observations = []
    await fetch(process.env.VUE_APP_BACKEND_V2_URL + "/getAllObservations", {
        method: 'GET',
    }).then(res => res.json().then(json => {
        observations = json
        console.log(observations);
        observations.map(o => {
            if (o.date && o.date.seconds) o.date = o.date.seconds
        })
    }))

    return observations;
};

export const getSpecies = async () => {
    // const queryFloreInvasive = await getDocs(
    //     collection(db, "especes_flore_invasive")
    // );
    // const queryFloreProtege = await getDocs(
    //     collection(db, "especes_flore_protege")
    // );

    // queryFloreInvasive.forEach((doc) => {
    //     species.push(doc.data());
    // });
    // queryFloreProtege.forEach((doc) => {
    //     species.push(doc.data());
    // });


    return species;
};
