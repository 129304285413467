<template>
    <div v-if="!hidden" class="popup-container">
        <div class="popup">
            <h3>{{ title }}</h3>
            <div class="btns">
                <template  v-for="(button, key) in btns" :key="key">
                    <button @click="handleButtonClick(button)" v-if="button.types.includes(this.type)" :id="button.id">{{ button.title }}</button>
                </template>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            title: "Are you sure ?",
            type: "dilem",
            hidden: true,
            data : null,
            action : null,
            btns : [
                {
                    types : ["dilem", "dilem-revert"],
                    title : "Cancel", 
                    id : 'cancel',
                    content : "cancel",
                },
                {
                    types : ["dilem", "validation"],
                    id: 'confirm',
                    title : "Ok", 
                    content : "ok",
                },
                {
                    types : ["dilem-revert"],
                    id: 'confirm-revert',
                    title : "Ok", 
                    content : "ok",
                },
            ]
        }
    },

    methods: {
        handleButtonClick(button){
            button
            this.hidden = true
            this.$emit('response', this.action, button.content, this.data);

        },
        show(title, type,action, data) {
            this.data = data
            this.action = action
            this.title = title
            this.type = type
            this.hidden = false
        }
    }

}
</script>

<style scoped>
.popup-container {
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.175);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 999999;
}

.popup{
    height: 15vw;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
    width: 35vw;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: rgb(255, 255, 255);
}

.popup-container h3 {
    font-family: 'Century Gothic Bold';
    color: var(--green);
    font-size: 1.5vw;
    padding: 2vw;
    text-align: center;
}

.btns {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 2vw;
}

.btns button {
    font-family: 'Century Gothic';
    font-size: 1vw;
    padding: 1vw;
    width: 8vw;
    border: none;
    opacity: 0.85;
    cursor: pointer;
}

.btns button:hover {
    opacity: 1;
}

#confirm {
    background-color: rgb(131, 254, 131);
    color: var(--black);
}

#confirm-revert {
    background-color: rgb(255, 47, 47);
    color: white
}
</style>