<template>
    <div class="edit-profile">
        <form @submit.prevent="handleFormSubmit">
            <h5>{{ newObj ? 'New' : 'Edit' }} {{ title }}</h5>

            <template v-for="(row, key) in rows" :key="key">
                <div v-if="newObj || !row.onlyCreate" class="entry">
                    <p>{{ row.title }} :</p>
                    <input v-if="row.type == 'text' || row.type == 'password' || row.type == 'mail' || row.type == 'date'" @input="handleInput" v-model="localData[row.key]"
                        :name="row.key" :placeholder="row.placeholder" :type="row.type">
                    <select v-if="row.type == 'select'">
                        <option v-for="(option, key2) in row.options" :key="key2">
                            {{ option.name }}
                        </option>
                    </select>
                    <div class="list" v-if="row.type == 'list'">
                        <select @change="(event) => handleListChange(event, row)">
                            <option v-show="false" selected>ADD</option>
                            <template v-for="(option, key2) in row.options" :key="key2">
                                <option v-if="true">
                                    {{ row.optionKey ? option[row.optionKey] : option }}
                                </option>
                            </template>
                            
                        </select>
                        <div class="in-list">
                            <span @click="localData[row.key].splice(key, 1)" v-for="(e, key) in localData[row.key]" :key="key">{{row.optionKey ? e[row.optionKey] : e}}</span>
                        </div>
                    </div>
                    <div class="choice" v-if="row.type == 'choice'">
                        <span @click="localData[row.key] = choice" :class="{selected : choice == localData[row.key], false : localData[row.key] == false}" v-for="(choice, key) in row.choices" :key="key">{{ choice }}</span>
                    </div>
                </div>  
            </template>


            <div class="btns">
                <button @click="$emit('cancel')" id="cancel">Cancel</button>
                <button type="submit">Save</button>
            </div>
        </form>
    </div>
</template>

<script>
export default {
    props: {
        data: Object,
        newObj: Boolean,
        rows: Object,
        title : String
    },
    data() {
        return {
            localData: {}
        }
    },
    mounted() {
        this.localData = JSON.parse(JSON.stringify(this.$props.data))
        console.log(this.localData);
    },
    methods: {
        handleListChange(event, row)
        {
            let optionToPush = null;
            if(row.optionKey) optionToPush = row.options.find(e=> e[row.optionKey] == event.target.value)
            else optionToPush = row.options.find(e=> e == event.target.value)

            let optionAlreadyIncluded ;
            if(row.comparisonKey) optionAlreadyIncluded = this.localData[row.key].find(e=> e[row.comparisonKey] == optionToPush[row.comparisonKey]);
            else optionAlreadyIncluded = this.localData[row.key].find(e=> e == optionToPush)
            
            if(!optionAlreadyIncluded) this.localData[row.key].push(optionToPush)

            event.target.selectedIndex = 0
        },
        handleFormSubmit(e) {
            let error = false
            e.target.querySelectorAll("input").forEach(element => {
                if (element.value == '') {
                    element.parentNode.classList.add("error")
                    error = true
                }
                if (element.name == 'mail' && !this.validateEmail(element.value)) {
                    element.parentNode.classList.add("error")
                    error = true
                }
            });
            if (error) return
            // if editing profile
            if (!this.$props.newObj) {
                this.$emit("update", this.localData)
            } else {
                this.$emit("new", this.localData)
            }
        },
        validateEmail(email) {
            const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            return regex.test(email);
        },
        handleInput(e) {
            e.target.parentNode.classList.remove("error")
        },
    }
}
</script>

<style scoped>
form {
    padding: 2vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

h5 {
    font-size: 1.5vw;
    width: 100%;

    font-family: 'Century Gothic';
    color: var(--black);
}

.edit-profile {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: white;
    width: 100%;
}



.entry {
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: auto;
    background-color: rgba(128, 128, 128, 0.063);
}

.entry.error {
    outline: 0.1vw solid red;
}

.entry p {
    width: 100%;
    font-family: 'Century Gothic';
    font-size: 0.8vw;
}


.entry input,
.entry select, .entry .list {
    background: none;
    font-family: 'Century Gothic';
    padding: 1vw 0;
    padding-bottom: 0.2vw;
    font-size: 0.9vw;
    border: none;
    height: 100%;
    width: 100%;
}




.entry input:focus,
.entry select:focus {
    outline: none;
}


.list select{
    font-family: 'Century Gothic Bold';
    outline: 0.1vw solid rgb(207, 207, 207);
    margin-bottom: 1vw;
    padding: 1vw 0.4vw; 
    padding-left: 2.5vw;
    appearance: none;
    background: url(@/assets/map/plus.png) no-repeat left 1vw center;
    background-size: 1.2vw;
    cursor: pointer;
}

.list select:hover {
    outline: 0.1vw solid rgb(146, 146, 146) !important;
}

.list select:focus{
    outline: 0.1vw solid rgb(99, 99, 99);
}



.list select option{
    font-family: 'Century Gothic';
}



.list .in-list
{
    display: flex;
    flex-wrap: wrap;
    gap: 1vw;
}

.list .in-list span
{
    font-family: 'Century Gothic';
    height: 3vw;
    padding-left: 1vw;
    padding-right: 2vw;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 0.5vw;
    font-size: 1vw;
    color: var(--black);
    background-color: rgb(228, 228, 228);
    position: relative;
    pointer-events: none;
}

.list .in-list span::after, .list .in-list span:before
{
    pointer-events: all;
    cursor: pointer;
    content: "";
    height: 0.8vw;
    background-color: rgb(146, 0, 0);
    width: 0.1vw;
    position: absolute;
    right: 1vw;
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
}
.list .in-list span:before
{
    transform: translateY(-50%) rotate(45deg);
}

.choice {
    display: flex;
    gap: 0.5vw;
    padding-top: 1vw;
}
.choice span
{
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-family: 'Century Gothic';
    width: 9vw;
    font-size: 1vw;
    padding: 1vw;
    background-color: rgb(227, 227, 227);
    transition: 0.1s ease;
}
.choice span:hover
{
    background-color: rgb(205, 205, 205);
}

.choice span.selected{
    background-color: rgb(85, 228, 163);
    color: white;
}
.choice span.selected.false{
    background-color: rgb(255, 131, 131);
}

form .btns {
    display: flex;
    gap: 1vw;
    justify-content: flex-end;
}

form button {
    background: var(--green);
    transition: 0.1s ease;
    opacity: 0.9;
    width: 20%;
    letter-spacing: 0.1vw;
    color: white;
    border: none;
    font-family: 'Century Gothic';
    cursor: pointer;
    font-weight: bolder;
    padding: 1vw;
    font-size: 1vw;
}

form button#cancel {
    background-color: rgba(128, 128, 128, 0.168);
    color: black;
}

form button#cancel:hover {
    background-color: rgba(128, 128, 128, 0.281);
}


form button[type="submit"]:hover {
    opacity: 1;
}

.reminder {
    font-family: 'Century Gothic';
    font-size: 1vw;
    color: var(--orange);
}
</style>