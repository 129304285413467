<template>
  <div :class="{ toComplete: toComplete }" class="observation">
    <img :src="data.imageUrl ? data.imageUrl : data.image">
    <span id="more-btn">{{ toComplete ? 'Click to complete' : 'Learn more' }}</span>
    <div class="data">
      <section v-if=" data['nom espece']">
        <h6>Species name</h6>
        <p>{{ data['nom espece']}}</p>
      </section>
      <section v-if="data['date']">
        <h6>Observed on</h6>
        <p>{{ showDate(data['date'])  }}</p>
      </section>
      <section v-if="!data['date'] && !data['nom espece']">
        <p>No information</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Object
  },
  methods : 
  {
    showDate(date)
        {
            if(date){
                return new Date(date * 1000).toDateString() 
            }else return false
        },
  },
  computed:
  {
    toComplete() {
      return this.$props.data.toComplete
    }
  }
}
</script>

<style scoped>
.observation {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  height: 6vw;
  border: 0.1vw solid rgba(0, 0, 0, 0.134);
  padding: 1vw;
  border-radius: 1vw;
  overflow: hidden;
}

.observation img {
  width: 4vw;
  height: 4vw;
  object-fit: cover;
  border-radius: 100%;
}

.data {
  width: 80%;
  display: flex;
  justify-content: space-around;
}

.observation.toComplete {
  border-right: 1vw solid rgb(60, 116, 189);
}


.data section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.2vw;

}

.data section h6 {
  font-size: 1vw;
  color: var(--green);
}

.data section p {
  font-size: 0.8vw;
  padding: 0 0.5vw;
  width: 13vw;
  text-align: center;
}

#more-btn {
  transition: 0.1s ease;
  opacity: 0;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 1.5vw;
  background-color: gray;
}

.observation.toComplete #more-btn {
  background-color: rgb(60, 116, 189);
}

#more-btn:hover {
  opacity: 0.95;
}

@media screen and (max-width: 100vh) {
  .observation {
    height: 15vw;
    padding: 4vw;
    border-radius: 5vw;
  }

  .observation img {
    width: 10vw;
    height: 10vw;
  }

  .data section {
    width: 50%;
    justify-content: center;
  }

  .data section h6 {
    display: none;
  }

  .data section p {
    padding: 1.5vw;
    font-size: 2.1vw;
    width: 100%;
    text-align: left;
  }

}
</style>