<template>
    <genericTextInput v-model="model" :placeholder="placeholder" :rightAlignIcon="rightAlignIcon"
        :isRequired="isRequired" :isTextArea="isTextArea" :disabled="disabled" :isVersionIndicator="isVersionIndicator"
        :attachCustomButton="attachCustomButton">
        <template #icon>
            <slot name="icon"></slot>
        </template>
        <template #customButtonIcon>
            <slot name="customButtonIcon"></slot>
        </template>
    </genericTextInput>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineProps, defineModel } from 'vue';
import genericTextInput from '@/components/map/topTools/forms/customElements/commonElements/genericTextInput.vue'

const model = defineModel()

defineProps({
    rightAlignIcon: {
        type: Boolean,
        default: false,
    },
    isRequired: {
        type: Boolean,
        default: false,
    },
    isTextArea: {
        type: Boolean,
        default: false,
    },
    isVersionIndicator: {
        type: Boolean,
        default: false,
    },
    placeholder: {
        type: String,
        default: '',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
    attachCustomButton: {
        type: Boolean,
        default: false,
    },
});



</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped></style>
