<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>
            
            <div class="form-field__form-field-content">

                <fieldLabelInput v-model="model.field_label" :placeholder="'This text input asks the user to...'" />
               <slot></slot>
               <button
                @click="$emit('addingToSection', sectionRef)"
                id="add-button">{{sectionRef.field_type=='section' ? 'ADD ELEMENT' :  'ADD SECTION'}}</button>
            </div>
        </template>

        <template v-slot:options>

            <div class="parameter-group__option">
                <!-- Ability to change min "select_min" & "select_max" answers -->

                <button class="--option">
                    <!-- Min. Answers : -->
                    <label>Min. Answers :</label>
                    <input type="number" v-model="model.field_min" :min="1" :max="model.field_max" @change="console.log(model)">
                </button>
                <button class="--option">
                    <!-- Max. Answers : -->
                    <label>Max. Answers :</label>
                    <input type="number" v-model="model.field_max" :min="model.field_min">
                </button>
            </div>

            <div class="parameter-group__option">
                    <button class="--option">
                        <label>Field repeat</label>
                        <input type="checkbox" v-model="model.field_repeat">
                    </button>
                </div>

            <div class="parameter-group__option">
                <button class="--option">
                    <label>🔑 Required :</label>
                    <input type="checkbox" v-model="model.field_required">
                </button>
            </div>
        </template>
    </skeletonField>
</template>

<script setup>
import { defineModel, defineProps } from 'vue';
import skeletonField from './skeletonField.vue'

import fieldLabelInput from '@/components/map/topTools/forms/customElements/fieldLabelInput.vue';


const model = defineModel();

defineProps({
    title: String,
    index: Number,
    sectionRef : Object,
    deleteSelf: Function
});

</script>

<style scoped >
.form-field__form-field-content{
    width: 100%;
}

.form-field__form-field-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 89%;
    gap: 0.2vw;

    label {
        overflow: scroll;
        /* break lines */
        white-space: pre-wrap;
        /* text-overflow: ellipsis; */
        /* white-space: nowrap; */
        width: 100%;
    }
}
</style>

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

.form-field__form-field-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 100%;
    gap: 0.5vw;
}

input {
    height: 1.8vw;
    width: 50%;
    font-size: 1.2vw;
    padding: 0.3vw;

    &:focus {
        outline: 0.01vw solid var(--grey-darker);
    }
}

img {
    pointer-events: none;
}

.form-field-content__content-header {
    display: flex;
    align-items: center;

    margin-bottom: 0.2vw;

    input {
        width: 100%;
        background-color: var(--white);
        border: 0.01vw solid var(--grey-darker);
        border-radius: 0 var(--small-radius) var(--small-radius) 0;
        box-shadow: var(--medium-shadow);
    }

    &.--preset {
        display: flex;
        flex-direction: column;

        select {
            width: 100%;
            height: 1.8vw;
            font-size: 1.2vw;
            padding: 0.1vw;

            &:focus {
                outline: 0.01vw solid var(--grey-darker);
            }
        }
    }

    &.--source {
        flex-direction: column;

        select {
            width: 50%;
            height: 1.8vw;
            font-size: 1.2vw;
            padding: 0.1vw;

            &:focus {
                outline: 0.01vw solid var(--grey-darker);
            }
        }
    }
}

.content-header__collapse-button {
    display: inline-flex;
    justify-content: center;
    align-items: center;

    background-color: var(--grey-dark);
    border: 0.01vw solid var(--grey-darker);
    border-radius: var(--small-radius) 0 0 var(--small-radius);

    height: 1.8vw;

    img {
        height: 1.8vw;

        display: inline-flex;
        justify-content: center;
        align-items: center;

        pointer-events: none;

        transition: transform 0.5s ease-in-out;
        transform: rotate(-90deg);
    }

    &.collapsed img {
        transform: rotate(90deg);
    }
}

.content__section-items {
    display: flex;
    flex-direction: column;

    margin-left: 1vw;
    gap: 0.2vw;

    &.collapsed {
        max-height: 0;
        padding: 0;
        margin: 0;
        opacity: 0;
        visibility: hidden;
        pointer-events: none;
    }
}

.section-items__item {
    display: flex;
    align-items: center;

    height: 1.8vw;

    background-color: var(--grey);
    /* border-radius: 4px; */

    img {
        height: 1.8vw;
        width: 1.8vw;
    }

    &.selected {
        background-color: var(--green);
    }
}

/* .form-field-content__content-footer {} */

.content-footer__new-option {
    display: flex;
    align-items: center;

    margin-top: 0.5vw;

    input {
        width: 100%;
        background-color: var(--grey-light);
        border: 0.01vw solid var(--grey-dark);
        border-radius: var(--small-radius) 0 0 var(--small-radius);
    }

    button {
        height: 1.8vw;
        width: 1.8vw;

        border-radius: 0 var(--small-radius) var(--small-radius) 0;
        background-color: var(--grey-dark);
        border: 0.01vw solid var(--grey-darker);
    }
}

.item__drag-handle {
    display: flex;
    justify-content: center;
    align-items: center;

    width: 3vw;

    &.--click {
        /* align to top right */
        margin-left: auto;
        cursor: pointer;
    }
}

.parameter-group__option {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5vw;

    .--option {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--grey-light);
        border-radius: var(--small-radius);
        border: 0.01vw solid var(--grey-darker);

        padding: 0.2vw;
        font-size: 0.9vw;

        label {
            margin-right: 0.2vw;
            /* filter: grayscale(100%); */
        }

        input {
            display: inline-flex;
            flex-direction: row;
            align-items: center;

            width: 3vw;
            height: 1.1vw;
            font-size: 0.9vw;
            padding: 0.2vw;

            &:focus {
                border: none;
                /* outline: 0.01vw solid var(--grey-darker); */
                /* border-radius: var(--small-radius); */
            }
        }

        input[type="checkbox"] {

            width: 1vw;
            height: 1vw;

            border-radius: var(--small-radius);
            border: none;
            outline: none;
        }
    }
}
</style>
