<template>
    <div class="export-additional-content" :class="{ visible: show }">
        <div class="container-scroll">
            <section class="information m">
                <h2>Observations count :</h2>
                <p>{{ $store.state.filteredObservations.length }}</p>
            </section>

            <div class="data">
                <div class="data-observation" v-for="(observation, key) in $store.state.filteredObservations" :key="key">
                    <template v-for="(row, rowKey) in observation" :key="rowKey">
                        <section v-if="row" >
                            <h6>{{ rowKey }}</h6>
                            <p>{{ row }}</p>
                        </section>
                    </template>
                   
                </div>
            </div>
        </div>

    </div>
</template>

<script>
export default {
    data() {
        return {
            show: false
        }
    },
    mounted() {

    },
    methods:
    {
        toggleShow() {
            this.show = !this.show
        }
    }
}
</script>

<style>
/* do not change in export css*/
.export-additional-content {
    height: 1080px;
    overflow: hidden;
    padding: 40px;
    width: 1920px;
}

.export-additional-content .container-scroll{
    overflow: auto;
    width: 100%;
    height: 100%;
}

.export-additional-content .information {
    padding: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: left;
    padding: 10px;
    padding-left: 0;
    gap: 50px;
}

.export-additional-content .information p {
    background-color: rgba(213, 213, 213, 0.233);
    padding: 20px;
    width: 60%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.export-additional-content .information h2 {
    border-bottom: 0.1vw solid rgba(162, 162, 162, 0.455);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    width: 40%;
    text-align: center;
}

.export-additional-content .information.m h2 {
    font-size: 35px;
}

.export-additional-content .information.m p {
    font-size: 30px;
}

.export-additional-content .data {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.export-additional-content .data .data-observation{
    padding: 20px;
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid rgba(0, 0, 0, 0.149);
}

.export-additional-content .data .data-observation section{
    display: flex;
	flex-direction: row;
	flex-wrap: nowrap;
    align-items: center;
	justify-content: flex-start;
	align-content: stretch;
}

.export-additional-content .data .data-observation section h6{
    font-size: 22px;
    font-family: 'Century Gothic';
    width: 15%;
    text-align: left;
}

.export-additional-content .data .data-observation section p{
    font-family: 'Century Gothic';
    font-size:22px;
    width: 85%;
    padding: 20px;
    background-color: rgba(228, 228, 228, 0.37);
    text-align: left;
}

</style>

<style scoped>

.map-export-container .export-additional-content {
    transition: 0.2s ease;
    position: absolute;
    left: 0;
    top: 100%;
    z-index: 10;
    min-height: 1080px;
    width: 100%;
    background-color: white;
}

.map-export-container .export-additional-content.visible {
    top: 0;
}
</style>