<template>
    <genericSelect v-model="model" isRequired :options="$store.state.form.formKeys" optionsKey="code"
        optionsValue="label" :placeholder="placeholder">
        <template #icon>
            <img src="@/assets/image/forms/form-input-key-icon.svg" alt="🔑" />
        </template>
    </genericSelect>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import genericSelect from '@/components/map/topTools/forms/customElements/commonElements/genericSelect.vue'

import { defineModel, defineProps } from 'vue'
const model = defineModel();

defineProps({
    isRequired: {
        type: Boolean,
        default: false,
    },
    options: {
        type: Array,
        default: () => [],
    },
    optionsKey: {
        type: String,
        default: 'code',
    },
    optionsValue: {
        type: String,
        default: 'label',
    },
    placeholder: {
        type: String,
        default: 'Select field key...',
    },
    disabled: {
        type: Boolean,
        default: false,
    },
});
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped></style>
