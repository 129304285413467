<!-- Right panel component of the forms management, this panel is used to manage the forms themselves -->

<template>
    <div v-show="formSelectedItemToEdit === null" class="manage-container">

        <!-- <popupComp @response="handlePopupResponse" ref="popup" /> -->
        <div class="search-bar" :class="{ '--full-width': !userPermissions.includes('CF') }">
            <div class="search-bar-container">
                <img src="@/assets/home/search.png" />
                <input v-model="formUserSearchInput" ref="input" placeholder="Search form by ID" type="text" />
            </div>
            <button v-if="userPermissions.includes('CF')" @click="handleNewFormCreation" id="add">NEW</button>
        </div>


        <div class="row --filter-bar">
            <!-- Button to collapse / un-collapse all forms -->
            <button @click="toggleExpandAll" class="filter-all" :class="{ '--collapsed': isFilterBarCollapseButton }">
                <img src="@/assets/image/forms/form-header-collapse-icon.webp">
            </button>

            <!-- Number of results found -->
            <section class="results-count">
                <label>{{ filteredForms.length }} results found</label>
            </section>

            <div class="--group">
                <!-- Dropdown to select sorting -->
                <select v-model="formSortingModeSelected" id="sort-by">
                    <option v-for="mode in formSortingMode" :key="mode.key" :value="mode.key">
                        {{ mode.title }}
                    </option>
                </select>

                <!-- Refresh button -->
                <button @click="refreshForms" class="refresh">
                    <img src="@/assets/image/forms/manage-forms-refresh-icon.svg" />
                </button>
            </div>
        </div>

        {{ selectedFilter }}

        <!-- Modal that appears when deleting a form -->
        <genericFormModal v-if="showModal" :closeModal="closeModal" @buttonClick="handleFormDeletion"
            modalTitle="Deletion confirmation" modalMessage="Are you sure you want to delete this form?"
            rightButtonText="Delete" :rightButtonStyle="{ backgroundColor: 'var(--red-light)' }" />

        <template v-if="formSelectedItemToEdit === null">
            <div class="content-scroller">
                <div v-for="(form, key) in filteredForms" :key="key" class="row"
                :class="{ expanded: expandedForms[form.form_id] }">

                <!-- See more button -->
                <button id="see-more" @click="toggleExpand(form.form_id)"
                    :class="{ expanded: expandedForms[form.form_id] }">
                    <img src="@/assets/home/search.png" />
                </button>

                <div class="row-content">
                    <template v-for="(formRow) in formItemRows" :key="formRow.key">
                        <div class="row-content-item">
                            <section>
                                <label>{{ formRow.title }}</label>
                                <p>{{
                                    formRow.key === 'form_category'
                                        ? capitalizeFirstLetter(form[formRow.key])
                                        : form[formRow.key] || 'N/A'
                                }}</p>
                            </section>
                        </div>
                    </template>
                </div>

                <div v-if="userPermissions.includes('MF') || userPermissions.includes('DF')" class="row-btns btns">
                    <button v-if="userPermissions.includes('MF')" @click="openDropdown(form.form_id)" class="edit"
                        ref="dropdown">
                        <img src="@/assets/image/forms/form-comp-edit-form.svg" />
                        <div v-if="openedDropdown === form.form_id" class="dropdown"
                            :class="{ '--last-row': isDropdownOpenedInLastRow }">
                            <button @click.stop="handleEdit(form)">Edit</button>
                            <button @click.stop="handleDuplicate(form)">Duplicate</button>
                        </div>
                    </button>
                    <button v-if="userPermissions.includes('DF')" @click="handleDelete(form)" class="delete">
                        <img src="@/assets/image/forms/form-field-trash-bin-icon.svg" />
                    </button>
                </div>
            </div>
            </div>

        </template>
    </div>

    <EditForms v-if="formSelectedItemToEdit !== null" @cancel="handleCancel" @success="handleSuccessfulFormCreation"
        :formData="formSelectedItemToEdit !== 'newForm' ? formSelectedItemToEdit : undefined" />

</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script>
import { onMounted, ref, watch } from 'vue';
import EditForms from './EditForms.vue';
import { useStore } from 'vuex';
import genericFormModal from '@/components/map/topTools/forms/customElements/genericFormModal.vue';

export default {
    // ? - - - Components - - - - -
    components: {
        EditForms,
        genericFormModal
    },

    // ? - - - Emits - - - - - - - -
    emits: ['categories', 'lock-categories'],

    // ? - - - Props - - - - - - - -
    props: {
        userPermissions: Array,
        selectedFilter: null,
    },

    // ? - - - Setup - - - - - - - -
    setup(_, { emit }) {
        const store = useStore();
        const formItemsData = ref([]);
        const formSelectedItemToEdit = ref(null);

        watch(formSelectedItemToEdit, (newValue) => {
            emit('lock-categories', newValue !== null);
        });

        onMounted(async () => {
            await store.dispatch('firstMinimalFormsFetch')
                .then(() => {
                    formItemsData.value = store.state.form.formsMinimal;
                    emit('categories', store.state.form.categories);
                });
        });

        return {
            formItemsData, formSelectedItemToEdit
        };
    },

    // ? - - - Data - - - - - - - -
    data() {
        return {
            showModal: false,
            openedDropdown: null,
            formUserSearchInput: "",
            newForm: false, // TODO: (form) Find better name for this
            // TODO: (form) Duplicate data, move to store?
            formFilters: [
                'Plant life',
                'Wildlife',
                'Insects'
            ],
            formSortingMode: [
                {
                    title: "Sort by ID",
                    key: "form_id"
                }, {
                    title: "Sort by Label",
                    key: "form_label"
                }, {
                    title: "Sort by Inventory Code",
                    key: "form_inventoryCode"
                }, {
                    title: "Sort by Description",
                    key: "form_description"
                }, {
                    title: "Sort by Category",
                    key: "form_category"
                }
            ],
            formSortingModeSelected: "form_id",
            // TODO: (form) Could probably be handled in a more dynamic way
            formItemRows: [
                {
                    title: "Label",
                    key: "form_label",
                    type: "text"
                }, {
                    title: "ID",
                    key: "form_id",
                    type: "text"
                }, {
                    title: "Inventory Code",
                    key: "form_inventoryCode",
                    type: "text"
                }, {
                    title: "Category",
                    key: "form_category",
                    type: "text"
                },
            ],
            expandedForms: {},
            isFilterBarCollapseButton: false,
            formPendingDeletionConfirmation: null,
        }
    },

    // ? - - - Computed - - - - - -
    computed: {
        filteredForms() {
            // Filter forms by user input and selected filter unless empty
            return this.formItemsData.length > 0
                ? this.formItemsData
                    // Replace, null, undefined, and empty strings with "None"
                    .map(e => {
                        for (const key in e) {
                            if (e[key] == null || e[key] === "") {
                                e[key] = "None";
                            }
                        }
                        return e;
                    })
                    .filter(
                        e => (e.form_id || '').toLowerCase().includes(this.formUserSearchInput.toLowerCase())
                            && (this.selectedFilter == null || e.form_category == this.selectedFilter))
                    .sort((a, b) => {
                        const sortKey = this.formSortingModeSelected;
                        if (sortKey === "form_description") {
                            // Treat null/undefined as empty strings for sorting
                            const aValue = a[sortKey] || "";
                            const bValue = b[sortKey] || "";
                            return aValue.localeCompare(bValue);
                        } else if (typeof a[sortKey] === "string" && typeof b[sortKey] === "string") {
                            return a[sortKey].localeCompare(b[sortKey]);
                        }
                        return 0;
                    })
                : [];
        },
        isDropdownOpenedInLastRow() {
            return this.openedDropdown && this.openedDropdown === this.filteredForms[this.filteredForms.length - 1].form_id;
        },
    },

    // ? - - - Methods - - - - - - -
    methods: {
        openModal() {
            this.showModal = true;
        },
        closeModal() {
            this.showModal = false;
        },
        handleFormDeletion(response) {
            if (response === 'right') {
                this.handleConfirmDelete();
            } else {
                this.formPendingDeletionConfirmation = null;
                this.closeModal();
            }
        },
        capitalizeFirstLetter(str) {
            return this.$store.getters.capitalizeFirstLetter(str);
        },
        openDropdown(formId) {
            this.openedDropdown = formId;
        },
        closeDropdown() {
            this.openedDropdown = null;
        },
        toggleExpandAll() {
            if (this.isFilterBarCollapseButton) {
                this.expandedForms = {};
                this.isFilterBarCollapseButton = false;
            } else {
                this.expandedForms = this.formItemsData.reduce((acc, form) => {
                    acc[form.form_id] = true;
                    return acc;
                }, {});
                this.isFilterBarCollapseButton = true;
            }
        },
        handleClickOutsideDropdown(event) {
            const clickedOutside =
                !(this.$refs.dropdown && (Array.isArray(this.$refs.dropdown)
                    ? this.$refs.dropdown.some(dropdownElement => dropdownElement.contains(event.target))
                    : this.$refs.dropdown.contains(event.target)));

            if (clickedOutside && this.openedDropdown !== null) {
                this.closeDropdown();
            }
        },
        async fetchData(url, options) {
            const response = await fetch(url, options);
            const data = await response.json();
            return data;
        },
        handleNewFormCreation() {
            this.formSelectedItemToEdit = 'newForm';
            this.newForm = true;
        },
        async handleSuccessfulFormCreation() {
            // Refetch forms
            const data = await this.fetchData(process.env.VUE_APP_BACKEND_V2_URL + '/getFormsMinimal', { method: 'GET' });
            this.formItemsData = data.filter(e => !e.fakeForm);

            this.formSelectedItemToEdit = null;
        },
        handleCancel() {
            this.newForm = false;
            this.formSelectedItemToEdit = null;
        },
        async handleDuplicate(form) {
            this.formSelectedItemToEdit = await this.fetchData(process.env.VUE_APP_BACKEND_V2_URL + '/getFormFromID/' + form.form_id, { method: 'GET' });
            this.formSelectedItemToEdit.newForm = true;
            this.formSelectedItemToEdit.form_id += " (Copy)"
            this.formSelectedItemToEdit.form_label += " (Copy)";
            this.newForm = true;
            this.closeDropdown();
        },
        async handleEdit(form) {
            this.formSelectedItemToEdit = await this.fetchData(process.env.VUE_APP_BACKEND_V2_URL + '/getFormFromID/' + form.form_id, { method: 'GET' });
            this.newForm = false;
            this.closeDropdown();
        },
        handleDelete(form) {
            // Open confirmation modal
            this.openModal();
            this.formPendingDeletionConfirmation = form;
        },
        async handleConfirmDelete() {
            const url = `${process.env.VUE_APP_BACKEND_V2_URL}/deleteForm/${this.formPendingDeletionConfirmation.form_id}`;
            const options = {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
            };
            const data = await this.fetchData(url, options);
            alert(data.message || data.success || data.error);
            this.formItemsData
                = this.$store.state.form.formsMinimal
                = this.formItemsData.filter(e => e.form_id !== this.formPendingDeletionConfirmation.form_id);

            this.formPendingDeletionConfirmation = null;
            this.closeModal();
        },
        toggleExpand(formId) {
            this.expandedForms[formId] = !this.expandedForms[formId];
        },
        refreshForms() {
            const refreshButton = document.querySelector('.refresh');
            refreshButton.classList.add('refresh--animate');
            setTimeout(() => {
                refreshButton.classList.remove('refresh--animate');
            }, 300);
            this.$store.dispatch('refreshMinimalForms')
                .then(() => {
                    this.formItemsData = this.$store.state.form.formsMinimal;
                });
        },
    },
    mounted() {
        document.addEventListener('click', this.handleClickOutsideDropdown);
    },
    beforeUnmount() {
        document.removeEventListener('click', this.handleClickOutsideDropdown);
    },
};
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped>
.content-scroller {
    padding-left: 0.5vw;
}
</style>

<style scoped lang="scss">
/* Source: https://stackoverflow.com/questions/44793453/how-do-i-add-a-top-and-bottom-shadow-while-scrolling-but-only-when-needed */
.manage-container {
    background:
        /* What this does is cover the shadow when the content is at the top */
        linear-gradient(var(--white) 30%, rgba(255, 255, 255, 0)),
        /* Here is the cover at the bottom */
        linear-gradient(rgba(255, 255, 255, 0), var(--white) 70%) 0 100%,
        /* This is the shadow at the top, so 0% of the farthest-side */
        radial-gradient(farthest-side at 50% 0, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)),
        /* And this is the shadow at the bottom, 100% farthest-side */
        radial-gradient(farthest-side at 50% 100%, rgba(0, 0, 0, .1), rgba(0, 0, 0, 0)) 0 100%;

    background-repeat: no-repeat;
    background-size: 100% 5vw, 100% 5vw, 100% 2vw, 100% 2vw;
    background-attachment: local, local, scroll, scroll;
}


.results-count {
    color: var(--black-light);
    gap: 0.5vw;
}

.search-bar {
    &.--full-width {
        width: 100%;
    }

    .search-bar-container img {
        pointer-events: none;
    }
}

.row {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1vw;
    max-height: 5vw;
    min-height: 5vw;

    .row-content {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    &.expanded {
        background-color: var(--grey-light);

        max-height: 30vw;

        .row-content-item {

            section {
                p {
                    white-space: normal;
                    overflow: visible;
                    text-overflow: unset;
                    word-wrap: break-word;
                }
            }
        }
    }

    .row-content-item {

        section {

            p {
                text-align: center;

                /* Handle overflow */
                width: 8.4vw;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }

    #see-more {
        border: none;
        background-color: transparent;
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        position: relative;

        width: 2vw;
        height: 2vw;
        left: -1.5vw;
        transition: 0.2s ease;

        &:hover {
            rotate: 10deg;
            transition: 0.4s ease;
        }

        &.expanded {
            rotate: 15deg;

            &:hover {
                rotate: 5deg;
            }
        }
    }

    .row-btns button {
        border-radius: var(--medium-radius);
        transition: 0.2s ease;
        font-size: 1vw;
        width: 100%;

        img {
            display: flex;
            align-items: center;
            justify-content: center;

            pointer-events: none;

            width: 1.5vw;
            height: 1.5vw;
        }

        &.delete {
            /* Subtle indicator that this is a delete button */
            border-bottom: 0.2vw solid var(--red-light);

            &:hover {
                border-bottom-color: #de2222;
                transition: 0.2s ease;
            }
        }

        &.edit {
            /* Subtle indicator that this is an edit button */
            border-bottom: 0.2vw solid var(--grey-darker);

            &:hover {
                border-bottom-color: #787878;
                transition: 0.2s ease;
            }
        }
    }

    #see-more img {
        width: 1.5vw;
        height: 1.5vw;

        pointer-events: none;

        background-color: var(--grey-dark);
        border-radius: var(--small-radius);
    }

    &.--filter-bar {
        background-color: var(--grey-light);
        border-radius: var(--large-radius);

        min-height: 1vw;
        padding: 0.4vw;

        .--group {
            display: flex;
            gap: 0.5vw;
        }

        img {
            display: flex;
            align-items: center;
            justify-content: center;

            pointer-events: none;
            width: 1.5vw;
            height: 1.5vw;
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;

            background-color: var(--white);
            border: 0.1vw solid var(--grey-darker);
            border-radius: var(--medium-radius);

            padding: 0.3vw;
            opacity: 0.7;

            cursor: pointer;
        }

        select {
            background-color: var(--white);
            border: 0.1vw solid var(--grey-darker);
            border-radius: var(--medium-radius);

            padding: 0.3vw;
            cursor: pointer;

            opacity: 0.7;
            font-size: 1vw;
        }

        button:hover,
        select:hover {
            background-color: var(--grey-dark);
            border: 0.1vw solid var(--white);
            outline: 0.1vw solid var(--grey-darker);
        }

        .filter-all {
            img {
                transition: 0.3s ease-in-out;
                rotate: -90deg;
            }

            &.--collapsed img {
                rotate: 90deg;
                transition: 0.3s ease-in-out;
            }
        }

        /* Animations & transitions */
        .refresh--animate img {
            transform: rotate(360deg);
            transition: 0.3s ease;
        }





        /* .filter-all { */
        /* transition: 0.2s ease; */
        /*  */
        /* &:hover { */
        /* transition: 0.3s ease; */
        /* transform: rotate(5deg); */
        /*  */
        /* img { */
        /* transform: rotate(-5deg); */
        /* } */
        /* } */
        /* } */
    }
}

.dropdown {
    position: absolute;
    background-color: var(--grey-light);
    box-shadow: var(--medium-shadow);
    border: 0.01vw solid var(--grey);
    z-index: 1;
    border-radius: var(--small-radius);
    display: flex;
    flex-direction: column;
    gap: 0.1vw;
    padding: 0.2vw;

    &.--last-row {
        /* Make dropdown appear above the button */
        margin-top: -8.5vw;
    }
}

.dropdown button {
    color: var(--black-light);
    padding: 0.5vw;
    text-decoration: none;
    display: block;
    border: none;
    background: none;
    width: 100%;
    text-align: left;

    &:hover {
        background-color: var(--grey-dark);
        color: var(--black-light);

        transition: 0.2s ease;
    }
}
</style>