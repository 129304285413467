<!-- Component responsible for handling the creation/edition of the forms -->

<!-- TODO Make the left buttons draggable -->
<!-- TODO Refactor & Reduce duplicate code -->
<!-- TODO Make Right Panel Form Header more compact -->

<!-- FIXME //! Add a getFormsID (only ID) method to avoid fetching all forms (firebase quota) -->
<!-- FIXME //! Add a minimalGetForms (get everything but .form) method to avoid fetching all forms (firebase quota) -->

<!-- FIXME 'user' might not be the best term to use -->

<template>
    <div class="edit-form">

        <!-- ? Left Panel -->
        <div class="edit-form__left-panel" :class="{'adding-to-section' : addingToSection}">
            <!-- * Left Panel Buttons -->
            <div v-for="(category, categoryName) in leftPanelElements" :key="categoryName"
                class="left-panel__menu-element-group">

                <!-- Category Title -->
                <h2>{{ capitalizeFirstLetter(categoryName) }}</h2>

                <!-- Category Elements -->
                <div class="left-panel__menu-element-content">
                    <div v-for="(element, elementId) in category" :key="elementId"
                        class="left-panel__menu-element-element">
                        <button class="left-panel__menu-element-choice" :class="{
                '--disabled': element.status === 'disabled',
                '--in-development': element.status === 'in-development'
            }" @click="element.status !== 'disabled' && addFormField(element.type)">
                            <div class="menu-element-choice__content">
                                <div class="menu-element-choice__icon">
                                    <span>{{ element.icon }}</span>
                                </div>
                                <div>
                                    {{ element.title ? element.title : getLeftPanelButtonText(element) }}
                                </div>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <!-- ? Right Panel -->
        <div class="edit-form__right-panel">

            <div v-if="addingToSection"
                style="flex-direction:column;display:flex;align-items:center;justify-content:center;width: 100%;height: 100%; position:absolute;background-color:#0000008b; z-index:99;top:0; left:0;">
                <p style="border-radius:0.2vw;background-color:black; color: white; font-size:1vw; padding: 2vw;    font-family: 'Century Gothic';">Select an elemet on the left pan</p>
                <button style="width: 60%;" @click="addingToSection = false" id="add-button">
                    Cancel
                </button>
            </div>
            <div style="overflow: auto; height:100%">
                <!-- * Collapse Form Header Button -->
                <button @click="localFormOptions.isFormHeaderCollapsed = !localFormOptions.isFormHeaderCollapsed"
                    class="right-panel__collapse-button"
                    :class="localFormOptions.isFormHeaderCollapsed ? 'collapsed' : ''">
                    <img src="@/assets/image/forms/form-header-collapse-icon.webp" />
                    <h4>{{ localFormOptions.isFormHeaderCollapsed ? "Collapse" : "Expand" }} Form Header</h4>
                </button>

                <!-- * Right Panel Form Header -->
                <div class="right-panel__form-header"
                    :class="localFormOptions.isFormHeaderCollapsed ? 'collapsed' : ''">
                    <div class="--id">
                        <h4>
                            <basicTextInputWithImage v-model="localFormData.header.form_id.value"
                                :placeholder="localFormOptions.IDPlaceholder" isRequired attachCustomButton>
                                <template #icon>
                                    <img src="@/assets/image/forms/form-edit-header-id.svg" alt="ID" />
                                </template>
                                <template #customButtonIcon>
                                    <button type="button" @click="generateNonExistingID">
                                        <img src="@/assets/image/forms/form-edit-header-random.svg" alt="🔀" />
                                    </button>
                                </template>
                            </basicTextInputWithImage>
                        </h4>
                    </div>
                    <div>
                        <h4>
                            <genericSelect v-model="localFormData.header.form_category.value"
                                :options="localFormOptions.categories" optionsKey="id" optionsValue="name"
                                :placeholder="'Select a category'" isRequired>
                                <template #icon>
                                    <img src="@/assets/image/forms/form-edit-header-category.svg" alt="C" />
                                </template>
                            </genericSelect>
                        </h4>
                    </div>
                    <div>
                        <h4>
                            <genericSelect v-model="localFormData.header.form_inventoryCode.value"
                                :options="inventoriesCodes" :placeholder="localFormOptions.inventoryCodePlaceholder"
                                isRequired>
                                <template #icon>
                                    <img src="@/assets/image/forms/form-edit-header-inventory.svg" alt="I" />
                                </template>
                            </genericSelect>
                        </h4>
                    </div>
                    <div>
                        <h4>
                            <basicTextInputWithImage v-model="localFormData.header.form_label.value"
                                :placeholder="localFormOptions.labelPlaceholder" isRequired>
                                <template #icon>
                                    <img src="@/assets/image/forms/form-edit-header-label.svg" alt="L" />
                                </template>
                            </basicTextInputWithImage>
                        </h4>
                    </div>
                    <div>
                        <h4>
                            <basicTextInputWithImage v-model="localFormData.header.form_description.value"
                                :placeholder="localFormOptions.formDescriptionPlaceholder" isTextArea>
                                <template #icon>
                                    <img src="@/assets/image/forms/form-edit-header-desc.svg" alt="D" />
                                </template>
                            </basicTextInputWithImage>
                        </h4>
                    </div>
                    <div>
                        <h4>
                            <basicTextInputWithImage v-model="localFormData.header.form_version.value" disabled>
                                <template #icon>
                                    <img src="@/assets/image/forms/form-edit-header-version.svg" alt="V" />
                                </template>
                            </basicTextInputWithImage>
                        </h4>
                    </div>
                </div>

                <!-- * Form Zone "Button" -->
                <button class="right-panel__form-zone-fake-button">
                    <h4>Form</h4>
                </button>

                <!-- * Right Panel Form Zone -->
                <div v-if="localFormDataLength > 0" class="right-panel__form-zone">
                    <!-- Form Elements -->

                    <SlickList axis="y" v-model:list="localFormData.fields.value" useDragHandle appendTo=".top-tools"
                        class="form_zone__form-zone-wrapper">
                        <template v-for="(element, index) in localFormData.fields.value" :key="element">
                            <SlickItem :index="index" v-if="element.field_type == 'section' || element.field_type=='sections'">
                                <sectionField :sectionRef="localFormData.fields.value[index]" @addingToSection="handleAddingToSection" v-model="localFormData.fields.value[index]" :index="index"
                                    :deleteSelf="deleteFormField" :title="getFieldTitle(element)">
                                    <SlickList v-model:list="localFormData.fields.value[index].field_children" axis="y"
                                        useDragHandle appendTo=".top-tools">

                                        <SlickItem v-for="(child, index2) in element.field_children" :key="index2"
                                            :index="index2">
                                                <sectionField  v-if="child.field_type == 'section'" :sectionRef="localFormData.fields.value[index].field_children[index2]" @addingToSection="handleAddingToSection" v-model="localFormData.fields.value[index].field_children[index2]" :index="index"
                                                    :deleteSelf="()=>this.localFormData.fields.value[index].field_children.splice(index2, 1)" :title="getFieldTitle(element)">
                                                    <SlickList v-model:list="localFormData.fields.value[index].field_children[index2].field_children" axis="y"
                                                        useDragHandle appendTo=".top-tools">
                                                        <SlickItem v-for="(child2, index3) in element.field_children[index2].field_children" :key="index3"
                                                            :index="index3">
                                                            <component  :is="getComponentName(child2.field_type, child2.input_type)"
                                                                v-model="localFormData.fields.value[index].field_children[index2].field_children[index3]"
                                                                :index="index3" :deleteSelf="()=>this.localFormData.fields.value[index].field_children[index2].field_children.splice(index3, 1)"
                                                                :title="getFieldTitle(child2)" />
                                                        </SlickItem>
                                                    </SlickList>
                                                </sectionField>
                                            
                                            <component v-else  :is="getComponentName(child.field_type, child.input_type)"
                                                v-model="localFormData.fields.value[index].field_children[index2]"
                                                :index="index2" :deleteSelf="()=>this.localFormData.fields.value[index].field_children.splice(index3, 1)"
                                                :title="getFieldTitle(child)" />
                                        </SlickItem>
                                    </SlickList>
                                </sectionField>
                            </SlickItem>

                            <SlickItem :index="index" v-else>
                                <component :is="getComponentName(element.field_type, element.input_type)"
                                    v-model="localFormData.fields.value[index]" :index="index"
                                    :deleteSelf="deleteFormField" :title="getFieldTitle(element)" />
                            </SlickItem>
                        </template>

                    </SlickList>
                </div>
                <div v-else class="right-panel__form-zone-fake-button --no-form">
                    <h4>⮞ The form is empty. Add elements to it. ⮜</h4>
                </div>

                <div class="right-panel__export-group">

                    <button v-if="debugEnabled" @click="openJsonInAnotherTab()" class="export-button">
                        DEV
                    </button>
                    <button @click="localFormOptions.showModal = true" class="export-button --info"
                        :class="{ '--valid': isFormValid }">
                        <img src="@/assets/home/search.png" alt="(?)" />
                        {{ isFormValid
                ? "Form is valid. (More details)"
                : "Form is invalid. (More details)"
                        }}
                    </button>
                    <button class="export-button --cancel" @click="$emit('cancel')" id="cancel">Cancel</button>
                    <button class="export-button --save" :disabled="!isFormValid || submitIsPending"
                        @click="handleSubmit" type="submit">
                        {{ formData.newForm ? "Create" : "Save" }}
                    </button>
                </div>
            </div>

        </div>
    </div>
    <div v-if="localFormOptions.showModal" class="modal">
        <div class="modal__modal-wrapper">
            <div class="modal__modal-wrapper --header">
                <h2>Form Validity</h2>
                <div id="closeModal">
                    <button @click="localFormOptions.showModal = false">&times;</button>
                </div>
            </div>
            <div class="modal-wrapper__modal-content">
                <template v-for="condition in formValidityMap" :key="condition.title">
                    <div v-if="condition.conditionToDisplay" class="modal-content__condition">
                        <input type="checkbox" :checked="condition.condition" disabled />
                        <h3>
                            {{ condition.title }}
                        </h3>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script>
// Vue imports
import { computed, ref } from "vue";
import { SlickList, SlickItem } from "vue-slicksort";
import { useStore } from 'vuex';

// Custom components
import basicTextInputWithImage from "./customElements/basicTextInputWithImage.vue";
import genericSelect from "./customElements/commonElements/genericSelect.vue";

import notYetImplementedField from "./formFields/notYetImplementedField.vue";
import noticeField from "./formFields/noticeField.vue";
import selectField from "./formFields/selectField.vue";
import checkboxField from "./formFields/checkboxField.vue";
import inputTextField from "./formFields/inputTextField.vue";
import inputNotesField from "./formFields/inputNotesField.vue";
import inputIntegerField from "./formFields/inputIntegerField.vue";
import inputDecimalField from "./formFields/inputDecimalField.vue";
import datetimepickerField from "./formFields/datetimepickerField.vue";
import pictureField from "./formFields/pictureField.vue";
import audioField from "./formFields/audioField.vue";
import videoField from "./formFields/videoField.vue";
import recognitionField from "./formFields/recognitionField.vue";
import saveLocationOption from "./formFields/saveLocationOption.vue";
import polygonField from "./formFields/polygonField.vue";
import sectionField from "./formFields/sectionField.vue";
// ... More components can be added


export default {

    // ? - - - Components - - -
    components: {
        basicTextInputWithImage, genericSelect,
        sectionField,
        SlickList, SlickItem,
        notYetImplementedField,
        noticeField,
        selectField, checkboxField,
        inputTextField, inputNotesField,
        inputIntegerField, inputDecimalField,
        datetimepickerField,
        pictureField, recognitionField, saveLocationOption, polygonField,
        audioField, videoField,
    },

    // ? - - - Props - - - - - -
    props: {
        /**
         * Form data that will be exported to JSON
         *
         * @type {Object}
         * @property {Object} header - Form header information
         * @property {Object[]} fields - Form fields
         *
         * @version 1.0.1
         */
        formData: {
            type: Object,
            required: true,
            default: () => ({
                newForm: true,
                form_id: "",
                form_category: "",
                form_inventoryCode: "",
                form_label: "",
                form_description: "",
                form_version: "1.0.2",

                form: ref([]),
            }),
        },
    },

    // ? - - - Emits - - - - - -
    emits: ['cancel', 'success'],

    // ? - - - Setup - - - - - -
    setup(props) {

        const store = useStore();

        // DEBUG ---------------------
        store.dispatch('debugLog', {
            componentName: 'EditForms',
            message: '! Starting EditForms setup()',
            level: 'debug'
        });
        store.dispatch('debugLog', {
            componentName: 'EditForms',
            message: 'EditForms -> props.formData:',
            attachment: props.formData ? props.formData : 'No form data',
            level: 'debug'
        });
        // ---------------------------

        /** Local reference to the form data */
        const localFormData =
        {
            header: {
                form_id: ref(props.formData.form_id),
                form_category: ref(props.formData.form_category),
                form_inventoryCode: ref(props.formData.form_inventoryCode),
                form_label: ref(props.formData.form_label),
                form_description: ref(props.formData.form_description),
                form_version: ref(props.formData.form_version),
            },
            fields: ref(props.formData.form),
        }

        // DEBUG ---------------------
        store.dispatch('debugLog', {
            componentName: 'EditForms',
            message: 'localFormData:',
            attachment: localFormData ? localFormData : 'No form data',
            level: 'debug'
        });
        store.dispatch('debugLog', {
            componentName: 'EditForms',
            message: 'localFormData.fields.value:',
            attachment: localFormData.fields.value ? localFormData.fields.value : 'No form data',
            level: 'debug'
        });
        // ---------------------------

        /** Local reference to the form data length */
        const localFormDataLength = computed(() => localFormData.fields.value.length);

        // DEBUG ---------------------
        store.dispatch('debugLog', {
            componentName: 'EditForms',
            message: '! Successful EditForms setup()',
            level: 'debug'
        });
        // ---------------------------
        return { localFormData, localFormDataLength };
    },

    // ? - - - Data - - - - - - -
    data() {
        // DEBUG ---------------------
        this.$store.dispatch('debugLog', {
            componentName: 'EditForms',
            message: '! Starting EditForms data()',
            level: 'debug'
        });
        // ---------------------------

        return {
            /**
             * Elements that can be added to the form
             *
             * @type {Object{type: string, title?:string, icon: string, status?: disabled || in-development}[]}
             * @property {Object[]} display     - Elements that display information
             * @property {Object[]} selection   - Elements that allow the user to select options
             * @property {Object[]} input       - Elements that allow the user to input information
             * @property {Object[]} ...         - More categories can be added
             *
             *
             * @version 1.0.2
             */
            addingToSection: false,
            addingToSectionReference: null,
            leftPanelElements: {
                display: [
                    { type: "notice", icon: "𝐭", componentName: 'noticeField' },
                    { type: "colors", icon: "🎨", status: "disabled" },
                ],
                container:
                    [
                        {
                            type: "section", componentName: "sectionField", icon: "s",
                        },
                        {title: "Sections group", type: "sections", componentName: "sectionField", icon: "[]"}
                    ],
                selection: [
                    { type: "select", icon: "▾", componentName: 'selectField' },
                    { type: "checkbox", icon: "✓", status: "in-development", componentName: 'checkboxField' },
                ],
                input: [
                    { type: "input-text", icon: "𝐓", componentName: 'inputTextField', status: "in-development" },
                    { type: "input-notes", icon: "✎", componentName: 'inputNotesField', status: "in-development" },
                    { type: "input-integer", icon: "1", componentName: 'inputIntegerField', status: "in-development" },
                    { type: "input-decimal", icon: ".1", componentName: 'inputDecimalField', status: "in-development" },
                    { type: "input-email", icon: "@", status: "disabled" },
                    { type: "datetimepicker", title: "Date & Time", icon: "📆", componentName: 'datetimepickerField', status: "in-development" },
                ],
                button: [
                    { type: "picturepicker", componentName: 'pictureField', title: "Picture", icon: "📷", },
                    { type: "recognition", componentName: 'recognitionField', title: "AI Recognition", icon: "🔍" },
                    { type: "polygonpicker", componentName: 'polygonField', title: "Polygon Picker", icon: "📍" },
                    { type: "audiopicker", title: "Audio", icon: "🔊", componentName: 'audioField', status: "in-development" },
                    { type: "videopicker", title: "Video", icon: "🎥", componentName: 'videoField', status: "in-development" },
                ],
                options: [
                    { type: "location", componentName: 'saveLocationOption', title: "Save Location", icon: "📍" },
                ],
            },
            /**
             * Local form parameters
             * 
             * @type {Object}
             * @version 1.0.0
             */
            localFormOptions: {
                IDPlaceholder: "BkYHky0CaVpAC1S0r...",
                inventoryCodePlaceholder: "Select an inventory code",
                labelPlaceholder: "Enter a label that summarize the form",
                formDescriptionPlaceholder: "This form is meant to...",
                // TODO: Handle categories in a dynamic way
                categories: [
                    {
                        id: "faune",
                        name: "Wildlife",
                    },
                    {
                        id: "flore",
                        name: "Plant life",
                    },
                    {
                        id: "insectes",
                        name: "Insects",
                    },
                    {
                        id: "protocol",
                        name: "Protocol",
                    },
                    {
                        id: "none",
                        name: "None",
                    }
                ],
                selectedCategory: null,
                isFormHeaderCollapsed: false,
                showModal: false,
            },
            formCopy: this.generateJSON(this.localFormData),
            debugEnabled: process.env.NODE_ENV === 'development',
            submitIsPending: false,
        };
    },

    // ? - - - Computed - - - - -
    computed: {
        inventoriesCodes() {
            let codes = this.$store.state.inventories.map(inventory=> inventory.code);
            // Add "none" at the end if not already present
            if (!codes.includes("None")) codes.push("None");
            return codes;
        },
        /**
         * Object that contains every condition for the form to be valid
         *
         * @type {Object}
         * @version 1.0.2
         */
        formValidityMap() {
            // DEBUG ---------------------
            this.$store.dispatch('debugLog', {
                componentName: 'EditForms',
                message: 'formValidityMap() activated',
                level: 'debug'
            });
            // ---------------------------
            return [
                {
                    title: 'The form must have at least one modification',
                    condition: !this.formData.newForm && this.formCopy !== this.generateJSON(this.formData),
                    conditionToDisplay: !this.formData.newForm,
                },
                {
                    title: 'A Form ID needs to be set',
                    condition: !this.isEmptyOrWhitespace(this.localFormData.header.form_id.value),
                    conditionToDisplay: true,
                },
                {
                    title: 'A category must be selected',
                    condition: !this.isEmptyOrWhitespace(this.localFormData.header.form_category.value),
                    conditionToDisplay: true,
                },
                {
                    title: 'An inventory code must be selected',
                    condition: !this.isEmptyOrWhitespace(this.localFormData.header.form_inventoryCode.value),
                    conditionToDisplay: true,
                },
                {
                    title: 'Form Label must not be empty',
                    condition: !this.isEmptyOrWhitespace(this.localFormData.header.form_label.value),
                    conditionToDisplay: true,
                },
                {
                    title: 'There must be at least one field in the form',
                    condition: this.localFormData.fields.value.length > 0,
                    conditionToDisplay: true,
                },
                {
                    title: 'All fields must have a label (except for recognition & location fields)',
                    condition: this.localFormData.fields.value.every((field) => {
                        if ([
                            "recognition",
                            "location",
                            "polygonpicker"
                        ].includes(field.field_type)) {
                            return true;
                        }
                        return !this.isEmptyOrWhitespace(field.field_label);
                    }),
                    conditionToDisplay: this.localFormData.fields.value.length > 0,
                },
                {
                    title: 'All fields must have a key (except for notice, section, picturepicker, audiopicker, videopicker & recognition fields)',
                    condition: this.localFormData.fields.value.every((field) => {
                        if ([
                            "notice",
                            "section",
                            "sections",
                            "picturepicker",
                            "recognition",
                            "audiopicker",
                            "videopicker",
                            "polygonpicker"
                        ]
                            .includes(field.field_type)) {
                            return true;
                        }
                        return !this.isEmptyOrWhitespace(field.field_key);
                    }),
                    conditionToDisplay: this.localFormData.fields.value.length > 0,
                },
                {
                    title: 'All keys are unique (no duplicates allowed)',
                    condition: this.localFormData.fields.value.length ===
                        // IMPORTANT: Some elements such as AI Recognition or PicturePicker don't have a key 
                        // this is why i'm doing: number of undefined/null + Set.size without the undefined

                        this.localFormData.fields.value.map((field) => field.field_key)
                            .filter(key => key == null | undefined).length
                        +
                        new Set(this.localFormData.fields.value
                            .map((field) => field.field_key)
                            .filter(key => key !== undefined)).size,
                    conditionToDisplay: this.localFormData.fields.value.length > 0,
                },
                {
                    title: 'Only one Picturepicker field is allowed per form',
                    condition: this.localFormData.fields.value.filter((field) => field.field_type === 'picturepicker').length <= 1,
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'picturepicker'),
                },
                {
                    title: 'Only one Recognition field is allowed per form',
                    condition: this.localFormData.fields.value.filter((field) => field.field_type === 'recognition').length <= 1,
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'recognition'),
                },
                {
                    title: 'Only one Polygon Picker field is allowed per form',
                    condition: this.localFormData.fields.value.filter((field) => field.field_type === 'polygonpicker').length <= 1,
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'polygonpicker'),
                },
                {
                    title: 'If an AI Recognition field is present, a Picturepicker field must also be present',
                    condition: this.localFormData.fields.value.some((field) => field.field_type === 'picturepicker'),
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'recognition'),
                },
                {
                    title: 'If a Location field is present, a Picturepicker field must also be present',
                    condition: this.localFormData.fields.value.some((field) => field.field_type === 'picturepicker'),
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'location'),
                },
                {
                    title: 'Only one Location field is allowed per form',
                    condition: this.localFormData.fields.value.filter((field) => field.field_type === 'location').length <= 1,
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'location'),
                },
                {
                    title: 'All checkbox fields must have at least one option',
                    condition: this.localFormData.fields.value.every((field) => {
                        if (field.field_type === 'checkbox') {
                            return field.checkbox_options.length > 0;
                        }
                        return true;
                    }),
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'checkbox'),
                },
                {
                    title: 'All select fields must have at least two options or a source',
                    condition: this.localFormData.fields.value.every((field) => {
                        if (field.field_type === 'select') {
                            if (!field.select_source && field.select_options.length > 1) {
                                return field.select_options.every((option) => !this.isEmptyOrWhitespace(option.label));
                            } else if (field.select_source && field.select_source !== "" && (!field.select_options || field.select_options.length === 0)) {
                                return true;
                            } else {
                                return false
                            }
                        }
                        return true;
                    }),
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'select' && !field.select_source),
                },
                {
                    title: 'The minimum required answers for a select field should be less than the number of options available',
                    condition: this.localFormData.fields.value.every((field) => {
                        if (field.field_type === 'select' && !field.select_source) {
                            return field.select_min < field.select_options.length;
                        }
                        return true;
                    }),
                    conditionToDisplay: this.localFormData.fields.value.some((field) => field.field_type === 'select' && field.select_min && field.select_min > 1),
                }
            ]
        },
        isFormValid() {
            return this.formValidityMap.every((condition) => !condition.conditionToDisplay || condition.condition);
        },
    },

    // ? - - - Methods - - - - - -
    methods: {
        capitalizeFirstLetter(str) {
            return this.$store.getters.capitalizeFirstLetter(str);
        },

        handleAddingToSection(AddingToSectionRef)
        {
            this.addingToSection = true
            this.addingToSectionReference = AddingToSectionRef
            if(AddingToSectionRef.field_type =='sections' ) this.addFormField('section')
            


        },

        /**
         * Method responsible for checking if a string is empty or only contains whitespace
         *
         * @param {string} str - string to check
         * @returns {boolean} - true if the string is empty or only contains whitespace, false otherwise
         * @version 1.0.1
         */
        isEmptyOrWhitespace(str) {
            return str === '' ? true : str.trim() === '';
        },

        /**
         * Method responsible for choosing the correct text for the left panel buttons.
         * If the element type contains a hyphen, only the second part of the string is
         * shown capitalized
         *
         * Ex: "checkbox" -> "Checkbox"; "input-text" -> "Text"
         *
         * @param element
         * @returns {string} - text for the left panel button
         * @version 1.0.1
         */
        getLeftPanelButtonText(element) {
            return this.capitalizeFirstLetter(
                Array.from(element.type).includes("-")
                    ? element.type.split("-").slice(1).join(" ")
                    : element.type
            );
        },

        /**
         * Method responsible for adding a form field to the form data
         *
         * @param {string} type - type of form field to add
         * @version 1.0.0
         */
        handleClick(element) {
            if (element.status !== "disabled") {
                this.addFormField(
                    element.type +
                    (element.type === "input" ? "-" + element.input_type : "")
                );
            }
        },

        /**
         * Method responsible for choosing the correct component name based on the field type
         *
         * @param {string} field_type - type of form field
         * @param {string} input_type - type of input field
         * @returns {string} - component name
         * @version 1.0.1
         */
        getComponentName(field_type, input_type = null) {
            if (input_type) field_type += "-" + input_type;

            const element = Object.values(this.leftPanelElements).flatMap(category => category).find(element => element.type === field_type);
            return element
                ? element.componentName
                    ? element.componentName
                    : "notYetImplementedField"
                : "notYetImplementedField";
        },

        /**
        * Method responsible for getting the title of a field
        * 
        * @param {Object} element - form field element
        * @returns {string} - field title
        * @version 1.0.0
        */
        getFieldTitle(element) {
            return element.field_type === "input"
                ? `${this.capitalizeFirstLetter(element.input_type)} ${this.capitalizeFirstLetter(element.field_type)}`
                : this.capitalizeFirstLetter(element.field_type);
        },

        /**F
         * Method responsible for adding a form field to the form data
         * 
         * @param {string} type - type of form field to add
         * @version 1.0.0 
         */
        addFormField(type) {
            const OneElementMaxList = [
                "picturepicker",
                "audiopicker",
                "videopicker",
                "polygonpicker",
            ];

            if (OneElementMaxList.includes(type) && this.localFormData.fields.value.some((field) => field.field_type === type)) {
                alert(`Only one ${type} field is allowed per form.`);
                return;
            }

            // Can't add recognition field if there is already one or if no picturepicker field is present
            if (type === "recognition") {
                if (this.localFormData.fields.value.some((field) => field.field_type === "recognition")) {
                    alert("Only one recognition field is allowed per form.");
                    return;
                }
            }

            // Can't add more than one Location field
            if (type === "location") {
                if (this.localFormData.fields.value.some((field) => field.field_type === "location")) {
                    alert("Only one location field is allowed per form.");
                    return;
                }
            }

            // Can't add more than one Polygon Picker field
            if (type === "polygonpicker") {
                if (this.localFormData.fields.value.some((field) => field.field_type === "polygonpicker")) {
                    alert("Only one polygon picker field is allowed per form.");
                    return;
                }
            }

            let field_to_add;

            switch (type) {
                default:
                    break;

                case "notice":
                    field_to_add =
                    {
                        field_type: "notice",
                        field_label: "",
                    };
                    break;

                case "section":
                    field_to_add = {
                        field_type: "section",
                        field_label: "",
                        
                        field_min: 1,
                        field_max: 1,
                        field_repeat : true,
                        field_required : false,
                        field_children: []
                    };
                    break;
                case "sections" :
                    field_to_add = {
                            field_type: "sections",
                            field_label: "",
                          
                            field_min: 1,
                            field_max: 1,
                            field_repeat : true,
                            field_required : false,
                            field_children: []
                        };
                        break;
                case "select":
                    field_to_add = {
                        field_type: "select",
                        field_label: "",
                        field_key: "",
                        field_required: true,
                        field_min: 1,
                        field_max: 1,
                        select_options: [],
                        select_source: null,

                        hidden_newOption: "",
                    };
                    break;

                case "checkbox":
                    field_to_add = {
                        field_type: "checkbox",
                        field_label: "",
                        field_key: "",
                        checkbox_default: "false",
                        field_required: true,
                        checkbox_options: [],
                    };
                    break;

                case "input-text":
                    field_to_add = {
                        field_type: "input",
                        input_type: "text",
                        field_key: "",
                        field_label: "",
                        field_required: true,
                    };
                    break;

                case "input-notes":
                    field_to_add = {
                        field_type: "input",
                        input_type: "notes",
                        field_key: "",
                        field_label: "",
                        field_required: true,
                    };
                    break;

                case "input-integer":
                    field_to_add = {
                        field_type: "input",
                        input_type: "integer",
                        field_key: "",
                        input_min: null,
                        input_max: null,
                        field_label: "",
                        field_required: true,
                    };
                    break;

                case "input-decimal":
                    field_to_add = {
                        field_type: "input",
                        input_type: "decimal",
                        field_label: "",
                        field_key: "",
                        field_required: true,
                    };
                    break;

                case "picturepicker":
                    field_to_add = {
                        field_type: "picturepicker",
                        field_label: "",
                        field_required: true,
                    };
                    break;

                case "audiopicker":
                    field_to_add = {
                        field_type: "audiopicker",
                        field_label: "",
                        field_required: true,
                    };
                    break;

                case "videopicker":
                    field_to_add = {
                        field_type: "videopicker",
                        field_label: "",
                        field_required: true,
                    };
                    break;

                case "recognition":
                    field_to_add = {
                        field_type: "recognition",
                    };
                    break;

                case "location":
                    field_to_add = {
                        field_type: "location",
                        field_label: "Location",
                        field_key: "geoPoint",
                    };
                    break;

                case "polygonpicker":
                    field_to_add = {
                        field_type: "polygonpicker",
                        field_label: "Polygon",
                        field_key: "polygonCoordinates",
                    };
                    break;

                case "datetimepicker":
                    field_to_add = {
                        field_type: "datetimepicker",
                        field_label: "",
                        field_key: "",
                        field_required: true,
                    };
                    break
            }



            if (this.addingToSection) {
                if(field_to_add.field_type=='sections' || (this.addingToSectionReference.field_type == 'section' && field_to_add.field_type == 'section'))
                {
                    alert("you can't do that")
                    return
                }

                this.addingToSectionReference.field_children.push(field_to_add);
                this.addingToSection = false
            } else {
                this.localFormData.fields.value.push(field_to_add)

            }

            // ? Key shared by all form elements
            this.localFormData.fields.value[this.localFormData.fields.value.length - 1].hidden_showOption = false;
        },

        /**
        * Method responsible for deleting a form field from the form data
        *
        * @param {number} index - index of the form field to delete
        * @version 1.0.0
        */
        deleteFormField(index) {
            this.localFormData.fields.value.splice(index, 1);
        },

        // FIXME //! getForms is overkill, should be replaced by a method that only returns the form IDs 
        // //! (Impossible for now because it's the only method the backend provides)
        async fetchAllForms() {
            const response = await fetch(process.env.VUE_APP_BACKEND_V2_URL + '/getFormsIdOnly', {
                method: 'GET'
            });
            const data = await response.json();
            return data.filter(e => !e.fakeForm);
        },

        async generateID() {
            return Array
                .from(crypto.getRandomValues(new Uint8Array(20)))
                .map((byte) => 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
                    .charAt(byte % 62))
                .join('');
        },

        // FIXME: //! generateNonExistingID() can be spammed (-> firebase quota?)
        async generateNonExistingID() {
            let attempts = 0;
            console.log("generateNonExistingID() -> attempts:", attempts);
            if (attempts < 10) {
                const potentialID = await this.generateID();

                // DEBUG ---------------------
                this.$store.dispatch('debugLog', {
                    componentName: 'EditForms',
                    message: 'generateNonExistingID() -> potentialID:',
                    attachment: potentialID,
                    level: 'debug'
                });
                // ---------------------------

                if (this.isIdUnique(potentialID)) {
                    this.localFormData.header.form_id.value = potentialID;
                    return;
                } else {
                    await this.generateNonExistingID(attempts + 1);
                }
            } else {
                alert("Could not generate a unique ID after 10 attempts. Please try again.");
            }
        },

        async isIdUnique(id = this.localFormData.header.form_id.value) {
            const forms = await this.fetchAllForms();
            return !forms.some(form => form.form_id === id);
        },

        async handleSubmit() {
            if (this.isFormValid) {

                // ? ID must be unique if new form
                if (this.formData.newForm && !await this.isIdUnique()) {
                    alert("A form with this ID already exists. Please choose another ID.");
                    return;
                }
                this.submitIsPending = true;
                this.exportFormToJson();
            } else {
                alert("The form is not valid. Please check the 'Form Validity' section for more details.");
            }
        },

        returnToManageForms() {
            this.$emit('success');
        },

        generateJSON() {
            const data = {
                form_id: this.localFormData.header.form_id.value,
                form_category: this.localFormData.header.form_category.value === "none" ? "" : this.localFormData.header.form_category.value,
                // form_inventoryCode: this.localFormData.header.form_inventoryCode.value,
                form_inventoryCode: this.localFormData.header.form_inventoryCode.value === "None" ? "" : this.localFormData.header.form_inventoryCode.value,
                form_label: this.localFormData.header.form_label.value,
                form_description: this.localFormData.header.form_description.value,
                form_version: this.localFormData.header.form_version.value,
                form: this.localFormData.fields.value,
            };

            // TODO: Might be a good idea to implement a NULL checker instead of checking manually

            return JSON.stringify(
                data,
                (key, value) => {
                    if (
                        key.startsWith("hidden") ||
                        (key === "form_description" && value === "") ||
                        (key === "form_category" && value === null) ||
                        // (key === "field_required" && value === true) || // weird
                        (key === "checkbox_default" && value === "false") ||
                        (key === "select_multi" && value === false) ||
                        (key === "select_min" && value === 1 || key === "select_max" && value === 1) ||
                        (key === "input_min" && value === null || key === "input_max" && value === null) ||
                        ((key === " " && value === null) || (key === "select_options" && value.length === 0)) ||
                        (key === "select_search" && value === false)
                    ) {
                        return undefined;
                    }
                    return value;
                },
                2
            );
        },

        /**
         * Method that handles opening the JSON in another tab for debugging
         * 
         * @version 1
         */
        openJsonInAnotherTab() {
            const json = this.generateJSON();
            const blob = new Blob([json], { type: "application/json" });
            const url = URL.createObjectURL(blob);
            window.open(url, "ExportWindow");
        },

        /**
        * Method that handles export of the form data to JSON
        * 
        * @version 0.1.0
        */
        exportFormToJson() {
            const json = this.generateJSON();

            // -> Open the JSON in another page
            // const blob = new Blob([json], { type: "application/json" });
            // const url = URL.createObjectURL(blob);
            // window.open(url, "ExportWindow");

            // FIXME : Separate the creation & update of forms into two different methods
            // FIXME : And then reduce code duplication
            try {
                if (this.formData.newForm) {
                    fetch(process.env.VUE_APP_BACKEND_V2_URL + '/newForm', {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: json,
                    })
                        .then(response => response.json())
                        .then(data => {
                            if (data.error) { alert(data.error) } else {
                                alert("Form created successfully");
                                this.returnToManageForms();
                            }
                        })
                } else {
                    fetch(process.env.VUE_APP_BACKEND_V2_URL + '/updateForm/' + this.formData.form_id, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: json,
                    })
                        .then(
                            response => response.json()
                        ).then(
                            data => {
                                if (data.error) { alert(data.error) } else {
                                    alert("Form updated successfully");
                                    this.returnToManageForms();
                                }
                            }
                        )
                }
            } catch (error) {
                alert("An error occurred while exporting the form. Please try again.");
                this.submitIsPending = false;
            }
        },
    },
}
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

/* General */
.edit-form {
    display: flex;
}

.edit-form {
    width: 100%;
    height: 100%;
    gap: 1.5vw;
}

/* left & Right Panel */
.edit-form__left-panel,
.edit-form__right-panel {
    padding: 2vw;
    padding-top: 1vw;
    position: relative;
    overflow: auto;
    background-color: var(--grey-light);
    border: 0.01vw solid var(--grey);
    border-radius: var(--large-radius);

    &::-webkit-scrollbar {
        width: 0.3vw;
    }

    &::-webkit-scrollbar-track,
    &::-webkit-scrollbar-thumb {
        border-radius: 0 var(--medium-radius) var(--medium-radius) 0;
    }
}

/* Left Panel Only */

.edit-form__left-panel.adding-to-section{
    scale: 1.1;
    outline: 0.2vw solid black;
}
.edit-form__left-panel {
    width: 30%;
    background-color: var(--grey-light);

    h2 {
        font-size: 1.4vw;
        color: var(--black-light);
        margin-bottom: 0.5vw;
        padding: 0.1vw;
        border-bottom: 0.1vw solid var(--grey);
        background-color: var(--grey-light);
    }

    button {
        background-color: var(--white);
        border-radius: var(--medium-radius);
        display: block;
        text-align: center;
        width: 100%;
        padding: 0.4vw;
        font-size: 0.9vw;
        cursor: pointer;
        border: none;
        outline: 0.1vw solid var(--grey);
        margin-bottom: 0.5vw;

        &:not(.disabled):hover {
            background-color: var(--grey-dark);
            outline: 0.1vw solid var(--grey-darker);
            outline-offset: 0.1vw;
            transition: background-color 0.2s;

            .menu-element-choice__icon {
                background-color: var(--grey-disabled);
            }
        }

        &.--disabled,
        &.--disabled:hover {
            background-color: var(--grey-light);
            color: var(--grey-disabled);
            cursor: not-allowed;
            border: 0.1vw dashed var(--grey-disabled);
            outline: none;

            .menu-element-choice__icon {
                background-color: var(--grey-disabled);
            }
        }

        /* Allow clicking but show that still in dev */
        &.--in-development {
            background-color: var(--grey-light);
            cursor: pointer;
            border: 0.1vw dashed var(--grey-disabled);

            &::after {
                content: 'BETA';

                display: flex;
                justify-content: center;
                align-items: center;


                width: 2vw;
                height: 0.8vw;
                font-size: 0.6vw;
                color: var(--black-light);
                background-color: var(--red-light);
                border-radius: var(--medium-radius);
                position: absolute;
                right: 2.5vw;
                rotate: -5deg;
                transform: translateY(-1.9vw) translateX(1.5vw);
            }
        }
    }

    /* Left Panel Button */
    .menu-element-choice__content {
        display: flex;
        align-items: center;
        gap: 0.4vw;
        font-size: 1vw;

        .menu-element-choice__icon {
            width: 1vw;
            height: 1vw;
            font-size: 0.9vw;
            background-color: var(--grey);
            filter: grayscale(100%);
            border-radius: 0.2vw;
            display: flex;
            direction: row;
            justify-content: center;
            align-items: center;
        }

        span {
            user-select: none;
            color: black;
        }
    }
}

/* Right Panel Only */
.edit-form__right-panel {
    display: flex;
    flex-direction: column;

    width: 67%;
    height: 100%;
    padding: 1vw;

    /* Right Panel Collapse Button */
    .right-panel__collapse-button,
    .right-panel__form-zone-fake-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background-color: var(--grey);
        border: 0.1vw solid var(--grey-dark);
        border-radius: var(--large-radius);
        font-size: 1vw;
        cursor: pointer;
        margin-bottom: 0.5vw;
        gap: 0.5vw;

        &:hover {
            background-color: var(--grey-dark);
            outline: 0.1vw solid var(--grey-darker);
            outline-offset: 0.1vw;
        }

        img {
            pointer-events: none;
            width: 1.25vw;
            display: inline-block;
            transition: transform 0.5s ease-in-out;
            transform: rotate(-90deg);
        }

        &.collapsed img {
            transform: rotate(90deg);
        }
    }

    .right-panel__form-zone-fake-button {
        cursor: default;

        &:hover {
            cursor: default;
            background-color: var(--grey);
            outline: none;
            outline-offset: none;
        }

        &.--no-form {
            background-color: var(--white);
            color: var(--grey-darker);
            font-size: 1.2vw;
            font-weight: bold;
            padding: 1vw;
            border: 0.1vw solid var(--grey);
            border-radius: var(--large-radius);
        }
    }

    /* Right Panel Form Header */
    .right-panel__form-header {
        display: flex;
        flex-direction: column;

        gap: 0.5vw;
        background-color: var(--white);
        border: 0.1vw solid var(--grey);
        border-radius: var(--small-radius);
        margin-bottom: 0.5vw;
        padding: 0.5vw;

        max-height: 100%;
        opacity: 1;
        transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out,
            padding 0.5s ease-in-out;

        h4 {
            font-size: 1vw;
            color: var(--black-light);
            border: 0.1vw solid var(--grey);
            border-radius: var(--small-radius);
            padding: 0.2vw;

            background-color: var(--grey-light);
        }

        &.collapsed {
            max-height: 0;
            padding: 0;
            margin: 0;
            opacity: 0;

            * {
                /* Disable interaction */
                pointer-events: none;
            }
        }

        select {
            appearance: none;
            -webkit-appearance: none;
            -moz-appearance: none;
            padding: 0.2vw;
        }

        input,
        select,
        textarea {
            width: 100%;
            font-size: 1vw;
            border: none;
            outline: 0.1vw solid var(--grey);
            color: var(--black-light);

            &:focus {
                outline: 0.1vw solid var(--grey-darker);
            }
        }
    }

    /* Right Panel Form Zone */
    .right-panel__form-zone {
        display: flex;
        flex-direction: column;

        background-color: var(--white);
        border: 0.1vw solid var(--grey);
        border-radius: var(--small-radius);
        padding: 0.2vw;
    }

    /* Right Panel Export Button */
    .right-panel__export-group {
        display: flex;
        justify-content: flex-end;

        padding: 0.5vw;
        gap: 0.5vw;

        /* border: 0.1vw solid #78866b; */
        /* border-radius: var(--large-radius); */
        /* font-size: 1vw; */
        /* cursor: pointer; */
        /* margin-top: 0.5vw; */

        /* &:hover { */
        /* background-color: #78866b; */
        /* outline: 0.1vw solid #5a5a5a; */
        /* outline-offset: 0.1vw; */
        /* } */

        /* &.--cancel { */
        /* background-color: #e47d7d; */
        /* padding: 0.5vw; */
        /* } */

        /* &.--save { */
        /* background-color: #7de4a5; */
        /* padding: 0.5vw; */
        /* } */
    }
}

.form_zone__form-zone-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.5vw;
    padding: 0.5vw;
}

.form-field {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    gap: 0.5vw;
    padding: 0.5vw;

    background-color: var(--grey-light);
    border: 0.1vw solid var(--grey);
    border-radius: 0.3vw;

    .form-field-drag-handle {
        display: flex;
        flex-direction: row;
        justify-content: center;
        pointer-events: none;

        width: 2.2vw;
        cursor: move;
    }

    img {
        width: 100%;
    }

    .form-field-options img {
        pointer-events: none;
        width: 1.5vw;
        height: 1.5vw;
    }

    .form-field-content.section {
        width: 100%;

        display: flex;
        flex-direction: row;
        justify-content: space-between;

        input {
            border: 0.01vw solid var(--grey);
            border-radius: 0.1vw;
            padding: 0.2vw;

            width: 100%;
            font-size: 22px;

            color: var(--black-light);
        }
    }
}

.export-button {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.3vw;

    background-color: var(--grey);
    border: 0.1vw solid var(--grey-dark);
    border-radius: var(--medium-radius);
    font-size: 1vw;
    cursor: pointer;
    padding: 0.5vw;
    transition: background-color 0.2s;

    img {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 1.5vw;
        height: 1.5vw;
    }

    &:not(:disabled):hover {
        background-color: var(--grey-dark);
        outline: 0.1vw solid var(--grey-darker);
        outline-offset: 0.1vw;
    }

    &.--cancel {
        background-color: var(--red-light);
        transition: background-color 0.5s;

        &:disabled {
            background-color: var(--grey);
            cursor: not-allowed;
        }

        &:not(:disabled):hover {
            background-color: var(--red-dark);
        }
    }

    &.--save {
        background-color: var(--green-light);
        transition: background-color 0.5s;

        &:disabled {
            background-color: var(--grey);
            cursor: not-allowed;
        }

        &:not(:disabled):hover {
            background-color: var(--green-dark);
        }
    }

    &.--info {
        background-color: var(--red-light);
        transition: background-color 0.5s;

        &.--valid {
            background-color: var(--blue-light);
        }

        &:disabled {
            background-color: var(--grey);
            cursor: not-allowed;
        }

        &:not(:disabled):hover {

            background-color: var(--red-dark);

            &.--valid {
                background-color: var(--blue-dark);
            }
        }
    }
}

.modal {
    z-index: 1;
    display: flex;
    position: fixed;
    left: 1vw;
    top: 1vw;
    width: 97.5%;
    height: 94%;
    border-radius: var(--medium-radius);
    background-color: rgba(0, 0, 0, 0.4);

    .modal__modal-wrapper:not(.--header) {
        background-color: var(--white);
        margin: 1vw auto;
        padding: 0.5vw;
        outline: 0.01vw solid var(--grey-darker);
        border-radius: var(--medium-radius);
        width: 50%;

        font-size: 0.9vw;

        overflow: auto;
    }

    .modal__modal-wrapper.--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.1vw solid var(--grey);

        h2 {
            font-size: 1.4vw;
            color: var(--black-light);
            margin-bottom: 0.5vw;
            padding: 0.1vw;
        }

        #closeModal {
            border-left: 0.1vw solid var(--grey);

            button {
                background-color: transparent;
                border: none;

                font-size: 2vw;
                width: 3vw;
                margin-left: 0;
                transition: transform 0.35s;

                &:hover {
                    transform: rotate(-90deg) scale(1.5);
                    transition: transform 0.5s;
                    cursor: pointer;
                }
            }
        }
    }

    .modal-wrapper__modal-content {
        display: flex;
        flex-direction: column;
        gap: 0.5vw;
        padding: 0.5vw;

        .modal-content__condition {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;

            padding: 0.2vw;
            border: 0.1vw solid var(--grey);
            border-radius: var(--small-radius);
            background-color: var(--grey-light);
            gap: 0.5vw;

            h3 {
                width: 99%;
                display: flex;
                align-items: center;

                font-size: 1.2vw;
                color: var(--black-light);
            }

            /* Checkboxes */
            input {

                width: 1.5vw;
                height: 1.5vw;


            }
        }
    }
}

#add-button:hover {
    border-color: black;
}

#add-button {
    border: 0.1vw solid gray;
    cursor: pointer;
    margin: 0.5vw 0;
    width: 100%;
    padding: 0.5vw;
    border-radius: 0.3vw;
    font-size: 0.8vw;
}
</style>