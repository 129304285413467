<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>
            <div class="form-field__form-field-content">
                <fieldKeySelect v-model="model.field_key" :keys="$store.state.formKeys" />
                <fieldLabelInput v-model="model.field_label" :placeholder="'This text input asks the user to...'" />
                <fieldHintInput v-model="model.field_hint" isTextArea
                    :placeholder="'This hint the user that the input should be...'" />

                <br>
                <hr>
                <br>

                Try it:
                <textarea :value="model.field_label" disabled />
                <input type="text" :placeholder="model.field_hint" />
            </div>
        </template>
        <template v-slot:options>
            <div class="parameter-group__option">
                <button class="--option">
                    <label>🔑 Required :</label>
                    <input type="checkbox" v-model="model.field_required">
                </button>
            </div>
        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps } from 'vue';
import skeletonField from './skeletonField.vue'
import fieldKeySelect from "@/components/map/topTools/forms/customElements/fieldKeySelect.vue";
import fieldLabelInput from '@/components/map/topTools/forms/customElements/fieldLabelInput.vue';
import fieldHintInput from '@/components/map/topTools/forms/customElements/fieldHintInput.vue';

const model = defineModel();

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function
});
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
#field-element:invalid {
    box-shadow: var(--red-glow-shadow);
}

.form-field__form-field-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    width: 89%;
    gap: 0.2vw;

    label {
        overflow: scroll;
        /* break lines */
        white-space: pre-wrap;
        /* text-overflow: ellipsis; */
        /* white-space: nowrap; */
        width: 100%;
    }
}

.parameter-group__option {

    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 0.5vw;

    .--option {
        display: inline-flex;
        flex-direction: row;
        align-items: center;

        background-color: var(--grey-light);
        border-radius: var(--small-radius);
        border: 0.01vw solid var(--grey-darker);

        padding: 0.2vw;
        font-size: 0.9vw;

        label {
            margin-right: 0.2vw;
            /* filter: grayscale(100%); */
        }

        input {
            display: inline-flex;
            flex-direction: row;

            width: 2vw;
            height: 1.1vw;
            font-size: 0.9vw;
            padding: 0.2vw;

            &:focus {
                border: none;
                /* outline: 0.01vw solid var(--grey-darker); */
                /* border-radius: var(--small-radius); */
            }
        }

        input[type="checkbox"] {

            width: 1vw;
            height: 1vw;

            border-radius: var(--small-radius);
            border: none;
            outline: none;
        }
    }
}
</style>
