import { createRouter, createWebHistory } from "vue-router";

import PublicMapPage from '@/views/PublicMapPage.vue'
import HomePage from '@/views/HomePage.vue'
import LoginPage from "@/views/LoginPage.vue";
import NotFoundPage from "@/views/NotFoundPage.vue";
import PrivateMapPage from '@/views/PrivateMapPage.vue'

const Router = createRouter({
    routes : [
        {
            path : '/map/:airport',
            component : PublicMapPage
        },
        {
            path : '/privateMap/:airport',
            component : PrivateMapPage
        },
        {
            path : '/login/:airport',
            component : LoginPage
        },
        {
            path : '/home/:airport',
            component : HomePage
        },
        {
            path: '/:anything(.*)*',
            component : NotFoundPage
        }
    ],
    history : createWebHistory()
})

export default Router;