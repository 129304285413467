<template>
    <span></span>
</template>

<script>
import L from "leaflet";
export default {
    props: {
        markers: Array,
        selectedMarker: String
    },
    data() {
        return {
            basicMarker: null,
            basicSelectedMarker: null,
            hidden: false
        };
    },

    watch: {
        selectedMarker(newValue, oldValue) {
            if (newValue == "observations") {
                this.$props.markers.forEach(e => {
                    if (this.$store.state.filteredObservations.includes(e.data)) e.class.getElement().classList.remove("hidden")
                })
            }
            if (oldValue == "observations") {
                this.selectMarker(null)
                this.$props.markers.forEach(e => e.class.getElement().classList.add("hidden"))
            }
        },
        '$store.state.filteredObservations'(newValue) {
            // TODO : Optimiser
            //this.selectMarker(null)
            this.$props.markers.forEach(e => e.class.getElement().classList.add("hidden"))
            this.$props.markers.forEach(e => {
                if (newValue.includes(e.data)) e.class.getElement().classList.remove("hidden")
            })
        }
    },

    mounted() {
        //les marqueurs
        this.basicMarker = L.divIcon({
            className: "observation-marker",
            html: '<svg width="15" height="15" viewBox="0 0 100 100"><circle cx="50" cy="50" r="40" fill="black" /></svg>',
        });
        this.basicSelectedMarker = L.divIcon({
            className: "selected-observation-marker",
            html: '<svg viewBox="0 0 16 16" width="20" height="20"><path fill="black" d="M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z"/></svg>'
        });
    },

    methods: {
        addMarker(e, map)
        {
            if((e.latitude !=undefined && e.longitude !=undefined) || (e.image_latitude !=undefined && e.image_longitude !=undefined))
            {
                const markerClass = L.marker([e.latitude ? e.latitude : e.image_latitude , e.longitude ? e.longitude :e.image_longitude ], {
                    icon: this.basicMarker,
                });
                markerClass.addTo(map)
                const marker = { class: markerClass, selected: false, data: e };
                this.$props.markers.push(marker);
                markerClass.getElement().addEventListener("click", () => {
                    const windowRatio = window.innerWidth / window.innerHeight
                    const markerLatLng = markerClass.getLatLng();
                    var adjustedLatLng = L.latLng(
                        markerLatLng.lat,
                        windowRatio >= 1 ? markerLatLng.lng + 0.0011 : markerLatLng.lng
                    );
                    this.$emit("clickMarker", 'observations', adjustedLatLng, e)
                    // changer le marqueur
                    console.log("marker 2 :",  marker);
                    this.selectMarker(marker);
                });
            }
            
        },
        init(map) {
            this.$store.state.observations.forEach((e) => {
                this.addMarker(e, map)
            });
        },
        selectMarker(marker) {
            console.log("marker 3 :",  marker);
            this.$props.markers.forEach((e) => {
                if (e.selected) {
                    e.selected = false;
                    e.class.setIcon(this.basicMarker);
                }
            });
           
            if (marker !== null) {
                marker.selected = true;
                marker.class.setIcon(this.basicSelectedMarker);
            }
        },
    },
};
</script>
<style>
.selected-observation-marker,
.observation-marker {
    background-color: none;
}

.observation-marker {
    animation: bip 2s ease-out infinite;
}

.observation-marker.hidden {
    display: none;
}
</style>
<style scoped>
span {
    display: none;
}
</style>
