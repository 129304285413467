<template>
    <footer :style="{ marginTop: marginTop + 'vw' }">
        <div class="top"></div>
        <div class="bottom">
            <a>Contact us</a>
            <a>Legal Notice</a>
            <a>Sitemap</a>
            <span>
                Olga © {{ `${new Date().getFullYear()}` }}
                <br>
                <span class="span-small">rev {{ buildTimestamp }}</span>
            </span>
        </div>
    </footer>
</template>

<script>
export default {
    props: {
        marginTop: Number
    },
    data() {
        const buildTimestampUtc = document.documentElement.dataset.buildTimestampUtc;
        const [day, month, year, time] = buildTimestampUtc.match(/(\d{2})\/(\d{2})\/(\d{4}), (\d{2}:\d{2}:\d{2})/).slice(1);
        const [hours, minutes, seconds] = time.split(':');

        const buildTimestamp = `${year}.${month}.${day}.${hours}${minutes}${seconds}`;

        return {
            buildTimestamp
        };
    }
}
</script>

<style scoped>
footer {
    height: 15vw;
    width: 100%;
    background-color: var(--green);
    padding: 0 7vw;
}

.bottom {
    width: 100%;
    border-top: 0.1vw solid rgba(255, 255, 255, 0.2);
    height: 30%;
    display: flex;
    color: white;
    align-items: center;
    justify-content: space-around;
}

.bottom a {
    cursor: pointer;
}

.bottom span {
    user-select: none
}

.span-small {
    font-size: 0.8vw;
    color: rgba(255, 255, 255, 0.5);
}

.bottom a,
.bottom span {
    text-align: center;
    transition: 0.1s ease;
    text-decoration: none;
}

.bottom a:hover,
.bottom span:hover {
    color: var(--orange);
}

.top {
    width: 100%;
    height: 70%;
}
</style>