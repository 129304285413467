<template>
    <div :class="{toComplete : detailedObservation.toComplete}" class="data-container">
        <div class="images">
            <!-- <img @click="actualImage = Math.max(actualImage - 1, 0)" v-if="actualImage > 0" class="arrow"
                id="left-arrow" src="@/assets/map/arrow.png" />
            <img @click="actualImage = Math.min(actualImage + 1, imagesCount - 1)" v-if="actualImage < imagesCount - 1"
                class="arrow" id="right-arrow" src="@/assets/map/arrow.png" /> -->
            <div class="images-container">
                <!-- <img :class="{ hidden: key !== actualImage }" v-for="(src, key) in detailedObservation.images"
                            :key="key" :src="src" /> -->
                <img :src="detailedObservation.imageUrl ?detailedObservation.imageUrl : detailedObservation.image " />
            </div>
        </div>
        <div class="data">
            <template v-if="toComplete && detailedObservationLocal !=null">
                <template v-for="(data, key) in detailedObservation" :key="key">
                    <section v-if="filterKey(key)">
                        <h6>{{ key }}</h6>
                        <p v-if="$props.detailedObservation[key] !== ''">{{ key=='date' ? showDate(data)  : data }}</p>
                        <input v-if="$props.detailedObservation[key] === ''" v-model="detailedObservationLocal[key]">
                    </section>
                </template>
            </template>
            <template  v-if="!toComplete && detailedObservationLocal !=null">
                <template v-for="(data, key) in detailedObservation" :key="key">
                    <section v-if="data && filterKey(key)">
                        <h6>{{ key }}</h6>
                        <p>{{ key=='date' ? showDate(data)  : data }}</p>
                    </section>
                </template>
            </template>
            <button @click="handleValidate" id="validate" v-if="toComplete">Validate</button>
        </div>
    </div>
</template>

<script>
export default {

    data() {
        return {
            detailedObservationLocal : null
        }
    },
    computed : 
    {
        toComplete()
        {
            return this.$props.detailedObservation.toComplete
        } 
    },
    methods :
    {
        showDate(date)
        {
            if(date){
                 return new Date(date * 1000).toDateString() 
            }
        },
        handleValidate()
        {
            delete(this.detailedObservationLocal.toComplete)
            fetch(process.env.VUE_APP_BACKEND_V2_URL +'/completeObservation', {
                method : "POST", 
                body : JSON.stringify(this.detailedObservationLocal)
            }).then(res=>{
                res.json().then(json=>{
                    if(!json.error){
                        this.$store.commit("addObservation", this.detailedObservationLocal)
                        this.$emit('delete', this.detailedObservationLocal)
                    }   
                })
            })
        },
        filterKey(key)
        {
            if(key == 'imageUrl' || key == 'image' || key == 'type_' || key == 'id_' ) return false
            return true
        }
    },
    mounted()
    {
        this.detailedObservationLocal = JSON.parse(JSON.stringify(this.$props.detailedObservation))
    },


    props: {
        detailedObservation: Object
    }
}
</script>

<style scoped>
.data-container {
    padding-right: 1vw;
}

.images {
    width: 100%;
    height: 20vw;
    position: relative;
    border-radius: 0.3vw;
    overflow: hidden;
}

.images .arrow {
    position: absolute;
    width: 3vw;
    top: 50%;
    transition: 0.2s ease;
    cursor: pointer;
    z-index: 2;
    border: 0.1vw solid white;
    border-radius: 100%;
    background-color: white;
}

.images .arrow:hover {
    opacity: 1;
    filter: invert(1);
}

.images #right-arrow {
    right: 1vw;
    transform: translateY(-30%) rotate(180deg);
}

.images #left-arrow {
    left: 1vw;
    transform: translateY(-30%);
}

.images-container {
    background-color: rgba(213, 213, 213, 0.233);
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
}

.images-container img {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.images-container img.hidden {
    opacity: 0;
    pointer-events: none;
}

.data {
    border-top: 0.1vw solid rgba(128, 128, 128, 0.204);
    margin-top: 2vw;
    padding-top: 2vw;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.data section {
    display: flex;
    gap: 1vw;
}

.data section h6 {
    width: 25%;
    display: flex;
    align-items: flex-end;
    font-size: 1vw;
    color: var(--black);
    font-family: 'Century Gothic';
    border-bottom: 0.1vw solid rgba(194, 194, 195, 0.455);
    padding: 1vw;
    padding-left: 0.2vw;
}

.data section p, .data section input {
    border-radius: 0.2vw;
    width: 75%;
    padding: 0.5vw 0;
    background-color: rgba(213, 213, 213, 0.233);
    padding: 1vw;
    color: var(--green);
    font-size: 0.8vw;
}
button#validate
{
    padding: 1vw;
    border: none;
    color: white;
    font-size: 0.9vw;
    background-color: var(--green);
    width: 40%;
    margin-left: auto;
    margin-bottom: 1vw;
    transition: 0.1s ease;
}

button#validate:hover
{
    background-color: var(--orange);
    cursor: pointer;
}

@media screen and (max-width: 100vh) {
    .images {
        width: 100%;
        height: 50vw;
    }

    .data section h6 {
        width: 30%;
        font-size: 2.5vw;
    }

    .data section p {
        width: 70%;
        font-size: 2.3vw;
    }
}
</style>