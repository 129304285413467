<template>
    <div class="menu">

        <img @click="$emit('close')" id="comeback" src="@/assets/map/comeback.png">

        <h4 id="title">Add some species</h4>
        <div class="inputs">
            <AddSpeciesLine ref="speciesLine" v-for="(species, key) in species_to_add" :key="key" :content="species"></AddSpeciesLine>
            <div  >
                <p v-html="species"></p>
            </div>
            <button @click="adding_species = true" class="btn" id="add-btn">+ ADD SPECIES NAME</button>
        </div>

        <div v-if="adding_species" id="search-species-name-menu">
            <img @click="adding_species = false" id="comeback" src="@/assets/map/comeback.png">
            <input placeholder="Enter species name..." v-model="search_species_input" id="search-bar-custom" type="text">
            <div id="search-results">
                <p @click="adding_species = false;species_to_add.push(species)" v-for="(species, key) in species_found" :key="key" class="result" v-html="species.fullNameHtml"></p>
                <div id="no-species-found" v-if="species_found.length == 0">
                    <img src="@/assets/map/noresult.png">
                    <p>No result found</p>
                </div>

            </div>
        </div>

        <div class="bottom-menu">
            <button class="btn" id="import-csv-btn">import CSV</button>
            <button @click="tryAddSpecies" class="btn" id="try-btn">TRY</button>
        </div>
    </div>

</template>

<script>
import AddSpeciesLine from "@/components/map/topTools/administration/sub/AddSpeciesLine.vue";

export default {
    components : 
    {
        AddSpeciesLine,
    },
    data() {
        return {
            species_to_add : [],
            search_species_input: null,
            species_found: [],
            adding_species : false,
        }
    },
    mounted()
    {
        this.search_species_input = ''
    },
    watch: {
        async search_species_input(input) {
            if(input == '') input = 'A'
            var response = await fetch(this.constructGetTaxRefAutocomplete(input,15, 1));
            var json = await response.json();
            if(json._embedded)
            {
                this.species_found = json._embedded.taxa
            }else
            {
                this.species_found = []
            }
            
            console.log(this.constructGetTaxRefAutocomplete(input,15, 1));
        }
    },
    methods: {
        constructGetTaxRefAutocomplete(name, lines, page) {
            return "https://taxref.mnhn.fr/api/taxa/autocomplete?term=" + name + "&size=" + lines + "&page=" + page;
        },
        tryAddSpecies()
        {
            if(this.$refs.speciesLine){
                this.$refs.speciesLine.map((line)=>{
                    line.try()
                })
            }
        }
    }
}
</script>

<style scoped>
.menu {
    position: absolute;
    left: 0;
    padding: 0 2vw;
    top: 0;
    z-index: 9999;
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.menu #title {
    font-family: "Century Gothic";
    font-size: 1vw;
    padding: 1vw 0;
}

.menu .inputs {
    width: 100%;
    display: flex;
    height: 100%;
    overflow: auto;
    flex-direction: column;
    gap: 1vw;
    padding-right: 1vw;
}


.menu .btn {
    cursor: pointer;
    font-size: 0.55vw;
    width: 30%;
    padding: 0.7vw;
    transition: 0.1s ease;
    border: 0.1vw solid rgba(128, 128, 128, 0.274);
    border-radius: 0.5vw;
    filter: opacity(0.8)
}

.menu .btn:hover {
    filter: opacity(1)
}

.menu .btn#add-btn {
    margin-bottom: 1vw;
    width: 30%;
}


.menu .bottom-menu {
    display: flex;
    justify-content: space-between;
    background-color: rgba(128, 128, 128, 0.137);
    padding: 0.5vw;
    margin: 1vw;
    border-radius: 1vw;
}


.menu .bottom-menu #try-btn {
    background-color: rgb(255, 163, 101);
    width: 30%;
    color: white;
}

.menu .bottom-menu #import-csv-btn {
    width: 10%;
    background-color: rgba(128, 128, 128, 0.705);
    color: white;
    border: none;
}

#search-species-name-menu {
    display: flex;
    flex-direction: column;
    padding: 0 2vw;
    position: absolute;
    left: 0;
    z-index: 10;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: white;

}

#search-species-name-menu input#search-bar-custom {
    width: 100%;
    padding: 1vw;
    font-family: "Century Gothic";
    font-size: 1vw;
    border: none;
    border-radius: 1vw;
    margin: 1vw 0;
    background-color: rgba(128, 128, 128, 0.11);
}

#search-species-name-menu input#search-bar-custom:focus {
    outline: none;
}

#search-species-name-menu #search-results {
    display: flex;

    overflow: auto;
    margin-top: 1vw;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

#search-species-name-menu #search-results .result {
    padding: 1vw 0;
    border-radius: 1vw;
    cursor: pointer;
    font-family: "Century Gothic";
    padding-left: 1vw;
    font-size: 0.8vw;
}


#search-species-name-menu #search-results .result:hover {
    background-color: rgba(128, 128, 128, 0.064);
}


#search-species-name-menu #no-species-found {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    opacity: 0.7;
    left: 50%;
    top: 50%;
    transform: translateX(-50%);
}

#search-species-name-menu #no-species-found img {
    width: 3vw;
}

#search-species-name-menu #no-species-found p {
    font-size: 1vw;
}

img#comeback
{
    width: 1.3vw;
    background-color: none;
    transition: 0.1s ease;
    cursor: pointer;;
}

img#comeback:hover
{
    scale: 1.1;
}
</style>