<template>
    <div :class="{ expanded: expanded }" class="container">
        <div class="observations-scroll">
            <span v-if="showNoResultFound()" id="no-result-container">
                <img src="@/assets/map/noresult.png">
                No result found...
            </span>
            
            <h3 v-if="actualObservations == 'observations'" class="title">Observations</h3>
            <h3 v-if="actualObservations == 'observationsToComplete'" class="title">Observations to complete</h3>

            <div class="observations-container">
                <template v-if="actualObservations == 'observations'">
                    <observationComp @click="handleObservationClick(observation, key)"
                    v-for="(observation, key) in filteredObservations" :data="observation" :key="key">
                    </observationComp>
                </template>
                <template v-if="actualObservations == 'observationsToComplete'">
                    <observationComp @click="handleObservationClick(observation, key)"
                    v-for="(observation, key) in $store.state.observationsToComplete" :data="observation" :key="key">
                    </observationComp>
                </template>
                
            </div>
        </div>

        <div class="observations-to-complete-scroll">

        </div>

        <div v-if="expanded" class="mid-tool-container">
            <observationFilterComp @addSpecies="$emit('addSpecies')" :filters="filters"></observationFilterComp>
        </div>
        <div class="right-btns">
            <img :class="{selected:expanded}" @click="expandFilterMenu" src="@/assets/map/filter.png">
            <img :class="{selected:actualObservations == 'observationsToComplete'}" @click="showObservationsToComplete" src="@/assets/map/check-list.png">
        </div>

        <div v-if="detailedObservation !== null" class="observation-data-container">
            <img @click="detailedObservation = null" id="comeback" src="@/assets/map/comeback.png" />
            <observationDetailedComp @delete="handleDeleteObservationToComplete" :detailedObservation="detailedObservation" />
        </div>
    </div>
</template>

<script>
import observationComp from "./subComp/observationComp.vue";
import observationFilterComp from './subComp/observationFilterComp.vue';
import observationDetailedComp from './subComp/observationDetailedComp.vue';
export default {
    components: {
        observationComp,
        observationFilterComp,
        observationDetailedComp
    },
    emits: ["observationClicked", "setExpand", "addSpecies", "addObservation"],
    data() {
        return {
            detailedObservation: null,
            detailedObservationKey : null,
            actualImage: 0,
            expanded: false,
            actualObservations : "observations",
            filters: [
                {
                    type: "list",
                    name: "Species",
                    keys: ["predictedEspece", "type"],
                    content: []
                },
                {
                    type: "checkbox",
                    name: "Status",
                    choices : {"Invasive" :['Indésirable', "Invasive"], "Protected" : ['Protégé', "Protected"], "Common" : ["Common"]},
                    // choices: ["Indésirable", "Protected", "Common", "Unknown"],
                    key: 'status',
                    checked: {}
                },

                {
                    type: "date",
                    name: "Start date",
                    content: ""
                },
                {
                    type: "date",
                    name: "End date",
                    content: ""
                },
                // {
                //     type: "text",
                //     name: "Agent",
                //     content: ""
                // },
            ],
        };
    },
    methods: {
        showNoResultFound()
        {
            if(this.actualObservations == 'observations') return this.filteredObservations.length <= 0
            if(this.actualObservations == 'observationsToComplete') return this.$store.state.observationsToComplete.length <= 0
        },
        handleDeleteObservationToComplete(observation)
        {
            // TODO : emit to parent who delegate to marker handler the add of the marker
            this.$emit('addObservation', observation)
            this.$store.state.observationsToComplete.splice(this.detailedObservationKey, 1) 
            this.detailedObservation=null;
        },
        async showObservationsToComplete()
        {
            this.expanded = false
            this.$emit("setExpand", false)
            await this.$store.dispatch("getObservationsToComplete")   
            if(this.actualObservations == 'observationsToComplete') this.actualObservations = 'observations'
            else this.actualObservations = 'observationsToComplete'  
                   
        },
        expandFilterMenu() {
            this.expanded = !this.expanded
            this.actualObservations = 'observations'
            this.$emit("setExpand", this.expanded)
        },
        reset() {
            this.expanded = false
            this.detailedObservation = null
        },
        handleObservationClick(observation, key) {
            this.detailedObservationKey = key
            this.detailedObservation = observation;
            if(this.actualObservations == 'observations') this.$emit("observationClicked", observation)
            if (this.expanded) {
                this.expanded = false
                this.$emit("setExpand", false)
            }
        },
        setData(data) {
            this.actualImage = 0;
            this.detailedObservation = data;
        },
        setFilters(data) {
            this.detailedObservation = null
            const keys = Object.keys(data)
            keys.forEach(key => {
                const filter = this.filters.find(e => e.keys.includes(key));
                if (filter.type == 'list' && !filter.content.find(e => e.key == key && e.content == data[key]) && data[key] !== '') filter.content.push({
                    key: key, content: data[key]
                })
            })
        }
    },
    mounted()
    {
       
    },
    computed: {
        imagesCount() {
            if (this.detailedObservation.images)
                return this.detailedObservation.images.length;
            else return 0;
        },
        

        filteredObservations() {
            let observations = this.$store.state.observations
            //TODO : réunir en un seul filter pour optimiser
            this.filters.forEach(filter => {
                if (filter.type == 'list') {
                    observations = observations.filter(e => {
                        if (filter.content.length == 0) return true

                        return filter.keys.some(key => {
                            if (e[key]) {
                                return filter.content.some(e2 => e2.key == key && e[key].includes(e2.content));
                            }
                            return false;
                        });
                    })
                }
                if (filter.type == 'checkbox') {
                    observations = observations.filter(e => {
                        let checked = Object.keys(filter.checked).filter(key => filter.checked[key]);
                        let allKeys = []
                        checked.forEach(e2=>
                            {
                                filter.choices[e2].forEach(e3=> allKeys.push(e3.toLowerCase()))
                            }
                        )
                        if(checked.length == 0) return true
                        return e[filter.key] && allKeys.find(el=> el== e[filter.key].toLocaleLowerCase())
                    })
                }
                if (filter.name == "Start date" || filter.name == "End date") {
                    if (filter.content != "" && observations.length > 0) {
                        observations = observations.filter(e => {
                            const contentDate = new Date(filter.content)
                            if (filter.name == "Start date") return e.date * 1000 > contentDate
                            if (filter.name == "End date"){
                                const contentDatePlusOneDay = new Date(contentDate.getTime() + 24 * 60 * 60 * 1000);
                                return e.date * 1000 <= contentDatePlusOneDay
                            } 
                        })
                    }
                }
            })
            this.$store.commit("setFilteredObservations", observations);
            return observations;
        },
    },
};
</script>

<style scoped>
.container {
    height: 100%;
    width: 100%;
    display: flex;
}

.observations-scroll {
    width: 100%;
    margin-right: 2vw;
    height: 100%;
    padding-right: 1vw;
    overflow: auto;
    position: relative;
}

.observations-scroll .title
{
    padding: 1vw 0.5vw;
    font-size: 1vw;
    font-family: 'Century Gothic'
}

.observations-container {
    gap: 1vw;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    overflow: auto;
}

.observation-data-container {
    overflow: hidden;
    border-radius: 2vw;
    height: 100%;
    width: 100%;
    padding: 3vw;
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgb(255, 255, 255);
}

/* 
WHEN EXPANDED */

.container.expanded .observations-scroll {
    width: 50%;
}


.mid-tool-container {
    width: 50%;
}

.right-btns {
    width: 5vw;
    margin-left: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 0.5vw;
    background-color: #F5F5F5;
    border: 0.01vw solid #E0E0E0;
    border-radius: 0.6vw;
    padding-top: 1vw;

}

.right-btns img {
    padding: 0.5vw;
    opacity: 0.7;
    width: 70%;
    border: 0.1vw solid rgba(0, 0, 0, 0.423);
    transition: 0.1s ease;
    background-color: white;
    border-radius: 0.5vw;
    cursor: pointer;
}
.right-btns img.selected
{
    border: 0.15vw solid var(--black);
}

.right-btns img:hover {
    opacity: 1;
}

.data-container {
    margin-top: 2vw;
    overflow: auto;
    height: 100%;
}

img#comeback {
    cursor: pointer;
    position: absolute;
    left: 3vw;
    z-index: 2;
    top: 2vw;
    width: 1.5vw;
    transition: 0.1s ease;
}

img#comeback:hover {
    scale: 1.2;
}

#no-result-container {
    position: absolute;
    flex-direction: column;
    height: 5vh;
    font-size: 2.5vh;
    align-items: center;
    justify-content: center;
    left: 50%;
    top: 55%;
    display: flex;
    gap: 1vh;
    opacity: 0.6;
    transform: translateX(-50%) translateY(-50%);
    font-family: 'Century Gothic';
}

#no-result-container img {
    width: 7vh;
}


@media screen and (max-width: 100vh) {
    img#comeback {
        width: 4vw;
        left: 4vw;
    }

    .right-btns {
        width: 12vw;

    }

    .right-btns img {
        border-radius: 1vw;
        padding: 1vw;
    }

    .data-container {
        margin-top: 5vw;
    }

    .container.expanded .observations-scroll {
        display: none;
    }


    .mid-tool-container {
        width: 100%;
    }

}
</style>