<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>
            <div class="form-field__form-field-content">
                <h4>
                    {{ title }} has not been implemented yet.
                </h4>
            </div>
        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps } from 'vue';
import skeletonField from './skeletonField.vue'

const model = defineModel();

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function
});
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped>
h4 {
    font-size: 1.5vw;
    color: var(--grey-darker);
    font-weight: bold;
    text-align: center;
}
</style>