<template>
    <skeletonField v-model="model" @callToDeleteSelf="deleteSelf(index)" :title="title">
        <template v-slot:content>
            <div class="form-field__form-field-content">
                <button disabled>
                    <h4>
                        📍 Polygon Picker (Option)
                    </h4>
                </button>
            </div>
        </template>
    </skeletonField>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineModel, defineProps } from 'vue';
import skeletonField from './skeletonField.vue'

const model = defineModel();

defineProps({
    title: String,
    index: Number,
    deleteSelf: Function
});

</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
.form-field__form-field-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    gap: 0.5vw;

    button {
        display: flex;
        align-items: center;
        justify-content: center;

        width: 100%;
        border: 0.01vw solid var(--grey-darker);
        border-radius: var(--small-radius);

        font-size: 1.2vw;
        padding: 0.3vw;

        &:disabled {
            background-color: var(--grey-dark);

            h4 {
                color: var(--black-light);
            }
        }
    }
}
</style>