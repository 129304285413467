export default [
    {
        "Nom scientifique": "Ficopomatus enigmaticus (Fauvel, 1923)",
        "Famille": "Serpulidae",
        "Ordre": "Sabellida",
        "Classe": "Polychaeta (Vers anneles marins, Polychetes)",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 366400,
        "Nom français": "Mercierelle"
    },
    {
        "Nom français": "Grenouille saharienne (La)",
        "Nom scientifique": "Pelophylax saharicus (Boulenger in Hartert, 1913)",
        "Ordre": "Anura (Anoures)",
        "Famille": "Ranidae (Ranides)",
        "Classe": "Amphibia (Amphibiens, batraciens)",
        "CD_REF": 995112,
        "Règne": "Animalia (Animaux)"
    },
    {
        "Ordre": "Diptera (Mouches, Moustiques, Cousins)",
        "Famille": "Tephritidae",
        "CD_REF": 653205,
        "Classe": "Insecta (Insectes)",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Mouche de l?olive, Mouche de l?olivier",
        "Nom scientifique": "Bactrocera oleae (Rossi, 1790)"
    },
    {
        "Famille": "Crambidae",
        "Règne": "Animalia (Animaux)",
        "Classe": "Insecta (Insectes)",
        "CD_REF": 645061,
        "Ordre": "Lepidoptera (Papillons)",
        "Nom scientifique": "Cydalima perspectalis (Walker, 1859)",
        "Nom français": "Pyrale du buis"
    },
    {
        "Nom scientifique": "Ludwigia peploides (Kunth) P.H.Raven, 1964",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Ludwigie faux peplis, Jussie faux peplis, Jussie rampante, Jussie",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Ordre": "Myrtales (Myrtales)",
        "CD_REF": 106748,
        "Famille": "Onagraceae (Onagracees)"
    },
    {
        "Nom scientifique": "Cabomba caroliniana A.Gray, 1848",
        "Famille": "Cabombaceae (Cabombacees)",
        "Ordre": "Nymphaeales (Nympheales)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Cabomba de Caroline",
        "Règne": "Plantae (Plantes)",
        "CD_REF": 610664
    },
    {
        "Nom scientifique": "Mnemiopsis leidyi A. Agassiz, 1865",
        "Ordre": "Lobata",
        "Famille": "Bolinopsidae",
        "Classe": "Tentaculata",
        "Nom français": "Ctenophore americain",
        "CD_REF": 379588,
        "Règne": "Animalia (Animaux)"
    },
    {
        "Nom scientifique": "Hemigrapsus sanguineus (De Haan, 1835)",
        "Ordre": "Decapoda (Decapodes)",
        "Classe": "Malacostraca (Malacostraces)",
        "Nom français": "Crabe sanguin (Le), Crabe japonais (Le)",
        "CD_REF": 593842,
        "Famille": "Varunidae",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Nom scientifique": "Paspalum distichum L., 1759",
        "Ordre": "Poales (Poales)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Règne": "Plantae (Plantes)",
        "Famille": "Poaceae (Poacees)",
        "Nom français": "Paspale distique, Paspale a deux epis",
        "CD_REF": 112483
    },
    {
        "Règne": "Animalia (Animaux)",
        "Famille": "Vespidae",
        "Classe": "Insecta (Insectes)",
        "Nom français": "Frelon a pattes jaunes, Frelon asiatique",
        "Ordre": "Hymenoptera (Hymenopteres)",
        "CD_REF": 433590,
        "Nom scientifique": "Vespa velutina nigrithorax du Buysson, 1905"
    },
    {
        "Ordre": "Stolidobranchia",
        "CD_REF": 837382,
        "Famille": "Styelidae",
        "Règne": "Animalia (Animaux)",
        "Classe": "Ascidiacea (Ascidies)",
        "Nom français": "Botrylle dore, Botrylle de San Diego",
        "Nom scientifique": "Botrylloides diegensis Ritter & Forsyth, 1917"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Nom français": "Eponge chinoise",
        "Famille": "Coelosphaeridae",
        "CD_REF": 543362,
        "Classe": "Demospongiae (Demosponges)",
        "Nom scientifique": "Celtodoryx ciocalyptoides (Burton, 1935)",
        "Ordre": "Poecilosclerida"
    },
    {
        "Ordre": "Asterales (Asterales)",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Baccharis a feuilles d'Halimium, Baccharide a feuilles d'Halimium, Senecon en arbre",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Famille": "Asteraceae (Asteracees)",
        "Nom scientifique": "Baccharis halimifolia L., 1753",
        "CD_REF": 85474
    },
    {
        "Nom français": "Mimosa argente, Mimosa des fleuristes, Mimosa de Bormes, Mimosa d'hiver, Acacia argente",
        "Règne": "Plantae (Plantes)",
        "Ordre": "Fabales (Fabales)",
        "Famille": "Fabaceae (Fabacees)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Acacia dealbata Link, 1822",
        "CD_REF": 79691
    },
    {
        "CD_REF": 219464,
        "Classe": "Insecta (Insectes)",
        "Ordre": "Hymenoptera (Hymenopteres)",
        "Nom français": "Fourmi d'Argentine",
        "Famille": "Formicidae (Fourmis)",
        "Nom scientifique": "Linepithema humile (Mayr, 1868)",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Ordre": "Stylommatophora",
        "CD_REF": 782405,
        "Famille": "Agriolimacidae",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Loche vagabonde",
        "Nom scientifique": "Deroceras invadens Reise, Hutchinson, Schunack & Schlitt, 2011",
        "Classe": "Gastropoda (Gasteropodes)"
    },
    {
        "Règne": "Plantae (Plantes)",
        "Nom français": "Chevrefeuille du Japon, Clematite du Japon",
        "CD_REF": 106571,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Lonicera japonica Thunb., 1784",
        "Ordre": "Dipsacales (Dipsacales)",
        "Famille": "Caprifoliaceae (Caprifoliacees)"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Classe": "Mammalia (Mammiferes)",
        "Nom scientifique": "Procyon lotor (Linnaeus, 1758)",
        "Nom français": "Raton laveur",
        "Famille": "Procyonidae (Ratons laveurs)",
        "CD_REF": 60822,
        "Ordre": "Carnivora"
    },
    {
        "Famille": "Psyllidae",
        "Nom scientifique": "Ctenarytaina eucalypti (Maskell, 1890)",
        "Classe": "Insecta (Insectes)",
        "CD_REF": 220385,
        "Règne": "Animalia (Animaux)",
        "Ordre": "Hemiptera (Cigales, Cochenilles, Pucerons, Punaises)",
        "Nom français": "Psylle de l'eucalyptus"
    },
    {
        "CD_REF": 790708,
        "Nom français": "Tunicier didemnum, Didemne etendard",
        "Règne": "Animalia (Animaux)",
        "Famille": "Didemnidae",
        "Ordre": "Aplousobranchia",
        "Nom scientifique": "Didemnum vexillum Kott, 2002",
        "Classe": "Ascidiacea (Ascidies)"
    },
    {
        "Nom scientifique": "Paspalum dilatatum Poir., 1804",
        "Classe": "Equisetopsida (Equisetopsides)",
        "CD_REF": 112482,
        "Règne": "Plantae (Plantes)",
        "Ordre": "Poales (Poales)",
        "Famille": "Poaceae (Poacees)",
        "Nom français": "Paspale dilate"
    },
    {
        "Nom scientifique": "Sporobolus alterniflorus (Loisel.) P.M.Peterson & Saarela, 2014",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Spartine a feuilles alternes, Sporobole a feuilles alternes",
        "Règne": "Plantae (Plantes)",
        "Famille": "Poaceae (Poacees)",
        "CD_REF": 1015861,
        "Ordre": "Poales (Poales)"
    },
    {
        "CD_REF": 101055,
        "Nom français": "Helianthe tubereux, Topinambour, Patate de Virginie",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Règne": "Plantae (Plantes)",
        "Famille": "Asteraceae (Asteracees)",
        "Ordre": "Asterales (Asterales)",
        "Nom scientifique": "Helianthus tuberosus L., 1753"
    },
    {
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "CD_REF": 69338,
        "Nom scientifique": "Lepomis gibbosus (Linnaeus, 1758)",
        "Nom français": "Perche-soleil, Boer, Calicoba, Perche arc-en-ciel, Perche argentee, Perche doree, Poisson tricolore, Poisson-soleil, Crapet-soleil",
        "Ordre": "Perciformes (Carangues, Daurades, Merous, Mulets, Perches)",
        "Famille": "Centrarchidae",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Nom français": "Chrysomele des racines du mais",
        "CD_REF": 322539,
        "Famille": "Chrysomelidae (Chrysomeles)",
        "Ordre": "Coleoptera (Coleopteres)",
        "Nom scientifique": "Diabrotica virgifera LeConte, 1868",
        "Classe": "Insecta (Insectes)",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Famille": "Caulacanthaceae",
        "CD_REF": 72562,
        "Classe": "Florideophyceae",
        "Nom scientifique": "Caulacanthus ustulatus (Mertens ex Turner) Kutz., 1843",
        "Nom français": "Caulacanthe brulee",
        "Ordre": "Gigartinales",
        "Règne": "Plantae (Plantes)"
    },
    {
        "CD_REF": 86869,
        "Règne": "Plantae (Plantes)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Buddleja davidii Franch., 1887",
        "Nom français": "Buddleia de David, Buddleia du pere David, Arbre-a-papillon, Arbre-aux-papillons",
        "Famille": "Scrophulariaceae (Scrophulariacees)",
        "Ordre": "Lamiales (Lamiales)"
    },
    {
        "Famille": "Poaceae (Poacees)",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Sporobole des Indes, Sporobole fertile, Sporobole tenace",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Ordre": "Poales (Poales)",
        "Nom scientifique": "Sporobolus indicus (L.) R.Br., 1810",
        "CD_REF": 124719
    },
    {
        "Règne": "Plantae (Plantes)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Ordre": "Proteales (Proteales)",
        "CD_REF": 1002319,
        "Nom français": "Hakea decurrent",
        "Nom scientifique": "Hakea decurrens R.Br., 1830",
        "Famille": "Proteaceae (Proteacees)"
    },
    {
        "Classe": "Gastropoda (Gasteropodes)",
        "Règne": "Animalia (Animaux)",
        "Famille": "Muricidae",
        "Nom scientifique": "Ocinebrellus inornatus (Recluz, 1851)",
        "Nom français": "Bigorneau perceur japonais",
        "Ordre": "Neogastropoda",
        "CD_REF": 647081
    },
    {
        "Classe": "Ulvophyceae",
        "CD_REF": 368610,
        "Règne": "Plantae (Plantes)",
        "Famille": "Caulerpaceae",
        "Nom scientifique": "Caulerpa taxifolia (M.Vahl) C.Agardh, 1817",
        "Nom français": "Caulerpe taxifoliee, Caulerpe a feuilles d'If",
        "Ordre": "Bryopsidales"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Famille": "Cerambycidae (Longicornes)",
        "Nom français": "Longicorne tigre (le)",
        "Ordre": "Coleoptera (Coleopteres)",
        "Classe": "Insecta (Insectes)",
        "Nom scientifique": "Xylotrechus chinensis (Chevrolat, 1852)",
        "CD_REF": 911079
    },
    {
        "Classe": "Leotiomycetes",
        "Famille": "Helotiaceae",
        "Nom scientifique": "Hymenoscyphus fraxineus (T.Kowalski) Baral, Queloz & Hosoya, 2014",
        "Règne": "Fungi (Champignons)",
        "Nom français": "Agent de la chalarose du frene",
        "CD_REF": 893794,
        "Ordre": "Helotiales"
    },
    {
        "Classe": "Insecta (Insectes)",
        "CD_REF": 248015,
        "Nom scientifique": "Etiella zinckenella (Treitschke, 1832)",
        "Nom français": "Pyrale du haricot vert",
        "Règne": "Animalia (Animaux)",
        "Famille": "Pyralidae",
        "Ordre": "Lepidoptera (Papillons)"
    },
    {
        "Classe": "Equisetopsida (Equisetopsides)",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Lentille d'eau menue, Lenticule minuscule, Lentille d'eau minuscule",
        "Famille": "Araceae (Aracees)",
        "Nom scientifique": "Lemna minuta Kunth, 1816",
        "Ordre": "Alismatales (Alismatales)",
        "CD_REF": 105433
    },
    {
        "Ordre": "Apiales (Apiales)",
        "Nom scientifique": "Heracleum mantegazzianum Sommier & Levier, 1895",
        "Règne": "Plantae (Plantes)",
        "CD_REF": 101286,
        "Famille": "Apiaceae (Apiacees)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Berce du Caucase, Berce de Mantegazzi"
    },
    {
        "Nom français": "Moule zebree",
        "Nom scientifique": "Dreissena polymorpha polymorpha (Pallas, 1771)",
        "Règne": "Animalia (Animaux)",
        "Ordre": "Myida",
        "Classe": "Bivalvia (Bivalves)",
        "Famille": "Dreissenidae",
        "CD_REF": 163458
    },
    {
        "Règne": "Animalia (Animaux)",
        "Nom français": "Botrylle violet",
        "CD_REF": 647505,
        "Classe": "Ascidiacea (Ascidies)",
        "Ordre": "Stolidobranchia",
        "Famille": "Styelidae",
        "Nom scientifique": "Botrylloides violaceus Oka, 1927"
    },
    {
        "Classe": "Equisetopsida (Equisetopsides)",
        "Ordre": "Ericales (Ericales)",
        "Règne": "Plantae (Plantes)",
        "Nom scientifique": "Rhododendron ponticum L., 1762 [nom. et typ. cons. prop.]",
        "Nom français": "Rhododendron du Pont, Rhododendron des parcs, Rhododendron pontique, Rhododendron de la mer Noire, Rhododendron de Betique",
        "CD_REF": 117692,
        "Famille": "Ericaceae (Ericacees)"
    },
    {
        "Classe": "Sordariomycetes",
        "Règne": "Fungi (Champignons)",
        "Nom français": "Agent du chancre du chataignier, Agent du chancre de l'ecorce, Agent de la brulure du chataignier",
        "Ordre": "Diaporthales",
        "Famille": "Cryphonectriaceae",
        "Nom scientifique": "Cryphonectria parasitica (Murrill) M.E.Barr, 1978",
        "CD_REF": 532884
    },
    {
        "CD_REF": 104805,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Lagarosiphon major (Ridl.) Moss, 1928",
        "Ordre": "Alismatales (Alismatales)",
        "Famille": "Hydrocharitaceae (Hydrocharitacees)",
        "Nom français": "Lagarosiphon majeur",
        "Règne": "Plantae (Plantes)"
    },
    {
        "Règne": "Plantae (Plantes)",
        "Famille": "Poaceae (Poacees)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "CD_REF": 92572,
        "Ordre": "Poales (Poales)",
        "Nom français": "Herbe de la pampa, Herbe des pampas",
        "Nom scientifique": "Cortaderia selloana (Schult. & Schult.f.) Asch. & Graebn., 1900"
    },
    {
        "Ordre": "Alismatales (Alismatales)",
        "Nom scientifique": "Elodea callitrichoides (Rich.) Casp., 1857",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Elodee faux callitriche, Elodee a feuilles allongees",
        "Famille": "Hydrocharitaceae (Hydrocharitacees)",
        "CD_REF": 95979,
        "Règne": "Plantae (Plantes)"
    },
    {
        "Règne": "Plantae (Plantes)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Ailante glanduleux, Faux vernis du Japon, Ailante, Ailanthe",
        "Ordre": "Sapindales (Sapindales)",
        "CD_REF": 80824,
        "Famille": "Simaroubaceae (Simaroubacees)",
        "Nom scientifique": "Ailanthus altissima (Mill.) Swingle, 1916"
    },
    {
        "Nom scientifique": "Lysichiton americanus Hulten & H.St.John, 1931",
        "Nom français": "Lysichiton d'Amerique, Faux arum",
        "Ordre": "Alismatales (Alismatales)",
        "Famille": "Araceae (Aracees)",
        "CD_REF": 610602,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Règne": "Plantae (Plantes)"
    },
    {
        "Classe": "Bivalvia (Bivalves)",
        "Ordre": "Adapedonta",
        "Règne": "Animalia (Animaux)",
        "Famille": "Pharidae",
        "Nom français": "Couteau americain, Couteau etroit",
        "CD_REF": 885584,
        "Nom scientifique": "Ensis leei M. Huber, 2015"
    },
    {
        "CD_REF": 106742,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Ludwigia grandiflora (Michx.) Greuter & Burdet, 1987",
        "Ordre": "Myrtales (Myrtales)",
        "Règne": "Plantae (Plantes)",
        "Famille": "Onagraceae (Onagracees)",
        "Nom français": "Ludwigie a grandes fleurs, Jussie a grandes fleurs, Jussie de l'Uruguay"
    },
    {
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Ludwigia peploides subsp. montevidensis (Spreng.) P.H.Raven, 1964",
        "CD_REF": 612514,
        "Règne": "Plantae (Plantes)",
        "Famille": "Onagraceae (Onagracees)",
        "Ordre": "Myrtales (Myrtales)",
        "Nom français": "Ludwigie de Montevideo, Jussie de Montevideo"
    },
    {
        "Famille": "Austrobalanidae",
        "Règne": "Animalia (Animaux)",
        "Classe": "Hexanauplia",
        "Nom scientifique": "Austrominius modestus (Darwin, 1854)",
        "Ordre": "Sessilia",
        "Nom français": "Balane croix de Malte (La), Balane de Nouvelle-Zelande (La)",
        "CD_REF": 622884
    },
    {
        "Nom français": "Azolle fausse fougere, Azolla fausse fougere, Fougere d'eau, Azolle fausse filicule",
        "Ordre": "Salviniales (Salviniales)",
        "CD_REF": 85469,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Azolla filiculoides Lam., 1783",
        "Règne": "Plantae (Plantes)",
        "Famille": "Salviniaceae (Salviniacees)"
    },
    {
        "Règne": "Chromista (Chromistes, Lignee brune)",
        "CD_REF": 73388,
        "Famille": "Alariaceae",
        "Nom scientifique": "Undaria pinnatifida (Harvey) Suringar, 1873",
        "Nom français": "Wakame",
        "Ordre": "Laminariales",
        "Classe": "Phaeophyceae (Algues brunes, Pheophycees)"
    },
    {
        "CD_REF": 701921,
        "Classe": "Insecta (Insectes)",
        "Nom scientifique": "Drosophila suzukii (Matsumura, 1931)",
        "Nom français": "Drosophile a ailes tachetees, Drosophile du cerisier, Moucheron asiatique, Drosophile asiatique, Drosophile japonaise",
        "Règne": "Animalia (Animaux)",
        "Ordre": "Diptera (Mouches, Moustiques, Cousins)",
        "Famille": "Drosophilidae (Drosophiles)"
    },
    {
        "Règne": "Plantae (Plantes)",
        "Nom français": "Ludwigie a six petales",
        "Ordre": "Myrtales (Myrtales)",
        "Nom scientifique": "Ludwigia grandiflora subsp. hexapetala (Hook. & Arn.) G.L.Nesom & Kartesz, 2000",
        "Classe": "Equisetopsida (Equisetopsides)",
        "CD_REF": 612513,
        "Famille": "Onagraceae (Onagracees)"
    },
    {
        "Nom scientifique": "Myriophyllum aquaticum (Vell.) Verdc., 1973",
        "Ordre": "Saxifragales (Saxifragales)",
        "Famille": "Haloragaceae (Haloragacees)",
        "Règne": "Plantae (Plantes)",
        "CD_REF": 109141,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Myriophylle du Bresil"
    },
    {
        "CD_REF": 162667,
        "Nom scientifique": "Pacifastacus leniusculus (Dana, 1852)",
        "Nom français": "Ecrevisse de Californie (L'), Ecrevisse signal (L'), Ecrevisse du Pacifique (L')",
        "Classe": "Malacostraca (Malacostraces)",
        "Famille": "Astacidae",
        "Ordre": "Decapoda (Decapodes)",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Famille": "Gobiidae",
        "Ordre": "Gobiiformes",
        "Nom français": "Gobie fluviatile",
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 70166,
        "Nom scientifique": "Neogobius fluviatilis (Pallas, 1814)"
    },
    {
        "Règne": "Plantae (Plantes)",
        "Ordre": "Asterales (Asterales)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Symphyotriche lanceole, Aster lanceole",
        "Nom scientifique": "Symphyotrichum lanceolatum (Willd.) G.L.Nesom, 1995",
        "Famille": "Asteraceae (Asteracees)",
        "CD_REF": 125330
    },
    {
        "CD_REF": 117503,
        "Règne": "Plantae (Plantes)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Ordre": "Caryophyllales (Caryophyllales)",
        "Nom français": "Renouee du Japon, Reynoutrie du Japon",
        "Famille": "Polygonaceae (Polygonacees)",
        "Nom scientifique": "Reynoutria japonica Houtt., 1777"
    },
    {
        "Famille": "Mytilicolidae",
        "Classe": "Copepoda (Copepodes)",
        "CD_REF": 375955,
        "Nom français": "Mytilocole oriental (Le)",
        "Ordre": "Cyclopoida",
        "Nom scientifique": "Mytilicola orientalis Mori, 1935",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "Famille": "Fistulariidae",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 424218,
        "Nom scientifique": "Fistularia commersonii Ruppell, 1838",
        "Nom français": "Trompette",
        "Ordre": "Syngnathiformes (Hippocampes)"
    },
    {
        "Nom scientifique": "Hyphantria cunea (Drury, 1773)",
        "CD_REF": 249066,
        "Ordre": "Lepidoptera (Papillons)",
        "Famille": "Erebidae",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Ecaille fileuse (L')",
        "Classe": "Insecta (Insectes)"
    },
    {
        "Nom français": "Bouquet migrateur (Le), Crevette a grandes pinces, Crevette orientale",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 534242,
        "Classe": "Malacostraca (Malacostraces)",
        "Nom scientifique": "Palaemon macrodactylus Rathbun, 1902",
        "Famille": "Palaemonidae",
        "Ordre": "Decapoda (Decapodes)"
    },
    {
        "CD_REF": 95983,
        "Règne": "Plantae (Plantes)",
        "Nom scientifique": "Elodea nuttallii (Planch.) H.St.John, 1920",
        "Ordre": "Alismatales (Alismatales)",
        "Nom français": "Elodee de Nuttall, Elodee a feuilles etroites",
        "Famille": "Hydrocharitaceae (Hydrocharitacees)",
        "Classe": "Equisetopsida (Equisetopsides)"
    },
    {
        "Règne": "Plantae (Plantes)",
        "Ordre": "Sapindales (Sapindales)",
        "Famille": "Sapindaceae (Sapindacees)",
        "Nom français": "Erable negundo, Erable frene, Erable a feuilles de frene, Erable Negondo",
        "CD_REF": 79766,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Acer negundo L., 1753"
    },
    {
        "Nom français": "Crepidule americaine, Pantoufle des mers",
        "Famille": "Calyptraeidae",
        "CD_REF": 62462,
        "Nom scientifique": "Crepidula fornicata (Linnaeus, 1758)",
        "Ordre": "Littorinimorpha",
        "Classe": "Gastropoda (Gasteropodes)",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Nom français": "Tete d'or",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Famille": "Asteraceae (Asteracees)",
        "Ordre": "Asterales (Asterales)",
        "Nom scientifique": "Solidago canadensis L., 1753",
        "Règne": "Plantae (Plantes)",
        "CD_REF": 124164
    },
    {
        "CD_REF": 788821,
        "Famille": "Cyperaceae (Cyperacees)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Eleocharis obtusa (Willd.) Schult., 1824",
        "Ordre": "Poales (Poales)",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Eleocharide obtuse, Eleocharis obtus"
    },
    {
        "Ordre": "Cheilostomatida",
        "Famille": "Bugulidae",
        "Classe": "Gymnolaemata",
        "Règne": "Animalia (Animaux)",
        "Nom scientifique": "Bugula neritina (Linnaeus, 1758)",
        "Nom français": "Bugule brune",
        "CD_REF": 28726
    },
    {
        "Ordre": "Pelecaniformes",
        "Nom français": "Ibis sacre",
        "Famille": "Threskiornithidae (Ibis, Spatules)",
        "Nom scientifique": "Threskiornis aethiopicus (Latham, 1790)",
        "Classe": "Aves (Oiseaux)",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 2687
    },
    {
        "Nom scientifique": "Arcuatula senhousia (Benson, 1842)",
        "Classe": "Bivalvia (Bivalves)",
        "Nom français": "Moule asiatique",
        "Ordre": "Mytilida",
        "Famille": "Mytilidae",
        "CD_REF": 647654,
        "Règne": "Animalia (Animaux)"
    },
    {
        "Famille": "Gobiidae",
        "Nom français": "Gobie demi-lune, Gobie a nez tubulaire",
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "Ordre": "Gobiiformes",
        "CD_REF": 521597,
        "Nom scientifique": "Proterorhinus semilunaris (Heckel, 1837)",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Nom scientifique": "Hydrocotyle ranunculoides L.f., 1782",
        "Règne": "Plantae (Plantes)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Hydrocotyle fausse renoncule, Hydrocotyle a feuilles de Renoncule",
        "Ordre": "Apiales (Apiales)",
        "CD_REF": 103139,
        "Famille": "Araliaceae (Araliacees)"
    },
    {
        "Nom scientifique": "Magallana gigas (Thunberg, 1793)",
        "Règne": "Animalia (Animaux)",
        "Famille": "Ostreidae",
        "Classe": "Bivalvia (Bivalves)",
        "Nom français": "Huitre creuse, Huitre japonaise, Huitre du Pacifique, Huitre portugaise",
        "CD_REF": 853681,
        "Ordre": "Ostreida"
    },
    {
        "Classe": "Florideophyceae",
        "Ordre": "Bonnemaisoniales",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Asparagopsis a crochets, Harpon de Neptune",
        "Famille": "Bonnemaisoniaceae",
        "CD_REF": 72500,
        "Nom scientifique": "Asparagopsis armata Harvey, 1855"
    },
    {
        "Règne": "Plantae (Plantes)",
        "Ordre": "Alismatales (Alismatales)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Egerie dense, Elodee dense",
        "Nom scientifique": "Egeria densa Planch., 1849",
        "Famille": "Hydrocharitaceae (Hydrocharitacees)",
        "CD_REF": 95823
    },
    {
        "Ordre": "Cypriniformes",
        "Nom scientifique": "Cyprinus carpio Linnaeus, 1758",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Carpe commune, Carpat, Carpeau, Escarpo, Kerpaille",
        "Famille": "Cyprinidae (Carpes)",
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "CD_REF": 67058
    },
    {
        "CD_REF": 67415,
        "Nom scientifique": "Pseudorasbora parva (Temminck & Schlegel, 1846)",
        "Nom français": "Pseudorasbora, Goujon asiatique",
        "Famille": "Gobionidae",
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "Ordre": "Cypriniformes",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Nom scientifique": "Tritia neritea (Linnaeus, 1758)",
        "Nom français": "Nasse neritoide, Cyclonasse, Cyclope neritoide",
        "Ordre": "Neogastropoda",
        "Famille": "Nassariidae",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 825649,
        "Classe": "Gastropoda (Gasteropodes)"
    },
    {
        "Nom scientifique": "Oxyura jamaicensis (Gmelin, 1789)",
        "Nom français": "Erismature rousse",
        "Classe": "Aves (Oiseaux)",
        "CD_REF": 2823,
        "Famille": "Anatidae",
        "Ordre": "Anseriformes (Canards, Oies, Cygnes)",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Ordre": "Venerida",
        "Famille": "Cyrenidae",
        "Classe": "Bivalvia (Bivalves)",
        "Règne": "Animalia (Animaux)",
        "Nom scientifique": "Corbicula leana Prime, 1864",
        "Nom français": "Corbicule japonaise",
        "CD_REF": 885309
    },
    {
        "Règne": "Animalia (Animaux)",
        "Nom français": "Ecrevisse de Louisiane (L'), Ecrevisse rouge de Louisiane (L'), Ecrevisse rouge des marais (L')",
        "Nom scientifique": "Procambarus clarkii (Girard, 1852)",
        "Ordre": "Decapoda (Decapodes)",
        "Classe": "Malacostraca (Malacostraces)",
        "CD_REF": 162668,
        "Famille": "Cambaridae"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Classe": "Gymnolaemata",
        "Nom scientifique": "Tricellaria inopinata d'Hondt & Occhipinti Ambrogi, 1985",
        "CD_REF": 378264,
        "Famille": "Candidae",
        "Ordre": "Cheilostomatida",
        "Nom français": "Bryozoaire inopine"
    },
    {
        "Ordre": "Coleoptera (Coleopteres)",
        "CD_REF": 530331,
        "Classe": "Insecta (Insectes)",
        "Nom scientifique": "Anoplophora glabripennis (Motschulsky, 1854)",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Longicorne asiatique",
        "Famille": "Cerambycidae (Longicornes)"
    },
    {
        "Nom français": "Lentille d'eau turionifere, Lenticule turionifere, Lenticule a turion",
        "Règne": "Plantae (Plantes)",
        "Nom scientifique": "Lemna turionifera Landolt, 1975",
        "CD_REF": 105442,
        "Ordre": "Alismatales (Alismatales)",
        "Famille": "Araceae (Aracees)",
        "Classe": "Equisetopsida (Equisetopsides)"
    },
    {
        "Classe": "Equisetopsida (Equisetopsides)",
        "Règne": "Plantae (Plantes)",
        "CD_REF": 124682,
        "Ordre": "Rosales (Rosales)",
        "Nom français": "Spiree de Billiard",
        "Famille": "Rosaceae (Rosacees)",
        "Nom scientifique": "Spiraea x billiardii Herincq, 1857"
    },
    {
        "CD_REF": 150567,
        "Ordre": "Caryophyllales (Caryophyllales)",
        "Famille": "Polygonaceae (Polygonacees)",
        "Nom français": "Renouee du Japon",
        "Nom scientifique": "Reynoutria japonica Houtt., 1777 var. japonica",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Règne": "Plantae (Plantes)"
    },
    {
        "Nom français": "Tigre du laurier (Le)",
        "Nom scientifique": "Stephanitis lauri Rietschel, 2014",
        "Famille": "Tingidae",
        "Ordre": "Hemiptera (Cigales, Cochenilles, Pucerons, Punaises)",
        "CD_REF": 932826,
        "Classe": "Insecta (Insectes)",
        "Règne": "Animalia (Animaux)"
    },
    {
        "CD_REF": 163459,
        "Famille": "Dreissenidae",
        "Classe": "Bivalvia (Bivalves)",
        "Ordre": "Myida",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Moule d'Amerique, Fausse moule brune",
        "Nom scientifique": "Mytilopsis leucophaeata (Conrad, 1831)"
    },
    {
        "Famille": "Geomitridae",
        "Nom scientifique": "Xeropicta derbentina (Krynicki, 1836)",
        "Classe": "Gastropoda (Gasteropodes)",
        "CD_REF": 163293,
        "Ordre": "Stylommatophora",
        "Nom français": "Helicelle des Balkans",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Nom français": "Limnorie du bois (La), Limnorie perforante (La)",
        "Nom scientifique": "Limnoria lignorum (Rathke, 1799)",
        "Famille": "Limnoriidae",
        "Ordre": "Isopoda (Isopodes)",
        "Règne": "Animalia (Animaux)",
        "Classe": "Malacostraca (Malacostraces)",
        "CD_REF": 374346
    },
    {
        "Nom scientifique": "Faxonius limosus (Rafinesque, 1817)",
        "CD_REF": 853999,
        "Famille": "Cambaridae",
        "Ordre": "Decapoda (Decapodes)",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Ecrevisse americaine (L')",
        "Classe": "Malacostraca (Malacostraces)"
    },
    {
        "Ordre": "Decapoda (Decapodes)",
        "Nom français": "Crabe a pinceaux (Le)",
        "CD_REF": 593844,
        "Classe": "Malacostraca (Malacostraces)",
        "Nom scientifique": "Hemigrapsus takanoi Asakura & Watanabe, 2005",
        "Règne": "Animalia (Animaux)",
        "Famille": "Varunidae"
    },
    {
        "Ordre": "Asterales (Asterales)",
        "Règne": "Plantae (Plantes)",
        "Famille": "Asteraceae (Asteracees)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Tete d'or",
        "Nom scientifique": "Solidago gigantea Aiton, 1789",
        "CD_REF": 124168
    },
    {
        "Nom scientifique": "Amphibalanus improvisus (Darwin, 1854)",
        "Famille": "Balanidae",
        "Ordre": "Sessilia",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Balane imprevue (La), Balane bernache (La), Petite balane ivoire (La)",
        "CD_REF": 647695,
        "Classe": "Hexanauplia"
    },
    {
        "Ordre": "Fabales (Fabales)",
        "Règne": "Plantae (Plantes)",
        "Nom scientifique": "Acacia mearnsii De Wild., 1925",
        "Nom français": "Mimosa de Mearns, Mimosa argente, Mimosa vert, Acacia de Mearns",
        "Famille": "Fabaceae (Fabacees)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "CD_REF": 79700
    },
    {
        "Classe": "Equisetopsida (Equisetopsides)",
        "Famille": "Poaceae (Poacees)",
        "CD_REF": 90192,
        "Ordre": "Poales (Poales)",
        "Règne": "Plantae (Plantes)",
        "Nom scientifique": "Ceratochloa cathartica (Vahl) Herter, 1940",
        "Nom français": "Brome cathartique, Ceratochloa cathartique, Brome faux uniola, Brome purgatif"
    },
    {
        "Famille": "Emydidae (Emydides)",
        "CD_REF": 77424,
        "Classe": " -",
        "Nom français": "Trachemyde ecrite, Tortue de Floride",
        "Ordre": "Chelonii (Tortues)",
        "Règne": "Animalia (Animaux)",
        "Nom scientifique": "Trachemys scripta (Schoepff, 1792)"
    },
    {
        "Nom français": "Sargasse Japonaise",
        "Nom scientifique": "Sargassum muticum (Yendo) Fensholt, 1955",
        "Ordre": "Fucales",
        "Famille": "Sargassaceae",
        "CD_REF": 73307,
        "Classe": "Phaeophyceae (Algues brunes, Pheophycees)",
        "Règne": "Chromista (Chromistes, Lignee brune)"
    },
    {
        "Famille": "Fabaceae (Fabacees)",
        "Ordre": "Fabales (Fabales)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Robinia pseudoacacia L., 1753",
        "Nom français": "Robinier faux-acacia, Acacia blanc, Robinier, Robinier faux acacia",
        "CD_REF": 117860,
        "Règne": "Plantae (Plantes)"
    },
    {
        "Nom français": "Bident feuille, Bident a fruits noirs, Bident feuillu",
        "Famille": "Asteraceae (Asteracees)",
        "CD_REF": 85957,
        "Nom scientifique": "Bidens frondosa L., 1753",
        "Règne": "Plantae (Plantes)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Ordre": "Asterales (Asterales)"
    },
    {
        "CD_REF": 347940,
        "Règne": "Animalia (Animaux)",
        "Nom scientifique": "Ballerus sapa (Pallas, 1814)",
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "Nom français": "Breme de Danube",
        "Ordre": "Cypriniformes",
        "Famille": "Leuciscidae"
    },
    {
        "Nom scientifique": "Acrothamnion preissii (Sonder) E.M.Wollaston, 1968",
        "Règne": "Plantae (Plantes)",
        "Famille": "Ceramiaceae",
        "CD_REF": 369355,
        "Classe": "Florideophyceae",
        "Nom français": "Acrochaetium de Preiss",
        "Ordre": "Ceramiales"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Famille": "Pipidae (Pipides)",
        "CD_REF": 79265,
        "Ordre": "Anura (Anoures)",
        "Classe": "Amphibia (Amphibiens, batraciens)",
        "Nom scientifique": "Xenopus laevis (Daudin, 1803)",
        "Nom français": "Xenope lisse (Le)"
    },
    {
        "Nom français": "Ragondin",
        "CD_REF": 61667,
        "Règne": "Animalia (Animaux)",
        "Ordre": "Rodentia (Rongeurs)",
        "Famille": "Echimyidae",
        "Classe": "Mammalia (Mammiferes)",
        "Nom scientifique": "Myocastor coypus (Molina, 1782)"
    },
    {
        "Nom scientifique": "Grateloupia turuturu Yamada, 1941",
        "Classe": "Florideophyceae",
        "Nom français": "Grateloupe du Pacifique",
        "Famille": "Halymeniaceae",
        "Règne": "Plantae (Plantes)",
        "Ordre": "Halymeniales",
        "CD_REF": 627497
    },
    {
        "Ordre": "Ericales (Ericales)",
        "CD_REF": 103547,
        "Famille": "Balsaminaceae (Balsaminacees)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Impatiente glanduleuse, Balsamine de l'Himalaya, Balsamine geante, Balsamine rouge",
        "Nom scientifique": "Impatiens glandulifera Royle, 1833"
    },
    {
        "Famille": "Bonnemaisoniaceae",
        "Ordre": "Bonnemaisoniales",
        "Nom scientifique": "Bonnemaisonia hamifera Har., 1891",
        "CD_REF": 72534,
        "Règne": "Plantae (Plantes)",
        "Nom français": "Porte-crochets, Plume epineuse rouge",
        "Classe": "Florideophyceae"
    },
    {
        "Nom français": "Crabe plat des oursins, Sally-pied-leger",
        "Nom scientifique": "Percnon gibbesi (H. Milne Edwards, 1853)",
        "Règne": "Animalia (Animaux)",
        "Famille": "Percnidae",
        "CD_REF": 350426,
        "Classe": "Malacostraca (Malacostraces)",
        "Ordre": "Decapoda (Decapodes)"
    },
    {
        "Ordre": "Asterales (Asterales)",
        "Famille": "Asteraceae (Asteracees)",
        "CD_REF": 84057,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Artemisia verlotiorum Lamotte, 1877",
        "Nom français": "Armoise, Herbe chinois, Marie-Therese",
        "Règne": "Plantae (Plantes)"
    },
    {
        "CD_REF": 28806,
        "Nom scientifique": "Schizoporella unicornis (Johnston in Wood, 1844)",
        "Classe": "Gymnolaemata",
        "Famille": "Schizoporellidae",
        "Règne": "Animalia (Animaux)",
        "Ordre": "Cheilostomatida",
        "Nom français": "Schizoporelle unicorne"
    },
    {
        "Classe": "Amphibia (Amphibiens, batraciens)",
        "Nom français": "Xenope lisse (Le)",
        "Règne": "Animalia (Animaux)",
        "Ordre": "Anura (Anoures)",
        "Famille": "Pipidae (Pipides)",
        "CD_REF": 699626,
        "Nom scientifique": "Xenopus laevis laevis (Daudin, 1803)"
    },
    {
        "Nom scientifique": "Wasmannia auropunctata (Roger, 1863)",
        "Classe": "Insecta (Insectes)",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 532927,
        "Nom français": "Petite fourmi de feu, Fourmi rouge",
        "Famille": "Formicidae (Fourmis)",
        "Ordre": "Hymenoptera (Hymenopteres)"
    },
    {
        "Famille": "Fabaceae (Fabacees)",
        "Nom scientifique": "Amorpha fruticosa L., 1753",
        "Ordre": "Fabales (Fabales)",
        "Nom français": "Amorphe arbustive, Indigo du Bush, Amorphe buissonnante, Faux indigo",
        "Règne": "Plantae (Plantes)",
        "CD_REF": 82164,
        "Classe": "Equisetopsida (Equisetopsides)"
    },
    {
        "Classe": "Insecta (Insectes)",
        "Famille": "Tingidae",
        "CD_REF": 237413,
        "Règne": "Animalia (Animaux)",
        "Nom français": "Tigre du platane",
        "Nom scientifique": "Corythucha ciliata (Say, 1832)",
        "Ordre": "Hemiptera (Cigales, Cochenilles, Pucerons, Punaises)"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Nom scientifique": "Xenostrobus securis (Lamarck, 1819)",
        "Ordre": "Mytilida",
        "Famille": "Mytilidae",
        "Classe": "Bivalvia (Bivalves)",
        "CD_REF": 369759,
        "Nom français": "Moule pygmee, Moule d'Australie"
    },
    {
        "Nom scientifique": "Dasysiphonia japonica (Yendo) H.-S.Kim, 2012",
        "CD_REF": 813752,
        "Ordre": "Ceramiales",
        "Famille": "Dasyaceae",
        "Classe": "Florideophyceae",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Heterosiphonie du Japon"
    },
    {
        "Nom scientifique": "Harmonia axyridis (Pallas, 1773)",
        "CD_REF": 459325,
        "Ordre": "Coleoptera (Coleopteres)",
        "Classe": "Insecta (Insectes)",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Coccinelle asiatique (la), Coccinelle arlequin (La)",
        "Famille": "Coccinellidae (Coccinelles)"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Classe": "Insecta (Insectes)",
        "Nom français": "Cochenille du pin maritime",
        "Nom scientifique": "Matsucoccus feytaudi Ducasse, 1941",
        "CD_REF": 221738,
        "Ordre": "Hemiptera (Cigales, Cochenilles, Pucerons, Punaises)",
        "Famille": "Margarodidae"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Famille": "Vespidae",
        "Ordre": "Hymenoptera (Hymenopteres)",
        "Classe": "Insecta (Insectes)",
        "CD_REF": 433589,
        "Nom scientifique": "Vespa velutina Lepeletier, 1836",
        "Nom français": "Frelon a pattes jaunes, Frelon asiatique, Vespa veloutee"
    },
    {
        "Classe": "Florideophyceae",
        "CD_REF": 370833,
        "Famille": "Rhodomelaceae",
        "Nom français": "Vomersleyelle setacee",
        "Ordre": "Ceramiales",
        "Nom scientifique": "Womersleyella setacea (Hollenb.) R.E. Norris, 1992",
        "Règne": "Plantae (Plantes)"
    },
    {
        "Classe": "Ascidiacea (Ascidies)",
        "Famille": "Corellidae",
        "Nom scientifique": "Corella eumyota Traustedt, 1882",
        "Nom français": "Ascidie cartilagineuse",
        "Ordre": "Phlebobranchia",
        "CD_REF": 647530,
        "Règne": "Animalia (Animaux)"
    },
    {
        "Nom français": "Hydrocampe de Perieres",
        "Classe": "Insecta (Insectes)",
        "Nom scientifique": "Diplopseustis perieresalis (Walker, 1859)",
        "Famille": "Crambidae",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 779482,
        "Ordre": "Lepidoptera (Papillons)"
    },
    {
        "Nom français": "Hybride entre  le Carpobrote a feuilles en sabre et le Carpobrote doux",
        "Nom scientifique": "Carpobrotus acinaciformis x Carpobrotus edulis",
        "Ordre": "Caryophyllales (Caryophyllales)",
        "Famille": "Aizoaceae (Aizoacees)",
        "CD_REF": 610840,
        "Règne": "Plantae (Plantes)",
        "Classe": "Equisetopsida (Equisetopsides)"
    },
    {
        "CD_REF": 219796,
        "Ordre": "Lepidoptera (Papillons)",
        "Famille": "Lycaenidae (Lycenes)",
        "Classe": "Insecta (Insectes)",
        "Nom français": "Brun du pelargonium (Le), Argus des Pelargoniums (L')",
        "Règne": "Animalia (Animaux)",
        "Nom scientifique": "Cacyreus marshalli Butler, 1898"
    },
    {
        "Règne": "Animalia (Animaux)",
        "CD_REF": 70155,
        "Nom français": "Gobie a tache noire",
        "Famille": "Gobiidae",
        "Ordre": "Gobiiformes",
        "Nom scientifique": "Neogobius melanostomus (Pallas, 1814)",
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)"
    },
    {
        "Nom scientifique": "Mytilicola intestinalis Steuer, 1902",
        "Règne": "Animalia (Animaux)",
        "Classe": "Copepoda (Copepodes)",
        "Famille": "Mytilicolidae",
        "Nom français": "Copepode rouge, Cop rouge",
        "CD_REF": 375954,
        "Ordre": "Cyclopoida"
    },
    {
        "Ordre": "Bryopsidales",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Codium fragile, Algue chou-fleur, Codium orvet",
        "Nom scientifique": "Codium fragile (Suringar) Har., 1889",
        "Famille": "Codiaceae",
        "Classe": "Ulvophyceae",
        "CD_REF": 72608
    },
    {
        "Ordre": "Fabales (Fabales)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom scientifique": "Acacia saligna (Labill.) H.L.Wendl., 1820",
        "Famille": "Fabaceae (Fabacees)",
        "Règne": "Plantae (Plantes)",
        "CD_REF": 79710,
        "Nom français": "Mimosa a feuilles de Saule, Mimosa a feuilles bleues"
    },
    {
        "Ordre": "Asterales (Asterales)",
        "Famille": "Asteraceae (Asteracees)",
        "CD_REF": 82080,
        "Nom français": "Ambroisie a feuilles d'armoise, Ambroise elevee, Ambroisie annuelle",
        "Règne": "Plantae (Plantes)",
        "Nom scientifique": "Ambrosia artemisiifolia L., 1753",
        "Classe": "Equisetopsida (Equisetopsides)"
    },
    {
        "Nom français": "Halophile stipulee",
        "Famille": "Hydrocharitaceae (Hydrocharitacees)",
        "Ordre": "Alismatales (Alismatales)",
        "Règne": "Plantae (Plantes)",
        "Classe": "Equisetopsida (Equisetopsides)",
        "CD_REF": 368620,
        "Nom scientifique": "Halophila stipulacea (Forssk.) Asch., 1867"
    },
    {
        "Règne": "Animalia (Animaux)",
        "CD_REF": 845923,
        "Nom français": "Aleurode du citronnier",
        "Famille": "Aleyrodidae",
        "Classe": "Insecta (Insectes)",
        "Ordre": "Hemiptera (Cigales, Cochenilles, Pucerons, Punaises)",
        "Nom scientifique": "Aleurocanthus spiniferus (Quaintance, 1903)"
    },
    {
        "Famille": "Cionidae",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Ascidie jaune, Cione, Cione instestinale",
        "Ordre": "Phlebobranchia",
        "Classe": "Ascidiacea (Ascidies)",
        "CD_REF": 6912,
        "Nom scientifique": "Ciona intestinalis (Linnaeus, 1767)"
    },
    {
        "Nom scientifique": "Elodea canadensis Michx., 1803",
        "Classe": "Equisetopsida (Equisetopsides)",
        "CD_REF": 95980,
        "Ordre": "Alismatales (Alismatales)",
        "Famille": "Hydrocharitaceae (Hydrocharitacees)",
        "Règne": "Plantae (Plantes)",
        "Nom français": "Elodee du Canada"
    },
    {
        "Famille": "Ictaluridae",
        "Nom français": "Poisson-chat",
        "Ordre": "Siluriformes (Poissons-chats)",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 67571,
        "Nom scientifique": "Ameiurus melas (Rafinesque, 1820)",
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)"
    },
    {
        "Nom scientifique": "Halyomorpha halys (St?l, 1855)",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Punaise diabolique",
        "Classe": "Insecta (Insectes)",
        "Famille": "Pentatomidae",
        "CD_REF": 720069,
        "Ordre": "Hemiptera (Cigales, Cochenilles, Pucerons, Punaises)"
    },
    {
        "Ordre": "Rodentia (Rongeurs)",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 61174,
        "Nom français": "Ecureuil a ventre rouge",
        "Classe": "Mammalia (Mammiferes)",
        "Nom scientifique": "Callosciurus erythraeus (Pallas, 1779)",
        "Famille": "Sciuridae (Ecureuils, Marmottes)"
    },
    {
        "Nom français": "Chrysomele des racines du mais",
        "CD_REF": 322540,
        "Nom scientifique": "Diabrotica virgifera virgifera LeConte, 1868",
        "Règne": "Animalia (Animaux)",
        "Famille": "Chrysomelidae (Chrysomeles)",
        "Classe": "Insecta (Insectes)",
        "Ordre": "Coleoptera (Coleopteres)"
    },
    {
        "Nom français": "Hydrobie des antipodes",
        "Ordre": "Littorinimorpha",
        "Famille": "Tateidae",
        "Règne": "Animalia (Animaux)",
        "Classe": "Gastropoda (Gasteropodes)",
        "CD_REF": 62131,
        "Nom scientifique": "Potamopyrgus antipodarum (J.E. Gray, 1843)"
    },
    {
        "Règne": "Plantae (Plantes)",
        "Famille": "Asteraceae (Asteracees)",
        "CD_REF": 122630,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Ordre": "Asterales (Asterales)",
        "Nom scientifique": "Senecio inaequidens DC., 1838",
        "Nom français": "Senecon du Cap, Senecon a dents inegales, Senecon sud-africain, Senecon a feuilles etroites, Senecon d'Harvey"
    },
    {
        "Ordre": "Venerida",
        "Règne": "Animalia (Animaux)",
        "Famille": "Cyrenidae",
        "CD_REF": 163433,
        "Nom français": "Corbicule asiatique",
        "Nom scientifique": "Corbicula fluminea (O.F. Muller, 1774)",
        "Classe": "Bivalvia (Bivalves)"
    },
    {
        "CD_REF": 454932,
        "Classe": "Equisetopsida (Equisetopsides)",
        "Nom français": "Jacinthe d'eau, Eichhornie a pied epais, Pontederie a pied epais",
        "Ordre": "Commelinales (Commelinales)",
        "Règne": "Plantae (Plantes)",
        "Nom scientifique": "Pontederia crassipes Mart., 1823",
        "Famille": "Pontederiaceae (Pontederiacees)"
    },
    {
        "Famille": "Sciuridae (Ecureuils, Marmottes)",
        "Ordre": "Rodentia (Rongeurs)",
        "Classe": "Mammalia (Mammiferes)",
        "Nom scientifique": "Tamias sibiricus (Laxmann, 1769)",
        "Règne": "Animalia (Animaux)",
        "Nom français": "Tamia de Siberie, Ecureuil de Coree, Rat de Coree, Ecureuil japonais",
        "CD_REF": 61204
    },
    {
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "Règne": "Animalia (Animaux)",
        "Ordre": "Gobiiformes",
        "Famille": "Gobiidae",
        "Nom scientifique": "Ponticola kessleri (Gunther, 1861)",
        "Nom français": "Gobie de Kessler",
        "CD_REF": 70175
    },
    {
        "Nom français": "Corbicule striolee",
        "Ordre": "Venerida",
        "Nom scientifique": "Corbicula fluminalis (O.F. Muller, 1774)",
        "Classe": "Bivalvia (Bivalves)",
        "CD_REF": 64641,
        "Règne": "Animalia (Animaux)",
        "Famille": "Cyrenidae"
    },
    {
        "Nom français": "Palourde japonaise, Palourde croisee japonaise",
        "Famille": "Veneridae",
        "Nom scientifique": "Ruditapes philippinarum (Adams & Reeve, 1850)",
        "Règne": "Animalia (Animaux)",
        "Classe": "Bivalvia (Bivalves)",
        "CD_REF": 444457,
        "Ordre": "Venerida"
    },
    {
        "CD_REF": 897029,
        "Nom scientifique": "Aproceros leucopoda Takeuchi, 1939",
        "Ordre": "Hymenoptera (Hymenopteres)",
        "Famille": "Argidae",
        "Classe": "Insecta (Insectes)",
        "Nom français": "Tenthrede zigzag de l?Orme",
        "Règne": "Animalia (Animaux)"
    },
    {
        "Classe": "Bivalvia (Bivalves)",
        "Famille": "Dreissenidae",
        "Nom français": "Moule zebree",
        "Ordre": "Myida",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 64629,
        "Nom scientifique": "Dreissena polymorpha (Pallas, 1771)"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Nom scientifique": "Anguillicola crassus Kuwahara, Niimi & Itagaki, 1974",
        "CD_REF": 238725,
        "Famille": "Anguillicolidae",
        "Classe": "Chromadorea",
        "Ordre": "Rhabditida",
        "Nom français": "Parasite de l'anguille"
    },
    {
        "Famille": "Tephritidae",
        "Nom français": "Mouche mediterraneenne des fruits, Mouche mediterraneenne, Mouche des fruits, Mouche de l'oranger",
        "Règne": "Animalia (Animaux)",
        "Nom scientifique": "Ceratitis capitata (Wiedemann, 1824)",
        "Ordre": "Diptera (Mouches, Moustiques, Cousins)",
        "Classe": "Insecta (Insectes)",
        "CD_REF": 24102
    },
    {
        "Classe": "Actinopterygii (Actinopterygiens, Poissons actinopterygiens)",
        "Règne": "Animalia (Animaux)",
        "CD_REF": 69372,
        "Famille": "Percidae",
        "Nom scientifique": "Sander lucioperca (Linnaeus, 1758)",
        "Nom français": "Sandre, Perche-brochet",
        "Ordre": "Perciformes (Carangues, Daurades, Merous, Mulets, Perches)"
    },
    {
        "Règne": "Animalia (Animaux)",
        "Famille": "Tingidae",
        "CD_REF": 932824,
        "Classe": "Insecta (Insectes)",
        "Ordre": "Hemiptera (Cigales, Cochenilles, Pucerons, Punaises)",
        "Nom français": "Punaise reticulee du chene (La)",
        "Nom scientifique": "Corythucha arcuata (Say, 1832)"
    },
    {
        "Ordre": "Decapoda (Decapodes)",
        "Règne": "Animalia (Animaux)",
        "Classe": "Malacostraca (Malacostraces)",
        "Nom scientifique": "Rhithropanopeus harrisii (Gould, 1841)",
        "Nom français": "Crabe de boue (Le), Xanthe, Crabe du Zuiderzee (Le), Crabe de vase (Le)",
        "CD_REF": 17658,
        "Famille": "Panopeidae"
    },
    {
        "Nom français": "Renoncule a petites fleurs",
        "CD_NOM": 718400,
        "Nom scientifique": "Ranunculus parviflorus L.",
        "Nom valide": "Ranunculus parviflorus L., 1758 subsp. parviflorus"
    },
    {
        "CD_NOM": 137521,
        "Nom français": "Luzule des bois",
        "Nom scientifique": "Luzula sylvatica (Hudson) Gaudin",
        "Nom valide": "Luzula sylvatica subsp. sieberi (Tausch) K.Richt., 1890"
    },
    {
        "Nom scientifique": "Oreopteris limbosperma (All.) Holub",
        "Nom valide": "Oreopteris limbosperma (All.) Holub, 1969",
        "Nom français": "Fougere des montagnes",
        "CD_NOM": 111239
    },
    {
        "CD_NOM": 112601,
        "Nom français": "Pediculaire des bois",
        "Nom valide": "Pedicularis sylvatica L., 1753",
        "Nom scientifique": "Pedicularis sylvatica L."
    },
    {
        "Nom français": "Cephalanthere rouge",
        "CD_NOM": 89928,
        "Nom valide": "Cephalanthera rubra (L.) Rich., 1817",
        "Nom scientifique": "Cephalanthera rubra (L.) L.C.M. Richard"
    },
    {
        "Nom scientifique": "Potentilla supina L.",
        "CD_NOM": 115669,
        "Nom valide": "Potentilla supina L., 1753",
        "Nom français": "Potentille couchee"
    },
    {
        "CD_NOM": 718249,
        "Nom scientifique": "Melica ciliata L.",
        "Nom valide": "Melica ciliata nothosubsp. thuringiaca (Rauschert) B.Bock, 2012",
        "Nom français": "Melique ciliee"
    },
    {
        "Nom valide": "Illecebrum verticillatum L., 1753",
        "Nom français": "Illecebre verticille",
        "CD_NOM": 103536,
        "Nom scientifique": "Illecebrum verticillatum L."
    },
    {
        "Nom scientifique": "Salix repens L.",
        "Nom valide": "Salix repens L., 1753 var. repens",
        "Nom français": "Saule rampant",
        "CD_NOM": 151113
    },
    {
        "Nom scientifique": "Lathyrus niger (L.) Bernh.",
        "Nom valide": "Lathyrus niger (L.) Bernh., 1800",
        "CD_NOM": 105230,
        "Nom français": "Gesse noire"
    },
    {
        "Nom scientifique": "Carum verticillatum (L.) Koch",
        "Nom français": "Carvi verticille",
        "Nom valide": "Trocdaris verticillatum (L.) Raf., 1840",
        "CD_NOM": 127864
    },
    {
        "Nom français": "Myriophylle a fleurs alternes",
        "Nom valide": "Myriophyllum alterniflorum DC., 1815",
        "Nom scientifique": "Myriophyllum alterniflorum DC.",
        "CD_NOM": 109139
    },
    {
        "CD_NOM": 91727,
        "Nom français": "Helientheme en ombelle",
        "Nom valide": "Cistus umbellatus L., 1753",
        "Nom scientifique": "Halimium umbellatum (L.) Spach"
    },
    {
        "Nom français": "Melique ciliee",
        "CD_NOM": 107851,
        "Nom valide": "Melica ciliata L., 1753",
        "Nom scientifique": "Melica ciliata L."
    },
    {
        "Nom français": "Laiche lisse",
        "CD_NOM": 88608,
        "Nom valide": "Carex laevigata Sm., 1800",
        "Nom scientifique": "Carex laevigata Sm."
    },
    {
        "Nom scientifique": "Pedicularis palustris L.",
        "CD_NOM": 112590,
        "Nom valide": "Pedicularis palustris L., 1753",
        "Nom français": "Pediculaire des marais"
    },
    {
        "Nom scientifique": "Scirpus cespitosus L.",
        "CD_NOM": 141824,
        "Nom français": "Scirpe cespiteux",
        "Nom valide": "Trichophorum cespitosum (L.) Hartm., 1849 subsp. cespitosum"
    },
    {
        "CD_NOM": 101315,
        "Nom français": "Orchis musc",
        "Nom scientifique": "Herminium monorchis (L.) R. Br.",
        "Nom valide": "Herminium monorchis (L.) R.Br., 1813"
    },
    {
        "Nom valide": "Trinia glauca (L.) Dumort., 1827 var. glauca",
        "Nom français": "Trinie commune",
        "Nom scientifique": "Trinia glauca (L.) Dumort.",
        "CD_NOM": 613127
    },
    {
        "CD_NOM": 88656,
        "Nom valide": "Carex mairei Coss. & Germ., 1840",
        "Nom scientifique": "Carex mairii Coss. et Germ.",
        "Nom français": "Laiche de Maire"
    },
    {
        "Nom valide": "Rumex scutatus L., 1753",
        "CD_NOM": 119587,
        "Nom français": "Oseille ronde",
        "Nom scientifique": "Rumex scutatus L."
    },
    {
        "Nom valide": "Comarum palustre L., 1753",
        "CD_NOM": 92217,
        "Nom scientifique": "Potentilla palustris (L.) Scop.",
        "Nom français": "Comaret"
    },
    {
        "Nom français": "Alyssum de montagne",
        "Nom scientifique": "Alyssum montanum L.",
        "Nom valide": "Alyssum montanum L., 1753 [nom. et typ. cons.]",
        "CD_NOM": 81923
    },
    {
        "Nom français": "Gesse des marais",
        "CD_NOM": 137139,
        "Nom valide": "Lathyrus palustris L., 1753 subsp. palustris",
        "Nom scientifique": "Lathyrus palustris L."
    },
    {
        "Nom scientifique": "Peucedanum cervaria Lapeyr.",
        "CD_NOM": 90278,
        "Nom valide": "Cervaria rivini Gaertn., 1788",
        "Nom français": "Herbe aux cerfs"
    },
    {
        "Nom valide": "Aconitum napellus subsp. lusitanicum Rouy, 1884",
        "CD_NOM": 130787,
        "Nom français": "Aconit casque de Jupiter",
        "Nom scientifique": "Aconitum napellus (L.) ssp. neomontanum (Wulfen) Gayer"
    },
    {
        "CD_NOM": 126276,
        "Nom scientifique": "Thelypteris palustris Schott",
        "Nom valide": "Thelypteris palustris Schott, 1834",
        "Nom français": "Fougere des marais"
    },
    {
        "Nom scientifique": "Draba muralis L.",
        "CD_NOM": 95337,
        "Nom valide": "Draba muralis L., 1753",
        "Nom français": "Drave des murailles"
    },
    {
        "Nom valide": "Arenaria grandiflora L., 1759",
        "CD_NOM": 83584,
        "Nom scientifique": "Arenaria grandiflora L.",
        "Nom français": "Sabline a grandes fleurs"
    },
    {
        "CD_NOM": 105239,
        "Nom français": "Gesse des marais",
        "Nom valide": "Lathyrus palustris L., 1753",
        "Nom scientifique": "Lathyrus palustris L."
    },
    {
        "CD_NOM": 105400,
        "Nom valide": "Leersia oryzoides (L.) Sw., 1788",
        "Nom scientifique": "Leersia oryzoides (L.) Swartz",
        "Nom français": "Faux riz"
    },
    {
        "Nom valide": "Cardamine impatiens L., 1753",
        "Nom français": "Cardamine impatiens",
        "Nom scientifique": "Cardamine impatiens L.",
        "CD_NOM": 87933
    },
    {
        "Nom français": "Senecon a feuilles spatulees",
        "Nom scientifique": "Senecio helenitis (L.) Schinz et Thell.",
        "CD_NOM": 141544,
        "Nom valide": "Tephroseris helenitis subsp. macrochaeta (Willk.) B.Nord., 1978"
    },
    {
        "CD_NOM": 762178,
        "Nom français": "Sagine noueuse",
        "Nom valide": "Sagina nodosa (L.) Fenzl, 1833 subsp. nodosa",
        "Nom scientifique": "Sagina nodosa (L.) Fenzl."
    },
    {
        "CD_NOM": 138637,
        "Nom valide": "Pedicularis sylvatica L., 1753 subsp. sylvatica",
        "Nom scientifique": "Pedicularis sylvatica L.",
        "Nom français": "Pediculaire des bois"
    },
    {
        "CD_NOM": 117732,
        "Nom français": "Rhynchospore brun",
        "Nom valide": "Rhynchospora fusca (L.) W.T.Aiton, 1810",
        "Nom scientifique": "Rhynchospora fusca (L.) Ait. fil."
    },
    {
        "CD_NOM": 97699,
        "Nom valide": "Euphorbia verrucosa L., 1753",
        "Nom français": "Euphorbe verruqueuse",
        "Nom scientifique": "Euphorbia brittingeri Opiz ex Samp."
    },
    {
        "Nom scientifique": "Trinia glauca (L.) Dumort.",
        "Nom valide": "Trinia glauca (L.) Dumort., 1827 subsp. glauca",
        "Nom français": "Trinie commune",
        "CD_NOM": 141936
    },
    {
        "Nom valide": "Carex depauperata Curtis ex With., 1787",
        "Nom scientifique": "Carex depauperata Curtis ex With.",
        "CD_NOM": 88465,
        "Nom français": "Laiche appauvrie"
    },
    {
        "Nom français": "Coeloglosse vert",
        "CD_NOM": 92097,
        "Nom valide": "Coeloglossum viride (L.) Hartm., 1820",
        "Nom scientifique": "Coeloglossum viride (L.) Hartman"
    },
    {
        "Nom scientifique": "Erica scoparia L.",
        "Nom valide": "Erica scoparia L., 1753",
        "CD_NOM": 96691,
        "Nom français": "Bruyere a balais"
    },
    {
        "Nom valide": "Melica ciliata subsp. magnolii (Godr. & Gren.) K.Richt., 1890",
        "Nom français": "Melique ciliee",
        "Nom scientifique": "Melica ciliata L.",
        "CD_NOM": 137723
    },
    {
        "CD_NOM": 612543,
        "Nom valide": "Phelipanche purpurea (Jacq.) Sojak, 1972 subsp. purpurea",
        "Nom scientifique": "Orobanche purpurea Jacq.",
        "Nom français": "Orobanche pourpree"
    },
    {
        "Nom scientifique": "Allium angulosum L.",
        "CD_NOM": 81316,
        "Nom valide": "Allium angulosum L., 1753",
        "Nom français": "Ail anguleux"
    },
    {
        "CD_NOM": 96698,
        "Nom valide": "Erica vagans L., 1770 [nom. et typ. cons.]",
        "Nom scientifique": "Erica vagans L.",
        "Nom français": "Bruyere vagabonde"
    },
    {
        "CD_NOM": 125894,
        "Nom valide": "Tephroseris helenitis (L.) B.Nord., 1978",
        "Nom français": "Senecon a feuilles spatulees",
        "Nom scientifique": "Senecio helenitis (L.) Schinz et Thell."
    },
    {
        "Nom scientifique": "Senecio helenitis (L.) Schinz et Thell.",
        "Nom valide": "Tephroseris helenitis subsp. candida (Corb.) B.Nord., 1978",
        "Nom français": "Senecon a feuilles spatulees",
        "CD_NOM": 141542
    },
    {
        "Nom valide": "Cytisus hirsutus L., 1753",
        "CD_NOM": 94123,
        "Nom scientifique": "Chamaecytisus supinus (L.) Link",
        "Nom français": "Cytise couche"
    },
    {
        "Nom valide": "Carex diandra Schrank, 1781",
        "Nom scientifique": "Carex diandra Schrank",
        "Nom français": "Laiche arrondie",
        "CD_NOM": 88468
    },
    {
        "CD_NOM": 81376,
        "Nom français": "Ail jaune",
        "Nom scientifique": "Allium flavum L.",
        "Nom valide": "Allium flavum L., 1753"
    },
    {
        "Nom valide": "Erica scoparia L., 1753 subsp. scoparia",
        "Nom scientifique": "Erica scoparia L.",
        "Nom français": "Bruyere a balais",
        "CD_NOM": 134198
    },
    {
        "Nom valide": "Carex elongata L., 1753",
        "Nom scientifique": "Carex elongata L.",
        "Nom français": "Laiche allongee",
        "CD_NOM": 88493
    },
    {
        "Nom valide": "Cuscuta europaea L., 1753",
        "Nom français": "Grande cuscute",
        "CD_NOM": 93623,
        "Nom scientifique": "Cuscuta europaea L."
    },
    {
        "Nom français": "Laser blanc",
        "Nom scientifique": "Laserpitium latifolium L. var. asperum",
        "Nom valide": "Laserpitium latifolium L., 1753 subsp. latifolium",
        "CD_NOM": 718371
    },
    {
        "Nom scientifique": "Salix repens L.",
        "Nom valide": "Salix repens L., 1753",
        "CD_NOM": 120192,
        "Nom français": "Saule rampant"
    },
    {
        "Nom scientifique": "Pedicularis palustris L.",
        "Nom français": "Pediculaire des marais",
        "Nom valide": "Pedicularis palustris L., 1753 subsp. palustris",
        "CD_NOM": 138634
    },
    {
        "Nom scientifique": "Asplenium septentrionale (L.) Hoffm.",
        "CD_NOM": 131846,
        "Nom valide": "Asplenium septentrionale (L.) Hoffm., 1796 subsp. septentrionale",
        "Nom français": "Doradille du Nord"
    },
    {
        "Nom valide": "Luzula sylvatica (Huds.) Gaudin, 1811",
        "Nom français": "Luzule des bois",
        "CD_NOM": 106863,
        "Nom scientifique": "Luzula sylvatica (Hudson) Gaudin"
    },
    {
        "Nom valide": "Ranunculus polyanthemoides Boreau, 1857",
        "Nom scientifique": "Ranunculus polyanthemos L. ssp. polyanthemoides (Boreau) Ahlfvengren",
        "Nom français": "Renoncule a segments etroits",
        "CD_NOM": 117175
    },
    {
        "Nom scientifique": "Eriophorum vaginatum L.",
        "Nom valide": "Eriophorum vaginatum L., 1753",
        "CD_NOM": 96861,
        "Nom français": "Linaigrette vaginee"
    },
    {
        "Nom valide": "Aegonychon purpurocaeruleum (L.) Holub, 1973",
        "CD_NOM": 80317,
        "Nom français": "Gremil bleu-pourpre",
        "Nom scientifique": "Buglossoides purpurocaerulea (L.) I.M. Johnston"
    },
    {
        "Nom français": "Lobelie brulante",
        "CD_NOM": 106435,
        "Nom valide": "Lobelia urens L., 1753",
        "Nom scientifique": "Lobelia urens L."
    },
    {
        "Nom scientifique": "Anthericum liliago L.",
        "Nom valide": "Anthericum liliago L., 1753",
        "CD_NOM": 82903,
        "Nom français": "Phalangere a fleurs de lis"
    },
    {
        "Nom scientifique": "Helleborus viridis L. ssp. occidentalis (Reuter) Schiffner",
        "Nom français": "Hellebore vert",
        "Nom valide": "Helleborus viridis L., 1753",
        "CD_NOM": 101202
    },
    {
        "Nom valide": "Isolepis fluitans (L.) R.Br., 1810",
        "Nom scientifique": "Scirpus fluitans L.",
        "CD_NOM": 103862,
        "Nom français": "Scirpe flottant"
    },
    {
        "Nom français": "Stipe pennee",
        "Nom valide": "Stipa pennata L., 1753",
        "CD_NOM": 125173,
        "Nom scientifique": "Stipa pennata L."
    },
    {
        "Nom scientifique": "Carex curta Good.",
        "Nom valide": "Carex canescens L., 1753 subsp. canescens",
        "Nom français": "Laiche blanchatre",
        "CD_NOM": 762143
    },
    {
        "Nom scientifique": "Amelanchier ovalis Medicus",
        "CD_NOM": 82103,
        "Nom valide": "Amelanchier ovalis Medik., 1793",
        "Nom français": "Amelanchier"
    },
    {
        "Nom valide": "Falcaria vulgaris Bernh., 1800",
        "Nom français": "Falcaire",
        "CD_NOM": 97956,
        "Nom scientifique": "Falcaria vulgaris Bernh."
    },
    {
        "Nom français": "Bruyere ciliee",
        "Nom scientifique": "Erica ciliaris L.",
        "CD_NOM": 96665,
        "Nom valide": "Erica ciliaris L., 1753"
    },
    {
        "Nom valide": "Poa palustris L., 1759",
        "Nom scientifique": "Poa palustris L.",
        "CD_NOM": 114312,
        "Nom français": "Paturin des marais"
    },
    {
        "Nom valide": "Cystopteris fragilis subsp. huteri (Hausm. ex Milde) Grin?. & I.Grin?., 1952",
        "CD_NOM": 133623,
        "Nom scientifique": "Cystopteris fragilis (L.) Bernh.",
        "Nom français": "Cystopteris"
    },
    {
        "CD_NOM": 83756,
        "Nom français": "Canche des marais",
        "Nom valide": "Aristavena setacea (Huds.) F.Albers & Butzin, 1977",
        "Nom scientifique": "Deschampsia setacea (Hudson) Hackel"
    },
    {
        "Nom valide": "Cytisus decumbens (Durande) Spach, 1845",
        "Nom français": "Cytise pedoncule",
        "CD_NOM": 94111,
        "Nom scientifique": "Cytisus decumbens (Durande) Spach."
    },
    {
        "CD_NOM": 128316,
        "Nom français": "Utriculaire citrine",
        "Nom valide": "Utricularia neglecta Lehm., 1828",
        "Nom scientifique": "Utricularia neglecta Lehm."
    },
    {
        "Nom scientifique": "Sagina nodosa (L.) Fenzl.",
        "Nom valide": "Sagina nodosa (L.) Fenzl, 1833",
        "CD_NOM": 119812,
        "Nom français": "Sagine noueuse"
    },
    {
        "Nom scientifique": "Stratiotes aloides L.",
        "Nom français": "Faux-aloes",
        "CD_NOM": 125219,
        "Nom valide": "Stratiotes aloides L., 1753"
    },
    {
        "Nom scientifique": "Carex lasiocarpa Ehrh.",
        "Nom valide": "Carex lasiocarpa Ehrh., 1784 subsp. lasiocarpa",
        "CD_NOM": 960921,
        "Nom français": "Laiche filiforme"
    },
    {
        "Nom français": "Linaigrette a feuilles larges",
        "CD_NOM": 96852,
        "Nom scientifique": "Eriophorum latifolium Hoppe",
        "Nom valide": "Eriophorum latifolium Hoppe, 1800"
    },
    {
        "Nom français": "Cardoncelle mou",
        "Nom scientifique": "Carduncellus mitissimus (L.) DC.",
        "Nom valide": "Carthamus mitissimus L., 1753",
        "CD_NOM": 89235
    },
    {
        "CD_NOM": 127416,
        "Nom valide": "Trifolium ornithopodioides L., 1753",
        "Nom scientifique": "Trifolium ornithopodioides L.",
        "Nom français": "Trefle pied d'oiseau"
    },
    {
        "Nom français": "Peucedan des marais",
        "CD_NOM": 126615,
        "Nom scientifique": "Peucedanum palustre (L.) Moench",
        "Nom valide": "Thysselinum palustre (L.) Hoffm., 1814"
    },
    {
        "Nom français": "Grassette commune",
        "Nom valide": "Pinguicula vulgaris L., 1753 subsp. vulgaris",
        "CD_NOM": 762169,
        "Nom scientifique": "Pinguicula vulgaris L."
    },
    {
        "Nom valide": "Inula britannica L., 1753",
        "Nom scientifique": "Inula britannica L.",
        "CD_NOM": 103598,
        "Nom français": "Inule des fleuves"
    },
    {
        "Nom français": "Orchis des marais",
        "Nom valide": "Anacamptis palustris (Jacq.) R.M.Bateman, Pridgeon & M.W.Chase, 1997",
        "CD_NOM": 82286,
        "Nom scientifique": "Orchis palustris Jacq."
    },
    {
        "Nom scientifique": "Lycopodium clavatum L.",
        "Nom valide": "Lycopodium clavatum L., 1753",
        "CD_NOM": 107003,
        "Nom français": "Lycopode en massue"
    },
    {
        "Nom valide": "Baldellia ranunculoides (L.) Parl., 1854",
        "Nom français": "Fluteau fausse-renoncule",
        "CD_NOM": 85486,
        "Nom scientifique": "Baldellia ranunculoides (L.) Parl."
    },
    {
        "Nom scientifique": "Daphne mezereum L.",
        "CD_NOM": 94435,
        "Nom valide": "Daphne mezereum L., 1753",
        "Nom français": "Bois gentil"
    },
    {
        "Nom français": "Barbon",
        "CD_NOM": 86169,
        "Nom valide": "Bothriochloa ischaemum (L.) Keng, 1936",
        "Nom scientifique": "Dichanthium ischaemum (L.) Roberty"
    },
    {
        "Nom valide": "Lathyrus niger var. angustifolius Rouy, 1899",
        "CD_NOM": 148091,
        "Nom scientifique": "Lathyrus niger (L.) Bernh.",
        "Nom français": "Gesse noire"
    },
    {
        "Nom scientifique": "Asplenium forisiense Le Grand",
        "CD_NOM": 923640,
        "Nom valide": "Asplenium foreziense Le Grand ex Magnier, 1884",
        "Nom français": "Doradille du Forez"
    },
    {
        "CD_NOM": 82656,
        "Nom valide": "Anemone ranunculoides L., 1753",
        "Nom français": "Anemone fausse-renoncule",
        "Nom scientifique": "Anemone ranunculoides L."
    },
    {
        "Nom scientifique": "Geum rivale L.",
        "Nom valide": "Geum rivale L., 1753",
        "CD_NOM": 100215,
        "Nom français": "Benoite des ruisseaux"
    },
    {
        "CD_NOM": 612541,
        "Nom scientifique": "Orobanche purpurea Jacq.",
        "Nom français": "Orobanche pourpree",
        "Nom valide": "Phelipanche purpurea subsp. millefolii (Rchb.) Carlon, G.Gomez, M.Lainz, Moreno Mor., O.Sanchez & Schneew, 2008"
    },
    {
        "Nom scientifique": "Rhynchospora alba (L.) Vahl",
        "CD_NOM": 117731,
        "Nom français": "Rhynchospore blanc",
        "Nom valide": "Rhynchospora alba (L.) Vahl, 1805"
    },
    {
        "CD_NOM": 108519,
        "Nom valide": "Micropyrum tenellum (L.) Link, 1844",
        "Nom français": "Catapode des graviers",
        "Nom scientifique": "Micropyrum tenellum (L.) Link"
    },
    {
        "CD_NOM": 131829,
        "Nom valide": "Asplenium obovatum subsp. billotii (F.W.Schultz) O.Bolos, Vigo, Massales & Ninot, 1990",
        "Nom français": "Doradille de Billot",
        "Nom scientifique": "Asplenium billotti F.W. Schultz"
    },
    {
        "Nom valide": "Myrica gale L., 1753",
        "Nom scientifique": "Myrica gale L.",
        "Nom français": "Piment royal",
        "CD_NOM": 109130
    },
    {
        "Nom valide": "Lathyrus niger (L.) Bernh., 1800 var. niger",
        "Nom scientifique": "Lathyrus niger (L.) Bernh.",
        "CD_NOM": 613540,
        "Nom français": "Gesse noire"
    },
    {
        "CD_NOM": 612435,
        "Nom scientifique": "Halimium umbellatum (L.) Spach",
        "Nom valide": "Cistus umbellatus subsp. viscosus (Willk.) Demoly, 2006",
        "Nom français": "Helientheme en ombelle"
    },
    {
        "Nom français": "Ophrys araignee litigieuse",
        "Nom valide": "Ophrys virescens Philippe, 1859",
        "CD_NOM": 610963,
        "Nom scientifique": "Ophrys sphegodes Miller ssp. litigiosa (Camus) Becherer"
    },
    {
        "CD_NOM": 85978,
        "Nom français": "Bident radie",
        "Nom scientifique": "Bidens radiata Thuill.",
        "Nom valide": "Bidens radiata Thuill., 1799"
    },
    {
        "Nom scientifique": "Botrychium lunaria (L.) Swartz",
        "Nom valide": "Botrychium lunaria (L.) Sw., 1801 var. lunaria",
        "CD_NOM": 970780,
        "Nom français": "Botryche lunaire"
    },
    {
        "Nom valide": "Sisymbrella aspera subsp. praeterita Heywood, 1964",
        "Nom français": "Cresson rude",
        "Nom scientifique": "Sisymbrella aspera (L.) Spach",
        "CD_NOM": 141232
    },
    {
        "Nom scientifique": "Chimaphila umbellata (L.) W. Barton",
        "CD_NOM": 90877,
        "Nom valide": "Chimaphila umbellata (L.) W.P.C.Barton, 1817",
        "Nom français": "Pyrole en ombelle"
    },
    {
        "Nom scientifique": "Ranunculus ololeucos Lloyd",
        "Nom valide": "Ranunculus ololeucos J.Lloyd, 1844",
        "CD_NOM": 117144,
        "Nom français": "Renoncule toute blanche"
    },
    {
        "Nom valide": "Calamagrostis canescens (Weber) Roth, 1789",
        "Nom scientifique": "Calamagrostis canescens (Weber) Roth",
        "CD_NOM": 87218,
        "Nom français": "Calamagrostis des marais"
    },
    {
        "Nom valide": "Erucastrum supinum (L.) Al-Shehbaz & Warwick, 2003",
        "CD_NOM": 717179,
        "Nom scientifique": "Sisymbrium supinum L.",
        "Nom français": "Sisymbre couche"
    },
    {
        "Nom français": "Saule rampant",
        "Nom scientifique": "Salix repens L.",
        "Nom valide": "Salix repens var. dunensis (Rouy) P.Fourn., 1935",
        "CD_NOM": 151107
    },
    {
        "Nom scientifique": "Cyperus longus L.",
        "CD_NOM": 93967,
        "Nom valide": "Cyperus longus L., 1753",
        "Nom français": "Souchet long"
    },
    {
        "Nom scientifique": "Cystopteris fragilis (L.) Bernh.",
        "Nom français": "Cystopteris",
        "CD_NOM": 133622,
        "Nom valide": "Cystopteris fragilis (L.) Bernh., 1805 subsp. fragilis"
    },
    {
        "Nom valide": "Asplenium septentrionale (L.) Hoffm., 1796",
        "Nom français": "Doradille du Nord",
        "CD_NOM": 84526,
        "Nom scientifique": "Asplenium septentrionale (L.) Hoffm."
    },
    {
        "Nom scientifique": "Lychnis viscaria L.",
        "Nom valide": "Viscaria vulgaris Bernh., 1800",
        "Nom français": "Lychnis visqueux",
        "CD_NOM": 129904
    },
    {
        "Nom français": "Sanguisorbe",
        "Nom scientifique": "Sanguisorba officinalis L.",
        "CD_NOM": 120758,
        "Nom valide": "Sanguisorba officinalis L., 1753"
    },
    {
        "Nom scientifique": "Sedum sexangulare L.",
        "Nom valide": "Sedum sexangulare L., 1753",
        "Nom français": "Orpin de Bologne",
        "CD_NOM": 122256
    },
    {
        "Nom français": "Arabette des sables",
        "Nom scientifique": "Cardaminopsis arenosa (L.) Hayek",
        "CD_NOM": 612663,
        "Nom valide": "Arabidopsis arenosa subsp. borbasii (Zapal.) O'Kane & Al-Shehbaz, 1997"
    },
    {
        "Nom scientifique": "Ranunculus gramineus L.",
        "CD_NOM": 117049,
        "Nom français": "Renoncule a feuilles de graminee",
        "Nom valide": "Ranunculus gramineus L., 1753"
    },
    {
        "Nom français": "Polystic a frondes munies d'aiguillons",
        "Nom scientifique": "Polystichum aculeatum (L.) Roth",
        "CD_NOM": 115041,
        "Nom valide": "Polystichum aculeatum (L.) Roth, 1799"
    },
    {
        "Nom français": "Trigonelle de Montpellier",
        "Nom scientifique": "Trigonella monspeliaca L.",
        "Nom valide": "Medicago monspeliaca (L.) Trautv., 1841",
        "CD_NOM": 107662
    },
    {
        "CD_NOM": 127193,
        "Nom valide": "Trichophorum cespitosum (L.) Hartm., 1849",
        "Nom français": "Scirpe cespiteux",
        "Nom scientifique": "Scirpus cespitosus L."
    },
    {
        "Nom français": "Isopyre faux-pygamon",
        "Nom valide": "Isopyrum thalictroides L., 1753",
        "Nom scientifique": "Isopyrum thalictroides L.",
        "CD_NOM": 103917
    },
    {
        "Nom scientifique": "Arenaria grandiflora L.",
        "Nom français": "Sabline a grandes fleurs",
        "CD_NOM": 131592,
        "Nom valide": "Arenaria grandiflora L., 1759 subsp. grandiflora"
    },
    {
        "Nom valide": "Dianthus deltoides L., 1753 subsp. deltoides",
        "Nom scientifique": "Dianthus deltoides L.",
        "Nom français": "Oeillet couche",
        "CD_NOM": 133835
    },
    {
        "Nom français": "Osmonde royale",
        "Nom valide": "Osmunda regalis L., 1753 var. regalis",
        "CD_NOM": 970784,
        "Nom scientifique": "Osmunda regalis L."
    },
    {
        "Nom français": "Lathree clandestine",
        "Nom scientifique": "Lathraea clandestina L.",
        "CD_NOM": 105145,
        "Nom valide": "Lathraea clandestina L., 1753"
    },
    {
        "Nom scientifique": "Melica ciliata L.",
        "Nom français": "Melique ciliee",
        "CD_NOM": 137726,
        "Nom valide": "Melica ciliata subsp. transsilvanica (Schur) Husn., 1898"
    },
    {
        "Nom valide": "Equisetum hyemale L., 1753 subsp. hyemale",
        "CD_NOM": 788972,
        "Nom scientifique": "Equisetum hyemale L.",
        "Nom français": "Prele d'hiver"
    },
    {
        "Nom français": "Genet d'Allemagne",
        "CD_NOM": 99754,
        "Nom valide": "Genista germanica L., 1753",
        "Nom scientifique": "Genista germanica L."
    },
    {
        "Nom français": "Potentille couchee",
        "CD_NOM": 718395,
        "Nom scientifique": "Potentilla supina L.",
        "Nom valide": "Potentilla supina L., 1753 subsp. supina"
    },
    {
        "Nom français": "Trefle agglomere",
        "CD_NOM": 127326,
        "Nom scientifique": "Trifolium glomeratum L.",
        "Nom valide": "Trifolium glomeratum L., 1753"
    },
    {
        "Nom valide": "Melica ciliata L., 1753 subsp. ciliata",
        "Nom français": "Melique ciliee",
        "Nom scientifique": "Melica ciliata L.",
        "CD_NOM": 137719
    },
    {
        "Nom scientifique": "Linum leonii F.W. Schultz",
        "Nom français": "Lin des Alpes",
        "CD_NOM": 106306,
        "Nom valide": "Linum leonii F.W.Schultz, 1838"
    },
    {
        "Nom valide": "Chrysosplenium alternifolium L., 1753",
        "Nom français": "Dorine a feuilles alternes",
        "CD_NOM": 91118,
        "Nom scientifique": "Chrysosplenium alternifolium L."
    },
    {
        "Nom français": "Scirpe cespiteux",
        "CD_NOM": 718426,
        "Nom valide": "Trichophorum cespitosum nothosubsp. foersteri Swan, 1999",
        "Nom scientifique": "Scirpus cespitosus L."
    },
    {
        "Nom valide": "Potentilla montana Brot., 1804",
        "Nom scientifique": "Potentilla montana Brot.",
        "CD_NOM": 115560,
        "Nom français": "Potentille des montagnes"
    },
    {
        "CD_NOM": 119824,
        "Nom scientifique": "Sagina subulata (Swartz) C. Presl.",
        "Nom valide": "Sagina revelierei Jord. & Fourr., 1866",
        "Nom français": "Sagine subulee"
    },
    {
        "Nom scientifique": "Luzula sylvatica (Hudson) Gaudin",
        "CD_NOM": 137522,
        "Nom français": "Luzule des bois",
        "Nom valide": "Luzula sylvatica (Huds.) Gaudin, 1811 subsp. sylvatica"
    },
    {
        "CD_NOM": 103369,
        "Nom français": "Porcelle tachee",
        "Nom valide": "Hypochaeris maculata L., 1753",
        "Nom scientifique": "Hypochoeris maculata L."
    },
    {
        "CD_NOM": 117056,
        "Nom valide": "Ranunculus hederaceus L., 1753",
        "Nom scientifique": "Ranunculus hederaceus L.",
        "Nom français": "Renoncule a feuilles de lierre"
    },
    {
        "Nom valide": "Eriophorum angustifolium Honck., 1782 subsp. angustifolium",
        "Nom scientifique": "Eriophorum angustifolium Honckeny",
        "Nom français": "Linaigrette a feuilles etroites",
        "CD_NOM": 718348
    },
    {
        "Nom valide": "Cystopteris fragilis (L.) Bernh., 1805",
        "Nom français": "Cystopteris",
        "Nom scientifique": "Cystopteris fragilis (L.) Bernh.",
        "CD_NOM": 94066
    },
    {
        "Nom français": "Osmonde royale",
        "Nom scientifique": "Osmunda regalis L.",
        "CD_NOM": 111815,
        "Nom valide": "Osmunda regalis L., 1753"
    },
    {
        "CD_NOM": 103553,
        "Nom valide": "Impatiens noli-tangere L., 1753 [nom. et typ. cons.]",
        "Nom scientifique": "Impatiens noli-tangere L.",
        "Nom français": "Balsamine des bois"
    },
    {
        "Nom français": "Epipactis pourpre",
        "Nom scientifique": "Epipactis purpurata Sm.",
        "CD_NOM": 96471,
        "Nom valide": "Epipactis purpurata Sm., 1828 [nom. cons.]"
    },
    {
        "CD_NOM": 100640,
        "Nom français": "Polypode du calcaire",
        "Nom valide": "Gymnocarpium robertianum (Hoffm.) Newman, 1851",
        "Nom scientifique": "Gymnocarpium robertianum (Hoffm.) Newman"
    },
    {
        "CD_NOM": 612436,
        "Nom valide": "Cistus umbellatus L., 1753 subsp. umbellatus",
        "Nom scientifique": "Halimium umbellatum (L.) Spach",
        "Nom français": "Helientheme en ombelle"
    },
    {
        "Nom français": "Oeillet couche",
        "Nom valide": "Dianthus deltoides L., 1753",
        "CD_NOM": 94728,
        "Nom scientifique": "Dianthus deltoides L."
    },
    {
        "Nom valide": "Carex canescens L., 1753",
        "Nom français": "Laiche blanchatre",
        "CD_NOM": 88407,
        "Nom scientifique": "Carex curta Good."
    },
    {
        "Nom scientifique": "Viola rupestris F.W. Schmidt",
        "CD_NOM": 129674,
        "Nom valide": "Viola rupestris F.W.Schmidt, 1791",
        "Nom français": "Violette des sables"
    },
    {
        "Nom valide": "Inula hirta L., 1753",
        "Nom français": "Inule herissee",
        "Nom scientifique": "Inula hirta L.",
        "CD_NOM": 103631
    },
    {
        "CD_NOM": 113639,
        "Nom français": "Grassette commune",
        "Nom scientifique": "Pinguicula vulgaris L.",
        "Nom valide": "Pinguicula vulgaris L., 1753"
    },
    {
        "Nom français": "Asaret",
        "Nom valide": "Asarum europaeum L., 1753",
        "Nom scientifique": "Asarum europaeum L.",
        "CD_NOM": 84230
    },
    {
        "Nom scientifique": "Carex halleriana Asso",
        "CD_NOM": 88560,
        "Nom français": "Laiche de Haller",
        "Nom valide": "Carex halleriana Asso, 1779"
    },
    {
        "Nom valide": "Polycnemum majus A.Braun ex Bogenh., 1841",
        "Nom français": "Grand polycneme",
        "Nom scientifique": "Polycnemum majus A. Braun",
        "CD_NOM": 114520
    },
    {
        "Nom valide": "Hypericum elodes L., 1759",
        "Nom français": "Millepertuis des marais",
        "CD_NOM": 103272,
        "Nom scientifique": "Hypericum helodes L."
    },
    {
        "Nom scientifique": "Pinguicula vulgaris L.",
        "Nom français": "Grassette commune",
        "CD_NOM": 149489,
        "Nom valide": "Pinguicula vulgaris var. alpicola Rchb., 1823"
    },
    {
        "Nom valide": "Ranunculus tripartitus DC., 1807",
        "Nom scientifique": "Ranunculus tripartitus DC.",
        "CD_NOM": 117258,
        "Nom français": "Renoncule tripartite"
    },
    {
        "Nom français": "Littorelle",
        "CD_NOM": 106419,
        "Nom scientifique": "Littorella uniflora (L.) Ascherson",
        "Nom valide": "Littorella uniflora (L.) Asch., 1864"
    },
    {
        "Nom valide": "Luronium natans (L.) Raf., 1840",
        "CD_NOM": 106807,
        "Nom scientifique": "Luronium natans (L.) Rafin",
        "Nom français": "Fluteau nageant"
    },
    {
        "Nom valide": "Ranunculus parviflorus L., 1758",
        "CD_NOM": 117156,
        "Nom scientifique": "Ranunculus parviflorus L.",
        "Nom français": "Renoncule a petites fleurs"
    },
    {
        "Nom scientifique": "Carex liparocarpos Gaudin",
        "Nom valide": "Carex liparocarpos Gaudin, 1804",
        "CD_NOM": 88637,
        "Nom français": "Laiche rongee"
    },
    {
        "Nom scientifique": "Calamagrostis canescens (Weber) Roth",
        "CD_NOM": 132377,
        "Nom valide": "Calamagrostis canescens (Weber) Roth, 1789 subsp. canescens",
        "Nom français": "Calamagrostis des marais"
    },
    {
        "Nom valide": "Carex lasiocarpa Ehrh., 1784",
        "Nom scientifique": "Carex lasiocarpa Ehrh.",
        "CD_NOM": 88614,
        "Nom français": "Laiche filiforme"
    },
    {
        "Nom français": "Grassette commune",
        "Nom scientifique": "Pinguicula vulgaris L.",
        "CD_NOM": 718748,
        "Nom valide": "Pinguicula vulgaris L., 1753 var. vulgaris"
    },
    {
        "Nom français": "Hutchinsie",
        "CD_NOM": 103019,
        "Nom valide": "Hornungia petraea (L.) Rchb., 1838",
        "Nom scientifique": "Hornungia petraea (L.) Reichenb."
    },
    {
        "CD_NOM": 86081,
        "Nom français": "Bistorte",
        "Nom scientifique": "Polygonum bistorta L.",
        "Nom valide": "Bistorta officinalis Delarbre, 1800"
    },
    {
        "Nom valide": "Eriophorum angustifolium Honck., 1782",
        "Nom scientifique": "Eriophorum angustifolium Honckeny",
        "CD_NOM": 96844,
        "Nom français": "Linaigrette a feuilles etroites"
    },
    {
        "Nom scientifique": "Scorzonera austriaca Willd.",
        "Nom valide": "Scorzonera austriaca Willd., 1803 subsp. austriaca",
        "CD_NOM": 140804,
        "Nom français": "Scorsonere d'Autriche"
    },
    {
        "Nom français": "Orchis neglige",
        "CD_NOM": 94273,
        "Nom valide": "Dactylorhiza praetermissa (Druce) Soo, 1962",
        "Nom scientifique": "Dactylorhiza majalis (Reichenb.) P.F. Hunt & Summ. ssp. praetermissa (Druce) D. Moresby, Moore & Soo"
    },
    {
        "Nom scientifique": "Utricularia minor L.",
        "Nom valide": "Utricularia minor L., 1753",
        "Nom français": "Petite utriculaire",
        "CD_NOM": 128315
    },
    {
        "Nom français": "Amelanchier",
        "Nom valide": "Amelanchier ovalis Medik., 1793 subsp. ovalis",
        "Nom scientifique": "Amelanchier ovalis Medicus",
        "CD_NOM": 131308
    },
    {
        "Nom scientifique": "Equisetum variegatum Schleich. ex Weber et Mohr",
        "CD_NOM": 762284,
        "Nom valide": "Equisetum variegatum Schleich. ex F.Weber & D.Mohr, 1807",
        "Nom français": "Prele panachee"
    },
    {
        "CD_NOM": 612664,
        "Nom français": "Arabette des sables",
        "Nom scientifique": "Cardaminopsis arenosa (L.) Hayek",
        "Nom valide": "Arabidopsis arenosa (L.) Lawalree, 1960 subsp. arenosa"
    },
    {
        "Nom valide": "Helianthemum oelandicum var. canescens (Hartm.) Fr., 1824",
        "Nom scientifique": "Helianthemum canum (L.) Baumg.",
        "CD_NOM": 981676,
        "Nom français": "Heliantheme blanc"
    },
    {
        "CD_NOM": 762187,
        "Nom français": "Prele panachee",
        "Nom scientifique": "Equisetum variegatum Schleich. ex Weber et Mohr",
        "Nom valide": "Equisetum variegatum Schleich. ex F.Weber & D.Mohr, 1807 subsp. variegatum"
    },
    {
        "Nom français": "Orobanche pourpree",
        "Nom valide": "Phelipanche purpurea (Jacq.) Sojak, 1972",
        "CD_NOM": 113099,
        "Nom scientifique": "Orobanche purpurea Jacq."
    },
    {
        "CD_NOM": 100636,
        "Nom français": "Polypode du chene",
        "Nom valide": "Gymnocarpium dryopteris (L.) Newman, 1851",
        "Nom scientifique": "Gymnocarpium dryopteris (L.) Newman"
    },
    {
        "Nom français": "Melique penchee",
        "Nom valide": "Melica nutans L., 1753",
        "Nom scientifique": "Melica nutans L.",
        "CD_NOM": 107871
    },
    {
        "Nom scientifique": "Hepatica nobilis Miller",
        "Nom valide": "Hepatica nobilis Schreb., 1771",
        "Nom français": "Anemone hepatique",
        "CD_NOM": 101253
    },
    {
        "Nom valide": "Botrychium lunaria (L.) Sw., 1801",
        "Nom français": "Botryche lunaire",
        "Nom scientifique": "Botrychium lunaria (L.) Swartz",
        "CD_NOM": 86183
    },
    {
        "Nom français": "Jonc a inflorescence globuleuse",
        "CD_NOM": 104148,
        "Nom scientifique": "Juncus capitatus Weigel",
        "Nom valide": "Juncus capitatus Weigel, 1772"
    },
    {
        "CD_NOM": 88691,
        "Nom français": "Laiche des montagnes",
        "Nom valide": "Carex montana L., 1753",
        "Nom scientifique": "Carex montana L."
    },
    {
        "Nom français": "Arabette des sables",
        "Nom valide": "Arabidopsis arenosa (L.) Lawalree, 1960",
        "CD_NOM": 610746,
        "Nom scientifique": "Cardaminopsis arenosa (L.) Hayek"
    },
    {
        "Nom scientifique": "Polygala amara L. ssp. amarella (Crantz) Chodat",
        "Nom valide": "Polygala amarella Crantz, 1769",
        "Nom français": "Polygala amer",
        "CD_NOM": 114526
    },
    {
        "Nom français": "Hysope",
        "CD_NOM": 103406,
        "Nom valide": "Hyssopus officinalis L., 1753",
        "Nom scientifique": "Hyssopus officinalis L."
    },
    {
        "Nom valide": "Elatine hexandra (Lapierre) DC., 1808",
        "CD_NOM": 95858,
        "Nom français": "Elatine a six etamines",
        "Nom scientifique": "Elatine hexandra (Lapierre) DC."
    },
    {
        "Nom français": "Saule rampant",
        "Nom scientifique": "Salix repens L.",
        "CD_NOM": 140478,
        "Nom valide": "Salix repens L., 1753 subsp. repens"
    },
    {
        "Nom valide": "Eruca vesicaria (L.) Cav., 1802",
        "CD_NOM": 97041,
        "Nom français": "Roquette cultivee",
        "Nom scientifique": "Eruca vesicaria (L.) Cav. ssp. sativa (Mill.) Thell."
    },
    {
        "CD_NOM": 121329,
        "Nom valide": "Scabiosa canescens Waldst. & Kit., 1802",
        "Nom scientifique": "Scabiosa canescens Waldst. et Kit.",
        "Nom français": "Scabieuse odorante"
    },
    {
        "Nom français": "Prele d'hiver",
        "Nom valide": "Equisetum hyemale L., 1753",
        "Nom scientifique": "Equisetum hyemale L.",
        "CD_NOM": 96523
    },
    {
        "CD_NOM": 105148,
        "Nom valide": "Lathraea squamaria L., 1753",
        "Nom scientifique": "Lathraea squamaria L.",
        "Nom français": "Lathree ecailleuse"
    },
    {
        "Nom scientifique": "Potamogeton polygonifolius Pourret",
        "Nom valide": "Potamogeton polygonifolius Pourr., 1788",
        "CD_NOM": 115301,
        "Nom français": "Potamot a feuilles de renouee"
    },
    {
        "Nom scientifique": "Trifolium rubens L.",
        "CD_NOM": 127463,
        "Nom français": "Trefle rougeatre",
        "Nom valide": "Trifolium rubens L., 1753"
    },
    {
        "Nom scientifique": "Carex liparocarpos Gaudin",
        "Nom français": "Laiche rongee",
        "Nom valide": "Carex liparocarpos Gaudin, 1804 subsp. liparocarpos",
        "CD_NOM": 132744
    },
    {
        "CD_NOM": 141825,
        "Nom français": "Scirpe cespiteux",
        "Nom valide": "Trichophorum cespitosum subsp. germanicum (Palla) Hegi, 1908",
        "Nom scientifique": "Scirpus cespitosus L."
    },
    {
        "Nom français": "Asperule des teinturiers",
        "CD_NOM": 84330,
        "Nom scientifique": "Asperula tinctoria L.",
        "Nom valide": "Asperula tinctoria L., 1753"
    },
    {
        "Nom valide": "Lathyrus niger (L.) Bernh., 1800 subsp. niger",
        "CD_NOM": 137134,
        "Nom français": "Gesse noire",
        "Nom scientifique": "Lathyrus niger (L.) Bernh."
    },
    {
        "CD_NOM": 112426,
        "Nom français": "Parnassie des marais",
        "Nom scientifique": "Parnassia palustris L.",
        "Nom valide": "Parnassia palustris L., 1753"
    },
    {
        "CD_NOM": 92807,
        "Nom valide": "Crassula vaillantii (Willd.) Roth, 1827",
        "Nom français": "Crassule de Vaillant",
        "Nom scientifique": "Crassula vaillanti (Willd.) Roth"
    },
    {
        "Nom scientifique": "Actaea spicata L.",
        "CD_NOM": 80137,
        "Nom valide": "Actaea spicata L., 1753",
        "Nom français": "Actee en epi"
    },
    {
        "Nom français": "Ache inondee",
        "Nom valide": "Helosciadium inundatum (L.) W.D.J.Koch, 1824",
        "CD_NOM": 101220,
        "Nom scientifique": "Apium inundatum (L.) Reichenb. fil."
    },
    {
        "Nom français": "Jonc nain",
        "Nom valide": "Juncus pygmaeus Rich. ex Thuill., 1799",
        "CD_NOM": 104302,
        "Nom scientifique": "Juncus pygmaeus L.C.M. Richard"
    }
]