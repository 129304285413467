<template>
    <div class="container">
      <div ref="monthsContainer" class="months-container">
        <div class="months">
          <div :style="{width : monthWidth + 'vw'}" class="month" v-for="(month, key) in months" :key="key">
            <span >{{month.month}}</span>
            <span class="month-year">{{month.year}}</span>
          </div>
        </div>
      </div>

      <div @scroll="handleActionsScroll" class="actions-container">
        <actionComp v-for="(action, key) in actions" :monthWidth="monthWidth" :nbMonths="months.length" :parentData="data" :key="key" :data="action"/>
      </div>
     
    </div>
</template>

<script>
import actionComp from './planning/actionComp.vue'

export default {
  components : {
    actionComp
  },
  data() {
    return {
      yearMonths: ["january", "february", "march", "april", "may", "june", "july", "august", "september", "october", "november", "december"],
      data : {
        startYear: 2023,
        endYear: 2025,
        startMonth: 12,
        endMonth: 2
      }, 
      monthWidth: 9, //vw
      actions: [
        {
          title: "Kill plants",
          startYear: 2024,
          startMonth: 2,
          startDate : 31,
          endMonth : 8,
          endDate : 1,//exclu
          endYear : 2024
        },
        {
          title: "Kill plants",
          startYear: 2024,
          startMonth: 4,
          startDate : 1,
          endMonth : 5,
          endDate : 1,//exclu
          endYear : 2024
        },
        {
          title: "Kill plants",
          startYear: 2024,
          startMonth: 2,
          startDate : 1,
          endMonth : 4,
          endDate : 1,//exclu
          endYear : 2024
        }
      ]
    }
  },
  computed: {
    months() {
      // TODO Simplifier avec getMonthsDifference (Redondance)
      let months = []
      for (let y = this.data.startYear; y <= this.data.endYear; y++) {
        let startMonthRound = this.data.startMonth
        if (y > this.data.startYear) startMonthRound = 1
        let endMonthRound = this.yearMonths.length 
        if (y == this.data.endYear) endMonthRound = this.data.endMonth
        for (let m = startMonthRound; m <= endMonthRound; m++) {
          months.push({month : this.yearMonths[m - 1], year : y})
        }
      }
      return months
    }
  },
  mounted() {
  
  },

  methods: {

    handleActionsScroll(e){
      this.$refs.monthsContainer.scrollLeft = e.target.scrollLeft 
    },
  },


}

</script>

<style scoped>
.container {  
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.months-container { 
  position: absolute;
  width: 100%;
  overflow: hidden;
  height: 100%;
  pointer-events: none;
}

.months {
    top: 0;
    left: 0;
    display: flex;
    height: 100%;
    max-height: 3vw;
    width: fit-content;
    border-bottom: 0.1vw solid rgba(0, 0, 0, 0.075);
    border-top: 0.1vw solid rgba(0, 0, 0, 0.075);
    z-index: 1;
}

.month {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.month span{
  font-family: "Century Gothic";
  font-size: 1.2vw;
  color: var(--green);
}

.month span.month-year{
  opacity: 0.5;
  font-size: 0.8vw;
}

.month::after{
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100vh;
  width: 0.01vw;
  background-color: rgba(0, 0, 0, 0.103);
}

.actions-container{
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding-top: 3vw;
  overflow: auto;
  height: 100%;
  width: 100%;
}


</style>
