<template>
    <span></span>
</template>

<script>
import L from "leaflet";
export default {
    props: {
        markers: Array,
        selectedMarker: String,
        map: Object
    },
    data() {
        return {
            hidden: true
        };
    },
    watch: {
        selectedMarker(newValue, oldValue) {
            if (newValue == "alerts") this.$props.markers.forEach(e => e.class.getElement().classList.remove("hidden"))
            if (oldValue == "alerts") {
                this.selectMarker(null)
                this.$props.markers.forEach(e => e.class.getElement().classList.add("hidden"))}
        }
    },
    mounted() {
    },
    methods: {
        createSelectedIcon(color){
            return L.divIcon({
                className : "selected-alert-marker",
                html: '<svg viewBox="0 0 16 16" width="20" height="20"><path fill="' + color + '" d="M7.5,0C5.0676,0,2.2297,1.4865,2.2297,5.2703C2.2297,7.8378,6.2838,13.5135,7.5,15c1.0811-1.4865,5.2703-7.027,5.2703-9.7297C12.7703,1.4865,9.9324,0,7.5,0z"/></svg>',
            });
        },
        createIcon(color){
            return L.divIcon({
                className : "alert-marker",
                html : '<svg width="15" height="15" viewBox="0 0 100 100"><circle cx="50" cy="50" r="40" fill="'+ color + '" /></svg>',
            });
        },
        init(map) {
            this.$store.state.alerts.forEach((e) => {
                const markerClass = L.marker([e.lat, e.lng], {
                    icon: this.createIcon(this.$store.getters.getAlertColor(e.priority)),
                });
                markerClass.addTo(map)
                markerClass.getElement().classList.add('hidden')
                const marker = { class: markerClass, selected: false, data : e };
                this.$props.markers.push(marker);
                markerClass.getElement().addEventListener("click", ()=>{
                    // Centrer la carte sur la position du marqueur
                    const markerLatLng = markerClass.getLatLng();
                    var adjustedLatLng = L.latLng(
                        markerLatLng.lat,
                        markerLatLng.lng + 0.0011
                    );
                    this.$emit("clickMarker",'alerts', adjustedLatLng, e)
                    // changer le marqueur'
                    this.selectMarker(marker);
                })
            });
        },
        setHidden(bool) {
            if (bool && !this.hidden) {
                this.hidden = true
                this.$props.markers.forEach(e => e.class.getElement().classList.add("hidden"))
            }
            if (!bool && this.hidden) {
                this.hidden = false
                this.$props.markers.forEach(e => e.class.getElement().classList.remove("hidden"))
            }
        },
        selectMarker(marker) {
            this.$props.markers.forEach((e) => {
                if (e.selected) {
                    e.selected = false;
                    e.class.setIcon(this.createIcon(this.$store.getters.getAlertColor(e.data.priority)));
                }
            });
            if (marker !== null) {
                marker.selected = true;
                marker.class.setIcon(this.createSelectedIcon(this.$store.getters.getAlertColor(marker.data.priority)));
            }
        },
    },

}
</script>

<style scoped>
span {
    display: none;
}
</style>

<style>
.alert-marker {
    background-color: none;
    animation: bip 2s ease-out infinite;
}

.alert-marker.hidden {
    display: none;
}

.selected-alert-marker {
    background-color: none;
}
</style>