<template>
  <navbarComp :pull="true" ref="navbar"></navbarComp>


  <main ref="main">
    <loadingComp ref="loading"></loadingComp>
    <popupComp ref="popup"></popupComp>
    <ExportComp @showPopup="showPopup" @close="closeExportMenu" ref="exportMenu">
      <div ref="map" @wheel="unpullAll" @mousedown="unpullAll" id="map"></div>
    </ExportComp>
    <span @click="closeExportMenu" id="blur-background-effect" style="filter: blur(0.1vw)"></span>

    <rightToolBarComp @addSpecies="handleAddSpecies" @chooseMarker="handleChooseMarker" ref="rightToolbar"
      @open="handleToolBarOpen" @addObservation="handleAddObservation"></rightToolBarComp>
    <topToolbarComp ref="topToolbar" @open="handleTopToolBarOpen"></topToolbarComp>
    <searchBarComp  ref="searchbar"  @setFilters="handleSetFilters"></searchBarComp>

    <div class="left-btns">
      <span @click="handleMarkerTogglerClick">
        <img v-if="markers[selectedMarker] == 'observations'" src="@/assets/map/eye.png">
        <img v-if="markers[selectedMarker] == 'alerts'" src="@/assets/map/alert.png">
      </span>
      <span @click="openExportMenu">
        <img style="padding: 0.2vw;" src="@/assets/map/export.png" alt="">
      </span>
    </div>

  </main>

  <observationMarkersHandler ref="observationMarkersHandler" @clickMarker="handleClickMarker" :map="map"
    :markers="observationMarkers" :selectedMarker="markers[selectedMarker]"></observationMarkersHandler>

  <alertsMarkersHandler ref="alertMarkersHandler" :markers="alertsMarkers" :map="map" @clickMarker="handleClickMarker"
    :selectedMarker="markers[selectedMarker]"></alertsMarkersHandler>


</template>

<script>
import L from "leaflet";

import navbarComp from "@/components/navbarComp.vue";
import rightToolBarComp from "@/components/map/rightToolbarComp.vue";
import searchBarComp from "@/components/map/searchBarComp.vue";
import topToolbarComp from "@/components/map/topToolbarComp.vue";
import loadingComp from "@/components/loadingComp.vue";
import popupComp from "@/components/popupComp.vue";
//Handlers
import observationMarkersHandler from "@/components/map/observationMarkersHandler.vue";
import alertsMarkersHandler from '@/components/map/alertsMarkersHandler.vue';
import ExportComp from '@/components/map/ExportComp.vue';

export default {
  components: {
    navbarComp,
    rightToolBarComp,
    searchBarComp,
    topToolbarComp,
    observationMarkersHandler,
    alertsMarkersHandler,
    ExportComp,
    loadingComp,
    popupComp
  },

  data() {
    return {
      map: null,
      loadingTimer: null,
      observationMarkers: [],
      alertsMarkers: [],
      selectedMarker: 0,
      markers: ['observations', 'alerts'],
      exporting: false
    };
  },

  async mounted() {
    this.$refs.loading.open("Website loading")
    this.loadingTimer = setTimeout(() => {
      this.$refs.loading.open("Website loading",
        "This is taking longer than expected. If this continues, please check your internet connection.");
    }, 10000);
    // verify if connected
    await this.$store.dispatch('waitLogged')
    if (!this.$store.state.logged) {
      const activeAirportParam = this.$route.params.airport;
      this.$router.push('/login/' + activeAirportParam)
      return
    }
    await this.$store.dispatch('getUserPermissions')
    await this.$store.dispatch("getObservations")
    await this.$store.dispatch("getSpecies")

    this.map = L.map("map");
    this.map.setView([this.$store.state.activeAirport.latitude, this.$store.state.activeAirport.longitude], 13);
    //retire les boutons zoom en haut a droite (souvent inutiles) et les contributeurs en bas a droite
    this.map.zoomControl.remove();
    this.map.attributionControl.remove();

    L.tileLayer(
      "https://{s}.basemaps.cartocdn.com/rastertiles/voyager/{z}/{x}/{y}.png",
      {
        maxZoom: 19,
      }
    ).addTo(this.map);

    this.$refs.observationMarkersHandler.init(this.map)
    this.$refs.alertMarkersHandler.init(this.map)
    // Dessin cercle

    // this.map.on("mousedown", e=>{
    //   this.map.dragging.disable()
    //   var circle = L.circle(e.latlng, {
    //     color: 'red',
    //     fillColor: '#f03',
    //     fillOpacity: 0.5,
    //     radius: 0
    //   })
    //   circle.addTo(this.map)

    //   const lastClientY = e.originalEvent.clientY
    //   // const lastClientX = e.originalEvent.clientX

    //   window.addEventListener("mousemove", e=>{
    //     console.log(e.clientY - lastClientY);
    //     circle.setRadius(Math.abs(e.clientY - lastClientY))
    //   })

    // })

    //TESTING

    // var circle = L.circle([48.743, 2.40129], {
    //   color: 'red',
    //   fillColor: '#f03',
    //   fillOpacity: 0.5,
    //   radius: 500
    // })
    // circle.addTo(this.map)

    // setInterval(()=>{
    //   circle.setRadius(circle.getRadius() + 100);
    // }, 1100)
    this.$refs.loading.close()
    clearTimeout(this.loadingTimer);
  },
  watch: {
    selectedMarker() {
      this.map.flyTo(this.map.getCenter(), 12)
    },
    '$store.state.activeAirport': function (newValue) {
      if (this.map) this.map.setView([newValue.latitude, newValue.longitude], 13);

      // Vous pouvez effectuer d'autres opérations ici en fonction des changements de activeAirport.
    }
  },
  methods: {
    closeExportMenu() {
      this.$refs.map.classList.remove('export')
      this.$refs.exportMenu.close()
      document.getElementById('blur-background-effect').classList.remove('visible')
      this.map.invalidateSize();
    },
    showPopup(data)
    {
      this.$refs.popup.show(data.title, data.type, data.action, data.data)
    },
    openExportMenu() {
      this.$refs.map.classList.add('export')
      this.$refs.exportMenu.open()
      document.getElementById('blur-background-effect').classList.add('visible')
      this.map.invalidateSize();
    },
    unpullAll() {
      this.$refs.navbar.setPulled(false);
      this.$refs.rightToolbar.unpull();
      this.$refs.topToolbar.unpull();
    },
    handleMarkerTogglerClick() {
      this.selectedMarker = (this.selectedMarker + 1) % this.markers.length
      this.unpullAll()
    },
    handleAddObservation(observation)
    {
      this.$refs.observationMarkersHandler.addMarker(observation, this.map)
    },
    handleAddSpecies() {
      this.$refs.searchbar.focus()
    },
    handleClickMarker(type, LatLng, data) {
      this.map.flyTo(LatLng, this.map.getMaxZoom());
      this.$refs.rightToolbar.pull(type, 'setData', data);
    },
    handleChooseMarker(data) {
      let marker = this.alertsMarkers.find(e => e.data == data)
      if (!marker) marker = this.observationMarkers.find(e => e.data == data)
      if(marker)  marker.class.getElement().click()
     
    },
    handleToolBarOpen(id) {
      if (this.markers.includes(id)) this.selectedMarker = this.markers.indexOf(id)
      this.$refs.navbar.setPulled(false);
      this.$refs.topToolbar.unpull();
    },
    handleTopToolBarOpen() {
      this.$refs.navbar.setPulled(false);
      this.$refs.rightToolbar.unpull();
    },
    handleSetFilters(tool, data) {
      this.unpullAll();
      this.$refs.rightToolbar.pull(tool, 'setFilters', data);
      this.selectedMarker = 0
    }
  },
};
</script>

<style scoped>
main {
  overflow: hidden;
  width: 100vw;
  height: 100vh;
  position: relative;
  user-select: none;
}

#map {
  width: 100%;
  height: 100%;
  cursor: default;
  z-index: 1;
}

#blur-background-effect {
  pointer-events: none;
  opacity: 0;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: var(--black);
  transition: 0.3s ease;
}

#blur-background-effect.visible {
  display: block;
  opacity: 0.7;
  pointer-events: all;
}

#map.export {
  width: 1920px;
  height: 1080px;
}

.left-btns span {
  height: 5vw;
  width: 5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0.1vw 0.3vw;
  border-radius: 100%;
  cursor: pointer;
  background-color: white;
  transition: 0.1s ease;
}

.left-btns {
  position: fixed;
  display: flex;
  flex-direction: column;
  gap: 1vw;
  left: 2vw;
  bottom: 3vw;
  z-index: 2;
}

.left-btns span:hover {
  scale: 1.1;
}

.left-btns span img {

  pointer-events: none;
  width: 2.5vw;
}


@media screen and (max-width: 100vh) {
  .left-btns span {
    width: 10vw;
    height: 10vw;
  }

  .left-btns span img {
    width: 5vw;
  }
}
</style>

<style>
@keyframes bip {
  from {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  to {
    opacity: 1;
  }
}
</style>
