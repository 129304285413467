<!-- Main component for the forms management -->

<template>
    <div v-if="checkUserPermissions('CAF', 'single')" class="forms-container">
        <div class="left" :class="{ '--locked': lockCategoriesState }">
            <div class="scroller">

                <!-- Lock overlay -->
                <img v-if="lockCategoriesState" src="@/assets/image/log/lock.png" id="lock-icon">

                <!-- Field Key management -->
                <div class="form-element --button" :class="{ selected: isKeyManagementSelected }"
                    @click="$store.state.form.formLeftMenuLastSelectedItem = 'KeyManagement'">
                    <img src="@/assets/image/forms/form-input-key-icon.svg" id="form-icon" />

                    {{ keyText }}
                </div>

                <!-- Form management -->
                <div>
                    <div class="form-element --button" :class="{ selected: isFormManagementSelected }"
                        @click="$store.state.form.formLeftMenuLastSelectedItem = 'FormManagement'">

                        <img v-if="userHasFromManagementPermission" src="@/assets/map/parameter.png" id="form-icon" />
                        <img v-else src="@/assets/map/eye.png" id="form-icon" />

                        {{ formText }}
                    </div>

                    <div div class="form-element-menu">
                        <div class="form-element-menu-item"
                            :class="{ selected: isFormManagementSelected && formLeftMenuSelectedItemFilter == null }"
                            @click="formLeftMenuSelectedItemFilter = null">
                            <div class="form-element form-element-menu-item-label">
                                All forms
                            </div>
                        </div>
                        <div v-for="(type, key) in formTypesItemFilter" :key="key" class="form-element-menu-item"
                            :class="{ selected: isFormManagementSelected && formLeftMenuSelectedItemFilter == type }"
                            @click="formLeftMenuSelectedItemFilter = type">
                            <div class="form-element form-element-menu-item-label">
                                {{ capitalizeFirstLetter(type) }}
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div class="right">
            <ManageForms v-if="isFormManagementSelected" :userPermissions="getUserPermissions"
                :selectedFilter="formLeftMenuSelectedItemFilter" @categories="updateCategories"
                @lock-categories="lockCategoriesState = $event" />

            <ManageKeys v-else-if="isKeyManagementSelected" :userPermissions="getUserPermissions"
                @lock-categories="lockCategoriesState = $event" />

            <span v-else id="no-result-container">
                <img src="@/assets/map/in-development.png">
                In development...
            </span>
        </div>
    </div>
    <div v-else>
        <span id="no-result-container">
            <img src="@/assets/image/forms/form-comp-access-denied.svg">
            You do not have the necessary permissions to access the form management section.
            If you believe this is an error, please reach out to your system administrator.
            Alternatively, you may want to verify your own assigned permissions.
        </span>
    </div>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script>
import ManageForms from './forms/ManageForms.vue'
import ManageKeys from './forms/ManageKeys.vue'
import { useStore } from 'vuex';
import { onMounted } from 'vue';

export default {
    // ? - - - Components - - -
    components: {
        ManageForms,
        ManageKeys
    },

    // ? - - - Setup - - - - - -
    setup() {
        const store = useStore();

        onMounted(async () => {
            await store.dispatch('firstFormKeysFetch');
            await store.dispatch('getAirportInventorys');
        });

        // Set the default selected item in the left menu
        if (!store.state.form.formLeftMenuLastSelectedItem) store.state.form.formLeftMenuLastSelectedItem = 'FormManagement';
    },

    // ? - - - Data - - - - - - -
    data() {
        return {
            /** Selected filter of the form management, By default, null (all forms) */
            formLeftMenuSelectedItemFilter: null,
            /** Boolean, lock the categories with an overlay when true */
            lockCategoriesState: false,
            /** Array of form types to filter the form management (updated by ManageForms) */
            formTypesItemFilter: []
        }
    },

    // ? - - - Computed - - - - -
    computed: {

        /** 
         * Selected item in the left menu, 
         * By default, 'FormManagement' 
         */
        formLeftMenuSelectedItem() {
            // Note: I use Strings instead of numbers to make this more flexible if need to move items around
            return this.$store.state.form.formLeftMenuLastSelectedItem;
        },

        /**
         * Boolean to check if the form management is selected
         */
        isKeyManagementSelected() {
            return this.formLeftMenuSelectedItem === 'KeyManagement';
        },

        /**
         * Boolean to check if the form management is selected
         */
        isFormManagementSelected() {
            return this.formLeftMenuSelectedItem === 'FormManagement';
        },

        /**
         * Get the user permissions from the store
         * 
         * @returns {Array} User permissions
         * @version 1.0.0
         */
        getUserPermissions() {
            return this.$store.state.user.permissions;
        },

        /**
         * Check if the user has at least one of the form management permissions
         * 
         * @returns {Boolean} true if above condition is met, false otherwise
         * @version 1.0.0
         */
        userHasFromManagementPermission() {
            // CF = Create forms ; MF = Manage forms ; DF = Delete forms
            return this.checkUserPermissions(['CF', 'MF', 'DF'], 'atLeastOne');
        },

        /**
         * Check if the user has at least one of the key management permissions
         * 
         * @returns {Boolean} true if above condition is met, false otherwise
         * @version 1.0.0
         */
        userHasKeyManagementPermission() {
            // CK = Create keys ; MK = Manage keys ; DK = Delete keys
            return this.checkUserPermissions(['CK', 'MK', 'DK'], 'atLeastOne');
        },

        /**
         * Changes text used by the form element depending on the user's permissions
         */
        formText() {
            return this.userHasFromManagementPermission ? 'Manage forms by type' : 'Consult forms by type';
        },

        /**
         * Changes text used by the form element depending on the user's permissions
         */
        keyText() {
            return this.userHasKeyManagementPermission ? 'Manage field keys' : 'Consult field keys';
        },
    },

    // ? - - - Methods - - - - - -
    methods: {

        /**
         * Self-explanatory
         * Refer to the store for the actual implementation
         */
        capitalizeFirstLetter(str) {
            return this.$store.getters.capitalizeFirstLetter(str);
        },

        /**
         * The form fetching is done in the child component, 
         * so we need to update the categories here when 
         * ManageForms emits the event
         * 
         * This refreshes the categories in the left menu
         * 
         * @version 1.0.0
         */
        updateCategories(categories) {
            this.formTypesItemFilter = categories;
        },

        /**
         * Check if the user has at least one of the permissions in the given array
         * 
         * @param {Array} codes Array of permission codes to check
         * @param {String} mode Mode of the check ('single', 'hasAll', 'atLeastOne')
         * @returns {Boolean} True if the user has at least one of the permissions, false otherwise
         * 
         * @version 1.0.0
         */
        checkUserPermissions(codes, mode = 'single') {
            const permissions = this.$store.state.user.permissions;
            if (!permissions) return false;

            // DEBUG ---------------------
            this.$store.dispatch('debugLog', {
                componentName: 'formsComp',
                message: 'Checking user permissions',
                attachment: { permissions, codes, mode },
                level: 'debug'
            });
            // ---------------------------

            switch (mode) {
                default:
                    return false;

                case 'single':
                    return permissions.includes(codes);

                case 'hasAll':
                    return Array.isArray(codes) && codes.every(code => permissions.includes(code));

                case 'atLeastOne':
                    return Array.isArray(codes) && codes.some(code => permissions.includes(code));
            }
        },
    }
}
</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
/* Make image unselectable */
img {
    pointer-events: none;
}

.forms-container {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    position: relative;
    width: 100%;
    height: 100%;

    /* 'BETA' label */
    /* FIXME: //! Remove BETA banner for release */
    &::after {
        content: 'BETA';
        position: absolute;
        color: white;
        top: 0;
        right: 0;
        width: 6vw;
        height: 1vw;
        font-size: 0.9vw;
        letter-spacing: 0.1vw;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #C42122;
        rotate: 45deg;
        border: 0.01vw solid var(--grey);
        box-shadow: 0.05vw 0.05vw 0.15vw rgba(0, 0, 0, 0.2);
        transform: translateY(-3.5vw) translateX(1.8vw);
        font-family: 'Century Gothic Bold', 'ui-sans-serif';

    }
}

.left {
    width: 30%;
    padding: 2vw;
    height: 92%;
    margin-top: 2%;
    position: relative;
    overflow: auto;
    background-color: var(--grey-light);
    border: 0.01vw solid var(--grey);
    border-radius: var(--large-radius);

    .scroller {
        display: flex;
        flex-direction: column;
        gap: 1vw;
        width: 100%;
    }

    &.--locked::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(206, 206, 206, 0.5);
        z-index: 1;
    }

    #lock-icon {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;
        width: 5vw;
    }
}

.right {
    width: 68%;
    height: 92%;
    margin-top: 2%;
    position: relative;
}

.form-element {
    background-color: var(--white);
    border-radius: var(--large-radius);
    display: block;
    font-family: 'Century Gothic';
    text-align: center;
    width: 100%;
    padding: 1vw;
    font-size: 1vw;
    cursor: pointer;
    transition: 0.05s ease;
    outline: 0.1vw solid var(--grey);

    &.--button {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 0.5vw;
    }

    #form-icon {
        width: 1.5vw;
        height: 1.5vw;
    }

    &.selected,
    .form-element-menu-item.selected>& {
        border-left: 0.3vw solid var(--green);
    }

    &:hover {
        background-color: var(--grey-dark);
        outline: 0.1vw solid var(--grey-darker);
        outline-offset: 0.1vw;
        transition: background-color 0.3s;
    }
}

/* "Menu" style for filters */
.form-element-menu {
    padding-left: 1vw;
    padding-top: 1vw;
    margin-left: 1.5vw;
    border-left: 0.01vw solid var(--grey-darker);

    .form-element-menu-item {
        position: relative;
        margin-bottom: 0.5vw;
        padding-right: 0vw;

        &:before,
        &:last-child:after {
            content: "";
            position: absolute;
            top: 1.5vw;
            left: -1vw;
            width: 1vw;
        }

        &:before {
            background: var(--grey-darker);
            height: 0.03vw;
        }

        &:last-child:after {
            background: var(--grey-light);
            left: -2vw;
            height: 2vw;
        }
    }
}

#no-result-container {
    z-index: 1;
    position: absolute;
    height: 2vw;
    font-size: 1.3vw;
    align-items: center;
    justify-content: center;
    text-align: justify;
    width: 45%;
    left: 50%;
    top: 55%;
    display: flex;
    gap: 0.5vw;
    opacity: 0.6;
    transform: translate(-50%, -50%);
    font-family: 'Century Gothic';

    img {
        width: 4vw;
    }
}
</style>