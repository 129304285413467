<template>
  Page not found
</template>

<script>
export default {
  mounted() {
    // renvoi vers l’accueil du premier aéroport de la liste 
    const firstAirportName = Object.keys(this.$store.state.airports)[0]
    this.$router.push('/home/' + firstAirportName)
  }
}
</script>

<style></style>