<template>
    <div class="manage-container">
        <popupComp @response="handlePopupResponse" ref="popup" />
        <div class="search-bar fully">
            <div class="search-bar-container">
                <img src="@/assets/home/search.png" />
                <input v-model="userInput" ref="input" placeholder="Search for alert rule..." type="text" />
            </div>
            
        <button @click="handleAdd" id="add">NEW</button>
        </div>


        <template v-if="ruleToEdit == null">
            <div class="content-scroller">
                <div v-for="(rule, key) in rules" :key="key" class="row">
                    <section>
                        <label>Type</label>
                        <p>{{ rule.type }}</p>
                    </section>
                    <section>
                        <label>Priority</label>
                        <p>{{ rule.priority }}</p>
                    </section>
    
                    <div class="btns">
                        <button @click="ruleToEdit = key" class="edit">Edit</button>
                        <button @click="handleDelete(user)" class="delete">Delete</button>
                    </div>
                </div>
            </div>
            
        </template>

        <div v-if="ruleToEdit !== null" class="edit-rule">
            <h5>Edit Alert Rule</h5>

            <div class="data">
                <div class="double-entry">
                    <div class="entry">
                        <p>Id</p>
                        <input placeholder="Id..." type="text">
                    </div>
                    <div class="entry">
                        <p>Priority</p>
                        <input placeholder="Priority..." type="number">
                    </div>
                </div>
                <div class="entry">
                    <p>Type</p>
                    <input placeholder="Security..." type="text">
                </div>
                <div class="entry">
                    <p>Concerned species</p>
                    <input placeholder="Bactrocera oleae..." type="text">
                </div>
            </div>
            <div class="if-block-container">
                <h6>IF</h6>
                <div class="entry">
                    <p>Attribute</p>
                    <select>
                        <option>Quantity</option>
                        <option>Germination</option>
                    </select>
                </div>
                <div class="double-entry">
                    <div class="entry">
                        <p>Operator</p>
                        <select>
                            <option>Equals</option>
                            <option>Less</option>
                            <option>Greater</option>
                        </select>
                    </div>
                    <div class="entry">
                        <p>Value</p>
                        <input placeholder="Number or text..." type="text">
                    </div>
                </div>
            </div>


            <div class="then-block-container">
                <h6>THEN</h6>
                <div class="entry">
                    <p>Action 1</p>
                    <select>
                        <option>Do nothing</option>
                        <option>Create alert</option>
                    </select>
                </div>
                <div class="double-entry">
                    <div class="entry">
                        <p>Action 2</p>
                        <select>
                            <option>Send email</option>
                            <option>Send message</option>
                        </select>
                    </div>
                    <div class="entry">
                        <p>Value</p>
                        <input placeholder="Number or text..." type="text">
                    </div>
                </div>

            </div>
        </div>



    </div>
</template>

<script>
import popupComp from '@/components/popupComp.vue'

export default {
    components: {
        popupComp
    },

    data() {
        return {
            ruleToEdit: null,
            rules: [
                {
                    id: 1,
                    type: 'Aero Security',
                    priority: 1,
                }
            ]
        }
    }
}
</script>

<style scoped>
.edit-rule {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background-color: white;
    width: 100%;
    padding: 1vw 2vw;
}


.data,
.if-block-container,
.then-block-container {
    margin: 2vw 0;
    display: flex;
    flex-direction: column;
    gap: 1vw;
}

.if-block-container,
.then-block-container {
    padding: 1vw;
    background-color: rgba(243, 243, 243, 0.547);
}

.if-block-container h6,
.then-block-container h6 {
    padding: 0.5vw;
    opacity: 0.6;
    font-size: 0.8vw;
}


.entry {
    padding: 1vw;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
    margin: auto;
    background-color: rgba(128, 128, 128, 0.063);
}

.entry.error {
    outline: 0.1vw solid red;
}

.entry p {
    width: 100%;
    font-family: 'Century Gothic';
    font-size: 0.8vw;
}


.entry input,
.entry select {
    background: none;
    font-family: 'Century Gothic';
    padding: 1vw 0;
    padding-bottom: 0.2vw;
    font-size: 0.9vw;
    border: none;
    height: 100%;
    width: 100%;
}


.entry input:focus,
.entry select:focus {
    outline: none;
}

.double-entry {
    display: flex;
    flex-direction: row;
    gap: 1vw;
}
</style>