<template>
    <div class="modal">
        <div class="modal__modal-wrapper">
            <div class="modal__modal-wrapper --header">
                <h2>{{ modalTitle }}</h2>
                <div id="closeModal">
                    <button @click="closeModal">&times;</button>
                </div>
            </div>
            <div class="modal-wrapper__modal-content">
                <div class="--text">
                    <p v-if="modalMessage">{{ modalMessage }}</p>
                </div>
                <div class="--btns">
                    <button class="--confirm" :style="leftButtonStyle" @click="handleClick('left')">
                        <span>{{ leftButtonText }}</span>
                    </button>
                    <button class="--cancel" :style="rightButtonStyle" @click="handleClick('right')">
                        <span>{{ rightButtonText }}</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<script setup>
import { defineProps, defineEmits } from 'vue';

defineProps({
    closeModal: {
        type: Function,
        required: true
    },
    modalTitle: {
        type: String,
        required: true
    },
    modalMessage: {
        type: String,
    },
    leftButtonText: {
        type: String,
        default: 'Cancel'
    },
    rightButtonText: {
        type: String,
        default: 'Confirm'
    },
    leftButtonStyle: {
        type: Object,
        default: () => ({ backgroundColor: 'var(--grey)' })
    },
    rightButtonStyle: {
        type: Object,
        default: () => ({ backgroundColor: 'var(--green-light)' })
    }
});

const emit = defineEmits(['buttonClick']);

function handleClick(button) {
    emit('buttonClick', button);
}

</script>

<!-- - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -->

<style scoped lang="scss">
.modal {
    z-index: 1;
    display: flex;
    position: fixed;
    left: 1vw;
    top: 1vw;
    width: 97.5%;
    height: 94%;
    border-radius: var(--medium-radius);
    background-color: rgba(0, 0, 0, 0.04);

    .modal__modal-wrapper:not(.--header) {
        background-color: var(--white);
        margin: auto;
        padding: 0.5vw;
        outline: 0.01vw solid var(--grey);
        border-radius: var(--medium-radius);
        box-shadow: var(--medium-shadow);
        width: 40%;
        height: 15vw;

        font-size: 0.9vw;
    }

    .modal__modal-wrapper.--header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 0.1vw solid var(--grey);

        h2 {
            font-size: 1.4vw;
            color: var(--grey-darker);
            margin-bottom: 0.5vw;
            padding: 0.1vw;
        }

        #closeModal {
            border-left: 0.1vw solid var(--grey);

            button {
                background-color: transparent;
                border: none;

                font-size: 2vw;
                width: 3vw;
                margin-left: 0;
                transition: transform 0.35s;

                &:hover {
                    transform: rotate(-90deg) scale(1.5);
                    transition: transform 0.5s;
                    cursor: pointer;
                }
            }
        }
    }

    .modal-wrapper__modal-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 0.5vw;
        padding: 0.5vw;

        .--text {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 5.5vw;
            font-size: 1.2vw;
            color: var(--black-light);
            gap: 0.5vw;
        }

        .--btns {
            display: flex;
            justify-content: center;
            gap: 2vw;

            button {
                width: 7vw;
                height: 3vw;
                border: none;
                border-radius: var(--small-radius);
                padding: 0.5vw;
                cursor: pointer;

                span {
                    font-size: 1vw;
                }

                &:hover {
                    /* Darken the background color */
                    filter: brightness(0.9);
                    transform: scale(1.1);

                    transition: filter 0.3s, transform 0.3s;
                }
            }
        }
    }
}
</style>